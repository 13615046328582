// Constant imports
import {
  STAFF_EXIT_ACTION_LIST_REQUEST,
  STAFF_EXIT_ACTION_LIST_SUCCESS,
  STAFF_EXIT_ACTION_LIST_FAIL,
  STAFF_EXIT_ACTION_LIST_RESET,
  STAFF_EXIT_ACTION_CREATE_REQUEST,
  STAFF_EXIT_ACTION_CREATE_SUCCESS,
  STAFF_EXIT_ACTION_CREATE_FAIL,
  STAFF_EXIT_ACTION_CREATE_RESET,
  STAFF_EXIT_ACTION_DETAILS_REQUEST,
  STAFF_EXIT_ACTION_DETAILS_SUCCESS,
  STAFF_EXIT_ACTION_DETAILS_FAIL,
  STAFF_EXIT_ACTION_DETAILS_RESET,
  STAFF_EXIT_ACTION_UPDATE_REQUEST,
  STAFF_EXIT_ACTION_UPDATE_SUCCESS,
  STAFF_EXIT_ACTION_UPDATE_FAIL,
  STAFF_EXIT_ACTION_UPDATE_RESET,
  STAFF_EXIT_ACTION_DELETE_REQUEST,
  STAFF_EXIT_ACTION_DELETE_SUCCESS,
  STAFF_EXIT_ACTION_DELETE_FAIL,
  STAFF_EXIT_ACTION_DELETE_RESET,
} from "../constants/staffExitActionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listStaffExitActionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_ACTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffExitActions(
        params,
        token
      );

      dispatch({
        type: STAFF_EXIT_ACTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfActionsCompleted: data.number_of_actions_completed,
          numberOfActionsInProgress: data.number_of_actions_in_progress,
          numberOfActionsOnHold: data.number_of_actions_on_hold,
          staff_exit_actions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_ACTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListStaffExitActionsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_ACTION_LIST_RESET });
};

// Create
export const createStaffExitActionAction =
  (staff_exit_action) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_ACTION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createStaffExitAction(
        token,
        staff_exit_action
      );

      dispatch({
        type: STAFF_EXIT_ACTION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_ACTION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateStaffExitActionAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_ACTION_CREATE_RESET });
};

// List details
export const listStaffExitActionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_ACTION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffExitAction(id, token);

      dispatch({
        type: STAFF_EXIT_ACTION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_ACTION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListStaffExitActionDetailsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_ACTION_DETAILS_RESET });
};

// Update
export const updateStaffExitActionAction =
  (id, staff_exit_action) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_ACTION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateStaffExitAction(
        id,
        token,
        staff_exit_action
      );

      dispatch({
        type: STAFF_EXIT_ACTION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_ACTION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateStaffExitActionAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_ACTION_UPDATE_RESET });
};

// Delete
export const deleteStaffExitActionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_ACTION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteStaffExitAction(id, token);

      dispatch({
        type: STAFF_EXIT_ACTION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_ACTION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteStaffExitActionAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_ACTION_DELETE_RESET });
};
