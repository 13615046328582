// Constants import
import {
  BACKEND_URL,
  NODE_SERVICES_URL,
} from "../constants/applicationConstants";

// Libraries import
import axios from "axios";

class CortixDataService {
  // AI credit transaction
  getAICreditTransactions(
    {
      action = "",
      organisation = "",
      page = 1,
      staff = "",
      sortField = "",
      timestampEnd = "",
      timestampStart = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/ai_credit_transactions?action=${action}&organisation=${organisation}&page=${page}&staff=${staff}&sortField=${sortField}&timestampEnd=${timestampEnd}&timestampStart=${timestampStart}`
    );
  }

  // Approval request
  getApprovalRequests(
    {
      approver = "",
      decisionRight = "",
      itemId = "",
      itemType = "",
      organisation = "",
      outcome = "",
      page = 1,
      reviewedEnd = "",
      reviewedStart = "",
      sortField = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/approval_requests?approver=${approver}&decisionRight=${decisionRight}&itemId=${itemId}&itemType=${itemType}&organisation=${organisation}&outcome=${outcome}&page=${page}&reviewedEnd=${reviewedEnd}&reviewedStart=${reviewedStart}&sortField=${sortField}`
    );
  }

  getApprovalRequest(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/approval_requests/${id}/`);
  }

  createApprovalRequest(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/approval_requests/`, data);
  }

  updateApprovalRequest(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/approval_requests/${id}/`, data);
  }

  deleteApprovalRequest(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/approval_requests/${id}/`);
  }

  // Approval response
  getApprovalResponses(
    {
      approverName = "",
      decisionRight = "",
      exclude = "",
      organisation = "",
      outcome = "",
      page = 1,
      requestor = "",
      reviewedEnd = "",
      reviewedStart = "",
      sortField = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/approval_responses?approverName=${approverName}&decisionRight=${decisionRight}&exclude=${exclude}&organisation=${organisation}&outcome=${outcome}&page=${page}&requestor=${requestor}&reviewedEnd=${reviewedEnd}&reviewedStart=${reviewedStart}&sortField=${sortField}`
    );
  }

  getApprovalResponse(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/approval_responses/${id}/`);
  }

  createApprovalResponse(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/approval_responses/`, data);
  }

  updateApprovalResponse(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/approval_responses/${id}/`, data);
  }

  deleteApprovalResponse(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/approval_responses/${id}/`);
  }

  // Approval role
  getApprovalRoles({ organisation = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/approval_roles?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getApprovalRole(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/approval_roles/${id}/`);
  }

  createApprovalRole(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/approval_roles/`, data);
  }

  updateApprovalRole(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/approval_roles/${id}/`, data);
  }

  deleteApprovalRole(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/approval_roles/${id}/`);
  }

  // Booking
  getBookings(
    {
      billable = "",
      bookingCode = "",
      bookingProfile = "",
      endEnd = "",
      endStart = "",
      engagement = "",
      organisation = "",
      page = 1,
      proposal = "",
      searchString = "",
      sortField = "",
      staff = "",
      startEnd = "",
      startStart = "",
      type = "",
      user = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/bookings?billable=${billable}&bookingCode=${bookingCode}&bookingProfile=${bookingProfile}&endEnd=${endEnd}&endStart=${endStart}&engagement=${engagement}&organisation=${organisation}&page=${page}&proposal=${proposal}&searchString=${searchString}&sortField=${sortField}&staff=${staff}&startEnd=${startEnd}&startStart=${startStart}&type=${type}&user=${user}`
    );
  }

  getBooking(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/bookings/${id}/`);
  }

  createBooking(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/bookings/`, data);
  }

  updateBooking(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/bookings/${id}/`, data);
  }

  deleteBooking(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/bookings/${id}/`);
  }

  // Booking code
  getBookingCodes(
    {
      billable = "",
      engagement = "",
      holding = "",
      open = "",
      organisation = "",
      page = 1,
      proposal = "",
      searchString = "",
      sortField = "",
      year = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/booking_codes?billable=${billable}&engagement=${engagement}&holding=${holding}&open=${open}&organisation=${organisation}&page=${page}&proposal=${proposal}&searchString=${searchString}&sortField=${sortField}&year=${year}`
    );
  }

  getBookingCode(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/booking_codes/${id}/`);
  }

  createBookingCode(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/booking_codes/`, data);
  }

  updateBookingCode(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/booking_codes/${id}/`, data);
  }

  deleteBookingCode(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/booking_codes/${id}/`);
  }

  // Case study
  getCaseStudies(
    { organisation = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/case_studies?organisation=${organisation}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getCaseStudy(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/case_studies/${id}/`);
  }

  createCaseStudy(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/case_studies/`, data);
  }

  updateCaseStudy(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/case_studies/${id}/`, data);
  }

  deleteCaseStudy(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/case_studies/${id}/`);
  }

  // Case study permission
  getCaseStudyPermissions(
    { caseStudy = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/case_study_permissions?caseStudy=${caseStudy}&page=${page}&searchString=${searchString}`
    );
  }

  getCaseStudyPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/case_study_permissions/${id}/`);
  }

  createCaseStudyPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/case_study_permissions/`, data);
  }

  updateCaseStudyPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/case_study_permissions/${id}/`,
      data
    );
  }

  deleteCaseStudyPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/case_study_permissions/${id}/`);
  }

  // Claim
  getClaims(
    {
      completedEnd = "",
      completedStart = "",
      organisation = "",
      page = 1,
      searchString = "",
      sortField = "",
      staff = "",
      status = "",
      submittedEnd = "",
      submittedStart = "",
      user = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/claims?completedEnd=${completedEnd}&completedStart=${completedStart}&organisation=${organisation}&page=${page}&searchString=${searchString}&sortField=${sortField}&staff=${staff}&status=${status}&submittedEnd=${submittedEnd}&submittedStart=${submittedStart}&user=${user}`
    );
  }

  getClaim(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/claims/${id}/`);
  }

  createClaim(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/claims/`, data);
  }

  updateClaim(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/claims/${id}/`, data);
  }

  deleteClaim(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/claims/${id}/`);
  }

  duplicateClaim(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/claims/${id}/duplicate/`);
  }

  reverseClaim(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/claims/${id}/reverse/`, data);
  }

  // Client
  getClients(
    {
      manager = "",
      market = "",
      organisation = "",
      page = 1,
      searchString = "",
      ticker = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/clients?manager=${manager}&market=${market}&organisation=${organisation}&page=${page}&searchString=${searchString}&ticker=${ticker}`
    );
  }

  getClient(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/clients/${id}/`);
  }

  createClient(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/clients/`, data);
  }

  updateClient(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/clients/${id}/`, data);
  }

  deleteClient(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/clients/${id}/`);
  }

  // Client action
  getClientActions(
    {
      category = "",
      client = "",
      dueEnd = "",
      dueStart = "",
      owner = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/client_actions?category=${category}&client=${client}&dueEnd=${dueEnd}&dueStart=${dueStart}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getClientAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_actions/${id}/`);
  }

  createClientAction(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/client_actions/`, data);
  }

  updateClientAction(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/client_actions/${id}/`, data);
  }

  deleteClientAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/client_actions/${id}/`);
  }

  // Client intel
  getClientIntel(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_intel/${id}/get_intel/`);
  }

  // Client KPI
  getClientKPIs(
    {
      client = "",
      page = 1,
      rating = "",
      searchString = "",
      sortField = "",
      units = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/client_kpis?client=${client}&page=${page}&rating=${rating}&searchString=${searchString}&sortField=${sortField}&units=${units}`
    );
  }

  getClientKPI(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_kpis/${id}/`);
  }

  createClientKPI(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/client_kpis/`, data);
  }

  updateClientKPI(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/client_kpis/${id}/`, data);
  }

  deleteClientKPI(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/client_kpis/${id}/`);
  }

  // Client need
  getClientNeeds({ client = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/client_needs?client=${client}&page=${page}&searchString=${searchString}`
    );
  }

  getClientNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_needs/${id}/`);
  }

  createClientNeed(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/client_needs/`, data);
  }

  updateClientNeed(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/client_needs/${id}/`, data);
  }

  deleteClientNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/client_needs/${id}/`);
  }

  // Client permission
  getClientPermissions({ client = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/client_permissions?client=${client}&page=${page}&searchString=${searchString}`
    );
  }

  getClientPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_permissions/${id}/`);
  }

  createClientPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/client_permissions/`, data);
  }

  updateClientPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/client_permissions/${id}/`, data);
  }

  deleteClientPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/client_permissions/${id}/`);
  }

  // Client program
  getClientPrograms(
    {
      client = "",
      page = 1,
      searchString = "",
      sortField = "",
      sponsor = "",
      year = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/client_programs?client=${client}&page=${page}&searchString=${searchString}&sortField=${sortField}&sponsor=${sponsor}&year=${year}`
    );
  }

  getClientProgram(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_programs/${id}/`);
  }

  createClientProgram(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/client_programs/`, data);
  }

  updateClientProgram(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/client_programs/${id}/`, data);
  }

  deleteClientProgram(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/client_programs/${id}/`);
  }

  // Client question
  getClientQuestions(
    {
      category = "",
      client = "",
      owner = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/client_questions?category=${category}&client=${client}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getClientQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_questions/${id}/`);
  }

  createClientQuestion(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/client_questions/`, data);
  }

  updateClientQuestion(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/client_questions/${id}/`, data);
  }

  deleteClientQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/client_questions/${id}/`);
  }

  // Client stakeholder
  getClientStakeholders({ client = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/client_stakeholders?client=${client}&page=${page}&searchString=${searchString}`
    );
  }

  getClientStakeholder(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/client_stakeholders/${id}/`);
  }

  createClientStakeholder(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/client_stakeholders/`, data);
  }

  updateClientStakeholder(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/client_stakeholders/${id}/`,
      data
    );
  }

  deleteClientStakeholder(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/client_stakeholders/${id}/`);
  }

  // Competency
  getCompetencies({ organisation = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/competencies?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getCompetency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/competencies/${id}/`);
  }

  createCompetency(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/competencies/`, data);
  }

  updateCompetency(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/competencies/${id}/`, data);
  }

  deleteCompetency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/competencies/${id}/`);
  }

  // Contact
  getContacts(
    {
      client = "",
      manager = "",
      opening = "",
      organisation = "",
      page = 1,
      role = "",
      searchString = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/contacts?client=${client}&manager=${manager}&opening=${opening}&organisation=${organisation}&page=${page}&role=${role}&searchString=${searchString}`
    );
  }

  getContact(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/contacts/${id}/`);
  }

  createContact(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/contacts/`, data);
  }

  updateContact(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/contacts/${id}/`, data);
  }

  deleteContact(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/contacts/${id}/`);
  }

  createCommunicationAction(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.post(
      `${BACKEND_URL}/api/v1/contacts/${id}/create_communication_action/`,
      data
    );
  }

  // Contact action
  getContactActions(
    {
      category = "",
      contact = "",
      dueEnd = "",
      dueStart = "",
      owner = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/contact_actions?category=${category}&contact=${contact}&dueEnd=${dueEnd}&dueStart=${dueStart}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getContactAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/contact_actions/${id}/`);
  }

  createContactAction(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/contact_actions/`, data);
  }

  updateContactAction(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/contact_actions/${id}/`, data);
  }

  deleteContactAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/contact_actions/${id}/`);
  }

  // Contact permission
  getContactPermissions({ contact = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/contact_permissions?contact=${contact}&page=${page}&searchString=${searchString}`
    );
  }

  getContactPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/contact_permissions/${id}/`);
  }

  createContactPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/contact_permissions/`, data);
  }

  updateContactPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/contact_permissions/${id}/`,
      data
    );
  }

  deleteContactPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/contact_permissions/${id}/`);
  }

  // Currency
  getCurrencies({ organisation = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/currencies?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getCurrency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/currencies/${id}/`);
  }

  createCurrency(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/currencies/`, data);
  }

  deleteCurrency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/currencies/${id}/`);
  }

  getCurrencyChoices(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/currencies/get_currency_choices/`);
  }

  getCurrencyRate(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.post(`${BACKEND_URL}/api/v1/currencies/${id}/get_rate/`, data);
  }

  getOrganisationCurrencyChoices({ organisation = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/currencies/get_organisation_currency_choices?organisation=${organisation}`
    );
  }

  // Currency exchange rate
  getCurrencyExchangeRates({ currency = "", page = 1 }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/currency_exchange_rates?currency=${currency}&page=${page}&`
    );
  }

  getCurrencyExchangeRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/currency_exchange_rates/${id}/`);
  }

  createCurrencyExchangeRate(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/currency_exchange_rates/`, data);
  }

  updateCurrencyExchangeRate(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/currency_exchange_rates/${id}/`,
      data
    );
  }

  deleteCurrencyExchangeRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/currency_exchange_rates/${id}/`);
  }

  // Demand profile
  generateDemandProfile(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/demand_profile/${id}/generate/`,
      data
    );
  }

  // Document
  generatePdf(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${NODE_SERVICES_URL}/api/v1/generatePdf/`, data, {
      responseType: "blob",
    });
  }

  generatePdfFromHtml(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${NODE_SERVICES_URL}/api/v1/generatePdfFromHtml/`,
      data,
      {
        responseType: "blob",
      }
    );
  }

  // Engagement
  getEngagements(
    {
      client = "",
      manager = "",
      organisation = "",
      page = 1,
      searchString = "",
      sortField = "",
      sponsor = "",
      status = "",
      user = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagements?client=${client}&manager=${manager}&organisation=${organisation}&page=${page}&searchString=${searchString}&sortField=${sortField}&sponsor=${sponsor}&status=${status}&user=${user}`
    );
  }

  getEngagement(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagements/${id}/`);
  }

  createEngagement(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagements/`, data);
  }

  updateEngagement(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagements/${id}/`, data);
  }

  deleteEngagement(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagements/${id}/`);
  }

  // Engagement action
  getEngagementActions(
    {
      category = "",
      dueEnd = "",
      dueStart = "",
      engagement = "",
      owner = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_actions?category=${category}&dueEnd=${dueEnd}&dueStart=${dueStart}&engagement=${engagement}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getEngagementAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_actions/${id}/`);
  }

  createEngagementAction(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_actions/`, data);
  }

  updateEngagementAction(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagement_actions/${id}/`, data);
  }

  deleteEngagementAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_actions/${id}/`);
  }

  // Engagement AI
  generateReportDataEngagement(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_ai/${id}/generate_report_data`
    );
  }

  // Engagement contact
  getEngagementContacts(
    { engagement = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_contacts?engagement=${engagement}&page=${page}&searchString=${searchString}`
    );
  }

  getEngagementContact(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_contacts/${id}/`);
  }

  createEngagementContact(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_contacts/`, data);
  }

  updateEngagementContact(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_contacts/${id}/`,
      data
    );
  }

  deleteEngagementContact(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_contacts/${id}/`);
  }

  // Engagement delivery issue
  getEngagementDeliveryIssues(
    {
      category = "",
      engagement = "",
      owner = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_delivery_issues?category=${category}&engagement=${engagement}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getEngagementDeliveryIssue(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_delivery_issues/${id}/`);
  }

  createEngagementDeliveryIssue(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/engagement_delivery_issues/`,
      data
    );
  }

  updateEngagementDeliveryIssue(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_delivery_issues/${id}/`,
      data
    );
  }

  deleteEngagementDeliveryIssue(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/engagement_delivery_issues/${id}/`
    );
  }

  // Engagement dependency
  getEngagementDependencies(
    { engagement = "", owner = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_dependencies?engagement=${engagement}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getEngagementDependency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_dependencies/${id}/`);
  }

  createEngagementDependency(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_dependencies/`, data);
  }

  updateEngagementDependency(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_dependencies/${id}/`,
      data
    );
  }

  deleteEngagementDependency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_dependencies/${id}/`);
  }

  // Engagement document
  getEngagementDocument(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_documents/${id}/`);
  }

  updateEngagementDocument(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_documents/${id}/`,
      data
    );
  }

  // Engagement fee
  getEngagementFees(
    { engagement = "", engagementService = "", page = 1, sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_fees?engagement=${engagement}&engagementService=${engagementService}&page=${page}&sortField=${sortField}`
    );
  }

  getEngagementFee(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_fees/${id}/`);
  }

  updateEngagementFee(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagement_fees/${id}/`, data);
  }

  // Engagement file
  downloadEngagement(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_files/${id}/docx`);
  }

  // Engagement labour expense
  getEngagementLabourExpenses(
    {
      engagement = "",
      engagementService = "",
      page = 1,
      role = "",
      sortField = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_labour_expenses?engagement=${engagement}&engagementService=${engagementService}&page=${page}&role=${role}&sortField=${sortField}`
    );
  }

  getEngagementLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_labour_expenses/${id}/`);
  }

  createEngagementLabourExpense(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/engagement_labour_expenses/`,
      data
    );
  }

  updateEngagementLabourExpense(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_labour_expenses/${id}/`,
      data
    );
  }

  deleteEngagementLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/engagement_labour_expenses/${id}/`
    );
  }

  // Engagement lesson
  getEngagementLessons(
    { engagement = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_lessons?engagement=${engagement}&page=${page}&searchString=${searchString}`
    );
  }

  getEngagementLesson(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_lessons/${id}/`);
  }

  createEngagementLesson(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_lessons/`, data);
  }

  updateEngagementLesson(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagement_lessons/${id}/`, data);
  }

  deleteEngagementLesson(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_lessons/${id}/`);
  }

  // Engagement milestone
  getEngagementMilestones(
    {
      engagement = "",
      page = 1,
      searchString = "",
      sortField = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_milestones?engagement=${engagement}&page=${page}&searchString=${searchString}&sortField=${sortField}&status=${status}`
    );
  }

  getEngagementMilestone(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_milestones/${id}/`);
  }

  createEngagementMilestone(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_milestones/`, data);
  }

  updateEngagementMilestone(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_milestones/${id}/`,
      data
    );
  }

  deleteEngagementMilestone(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_milestones/${id}/`);
  }

  // Engagement non-labour expense
  getEngagementNonLabourExpenses(
    {
      category = "",
      engagement = "",
      engagementService = "",
      page = 1,
      sortField = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_non_labour_expenses?category=${category}&engagement=${engagement}&engagementService=${engagementService}&page=${page}&sortField=${sortField}`
    );
  }

  getEngagementNonLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_non_labour_expenses/${id}/`
    );
  }

  createEngagementNonLabourExpense(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/engagement_non_labour_expenses/`,
      data
    );
  }

  updateEngagementNonLabourExpense(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_non_labour_expenses/${id}/`,
      data
    );
  }

  deleteEngagementNonLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/engagement_non_labour_expenses/${id}/`
    );
  }

  // Engagement permission
  getEngagementPermissions(
    { engagement = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_permissions?engagement=${engagement}&page=${page}&searchString=${searchString}`
    );
  }

  getEngagementPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_permissions/${id}/`);
  }

  createEngagementPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_permissions/`, data);
  }

  updateEngagementPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_permissions/${id}/`,
      data
    );
  }

  deleteEngagementPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_permissions/${id}/`);
  }

  // Engagement rate
  getEngagementRates(
    { engagement = "", page = 1, role = "", sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_rates?engagement=${engagement}&page=${page}&role=${role}&sortField=${sortField}`
    );
  }

  getEngagementRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_rates/${id}/`);
  }

  createEngagementRate(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_rates/`, data);
  }

  updateEngagementRate(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagement_rates/${id}/`, data);
  }

  deleteEngagementRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_rates/${id}/`);
  }

  // Engagement report
  getEngagementReports(
    {
      dateEnd = "",
      dateStart = "",
      engagement = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_reports?dateEnd=${dateEnd}&dateStart=${dateStart}&engagement=${engagement}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getEngagementReport(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_reports/${id}/`);
  }

  createEngagementReport(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_reports/`, data);
  }

  updateEngagementReport(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagement_reports/${id}/`, data);
  }

  deleteEngagementReport(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_reports/${id}/`);
  }

  // Engagement report file
  downloadEngagementReport(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_report_files/${id}/docx`
    );
  }

  // Engagement risk
  getEngagementRisks(
    {
      category = "",
      engagement = "",
      impact = "",
      likelihood = "",
      owner = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_risks?category=${category}&engagement=${engagement}&impact=${impact}&likelihood=${likelihood}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getEngagementRisk(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_risks/${id}/`);
  }

  createEngagementRisk(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_risks/`, data);
  }

  updateEngagementRisk(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagement_risks/${id}/`, data);
  }

  deleteEngagementRisk(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_risks/${id}/`);
  }

  // Engagement service
  getEngagementServices(
    { engagement = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_services?engagement=${engagement}&page=${page}&searchString=${searchString}`
    );
  }

  getEngagementService(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_services/${id}/`);
  }

  createEngagementService(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_services/`, data);
  }

  updateEngagementService(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_services/${id}/`,
      data
    );
  }

  deleteEngagementService(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_services/${id}/`);
  }

  // Engagement service activity
  getEngagementServiceActivities({ engagement = "", page = 1 }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_service_activities?engagement=${engagement}&page=${page}`
    );
  }

  getEngagementServiceActivity(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_service_activities/${id}/`
    );
  }

  createEngagementServiceActivity(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/engagement_service_activities/`,
      data
    );
  }

  updateEngagementServiceActivity(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_service_activities/${id}/`,
      data
    );
  }

  deleteEngagementServiceActivity(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/engagement_service_activities/${id}/`
    );
  }

  // Engagement service deliverable
  getEngagementServiceDeliverables(
    {
      dueEnd = "",
      dueStart = "",
      engagement = "",
      milestone = "",
      page = 1,
      searchString = "",
      service = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_service_deliverables?dueEnd=${dueEnd}&dueStart=${dueStart}&engagement=${engagement}&milestone=${milestone}&page=${page}&searchString=${searchString}&service=${service}&status=${status}`
    );
  }

  getEngagementServiceDeliverable(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_service_deliverables/${id}/`
    );
  }

  createEngagementServiceDeliverable(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/engagement_service_deliverables/`,
      data
    );
  }

  updateEngagementServiceDeliverable(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/engagement_service_deliverables/${id}/`,
      data
    );
  }

  deleteEngagementServiceDeliverable(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/engagement_service_deliverables/${id}/`
    );
  }

  // Engagement upload
  getEngagementUploads({ engagement = "", page = 1 }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/engagement_uploads?engagement=${engagement}&page=${page}`
    );
  }

  getEngagementUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/engagement_uploads/${id}/`);
  }

  createEngagementUpload(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/engagement_uploads/`, data);
  }

  updateEngagementUpload(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/engagement_uploads/${id}/`, data);
  }

  deleteEngagementUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/engagement_uploads/${id}/`);
  }

  // Expense
  getExpenses(
    {
      billable = "",
      bookingCode = "",
      category = "",
      claim = "",
      dateEnd = "",
      dateStart = "",
      engagement = "",
      organisation = "",
      page = 1,
      proposal = "",
      reversed = "",
      sortField = "",
      staff = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/expenses?billable=${billable}&bookingCode=${bookingCode}&category=${category}&claim=${claim}&dateEnd=${dateEnd}&dateStart=${dateStart}&engagement=${engagement}&organisation=${organisation}&page=${page}&proposal=${proposal}&reversed=${reversed}&sortField=${sortField}&staff=${staff}`
    );
  }

  getExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/expenses/${id}/`);
  }

  createExpense(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/expenses/`, data);
  }

  updateExpense(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/expenses/${id}/`, data);
  }

  deleteExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/expenses/${id}/`);
  }

  // Expense category
  getExpenseCategories(
    { organisation = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/expense_categories?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getExpenseCategory(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/expense_categories/${id}/`);
  }

  createExpenseCategory(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/expense_categories/`, data);
  }

  updateExpenseCategory(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/expense_categories/${id}/`, data);
  }

  deleteExpenseCategory(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/expense_categories/${id}/`);
  }

  // Help content
  getHelpContent(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/help_content/`);
  }

  // HubSpot integration
  hubspotAuthorise(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/hubspot_integration/${id}/authorise`
    );
  }

  hubspotDeauthorise(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/hubspot_integration/${id}/deauthorise`
    );
  }

  hubspotGetContact(id, token, email) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/hubspot_integration/${id}/get_contact?email=${email}`
    );
  }

  hubspotSyncContacts(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/hubspot_integration/${id}/sync_contacts`
    );
  }

  // Insight
  getInsights(
    { organisation = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/insights?organisation=${organisation}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getInsight(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/insights/${id}/`);
  }

  createInsight(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/insights/`, data);
  }

  updateInsight(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/insights/${id}/`, data);
  }

  deleteInsight(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/insights/${id}/`);
  }

  // Insight permission
  getInsightPermissions({ insight = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/insight_permissions?insight=${insight}&page=${page}&searchString=${searchString}`
    );
  }

  getInsightPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/insight_permissions/${id}/`);
  }

  createInsightPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/insight_permissions/`, data);
  }

  updateInsightPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/insight_permissions/${id}/`,
      data
    );
  }

  deleteInsightPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/insight_permissions/${id}/`);
  }

  // Interview
  getInterviews(
    {
      contact = "",
      dateEnd = "",
      dateStart = "",
      manager = "",
      organisation = "",
      page = 1,
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/interviews?contact=${contact}&dateEnd=${dateEnd}&dateStart=${dateStart}&manager=${manager}&organisation=${organisation}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getInterview(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/interviews/${id}/`);
  }

  createInterview(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/interviews/`, data);
  }

  updateInterview(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/interviews/${id}/`, data);
  }

  deleteInterview(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/interviews/${id}/`);
  }

  // Interview permission
  getInterviewPermissions(
    { interview = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/interview_permissions?interview=${interview}&page=${page}&searchString=${searchString}`
    );
  }

  getInterviewPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/interview_permissions/${id}/`);
  }

  createInterviewPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/interview_permissions/`, data);
  }

  updateInterviewPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/interview_permissions/${id}/`,
      data
    );
  }

  deleteInterviewPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/interview_permissions/${id}/`);
  }

  // Interview question
  getInterviewQuestions(
    { interview = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/interview_questions?interview=${interview}&page=${page}&searchString=${searchString}`
    );
  }

  getInterviewQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/interview_questions/${id}/`);
  }

  createInterviewQuestion(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/interview_questions/`, data);
  }

  updateInterviewQuestion(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/interview_questions/${id}/`,
      data
    );
  }

  deleteInterviewQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/interview_questions/${id}/`);
  }

  // Invoice
  getInvoices(
    {
      bookingCode = "",
      client = "",
      dueEnd = "",
      dueStart = "",
      issuedEnd = "",
      issuedStart = "",
      manager = "",
      organisation = "",
      page = 1,
      paidEnd = "",
      paidStart = "",
      searchString = "",
      sortField = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoices?bookingCode=${bookingCode}&client=${client}&dueEnd=${dueEnd}&dueStart=${dueStart}&issuedEnd=${issuedEnd}&issuedStart=${issuedStart}&manager=${manager}&organisation=${organisation}&page=${page}&paidEnd=${paidEnd}&paidStart=${paidStart}&searchString=${searchString}&sortField=${sortField}&status=${status}`
    );
  }

  getInvoice(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/invoices/${id}/`);
  }

  createInvoice(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/invoices/`, data);
  }

  updateInvoice(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/invoices/${id}/`, data);
  }

  deleteInvoice(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/invoices/${id}/`);
  }

  // Invoice booking
  getInvoiceBookings(
    { invoice = "", page = 1, searchString = "", sortField = "", staff = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_bookings?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}&staff=${staff}`
    );
  }

  getInvoiceBooking(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/invoice_bookings/${id}/`);
  }

  updateInvoiceBooking(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/invoice_bookings/${id}/`, data);
  }

  deleteInvoiceBooking(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/invoice_bookings/${id}/`);
  }

  // Invoice booking credit
  getInvoiceBookingCredits(
    { invoice = "", page = 1, searchString = "", sortField = "", staff = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_booking_credits?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}&staff=${staff}`
    );
  }

  // Invoice document
  getInvoiceDocument(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/invoice_documents/${id}/`);
  }

  updateInvoiceDocument(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/invoice_documents/${id}/`, data);
  }

  // Invoice expense
  getInvoiceExpenses(
    { invoice = "", page = 1, searchString = "", sortField = "", staff = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_expenses?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}&staff=${staff}`
    );
  }

  getInvoiceExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/invoice_expenses/${id}/`);
  }

  updateInvoiceExpense(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/invoice_expenses/${id}/`, data);
  }

  deleteInvoiceExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/invoice_expenses/${id}/`);
  }

  // Invoice expense credit
  getInvoiceExpenseCredits(
    { invoice = "", page = 1, searchString = "", sortField = "", staff = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_expense_credits?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}&staff=${staff}`
    );
  }

  // Invoice milestone
  getInvoiceMilestones(
    { invoice = "", page = 1, searchString = "", sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_milestones?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}`
    );
  }

  getInvoiceMilestone(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/invoice_milestones/${id}/`);
  }

  updateInvoiceMilestone(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/invoice_milestones/${id}/`, data);
  }

  deleteInvoiceMilestone(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/invoice_milestones/${id}/`);
  }

  // Invoice milestone credit
  getInvoiceMilestoneCredits(
    { invoice = "", page = 1, searchString = "", sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_milestone_credits?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}`
    );
  }

  // Invoice other
  getInvoiceOthers(
    { invoice = "", page = 1, searchString = "", sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_others?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}`
    );
  }

  getInvoiceOther(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/invoice_others/${id}/`);
  }

  createInvoiceOther(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/invoice_others/`, data);
  }

  updateInvoiceOther(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/invoice_others/${id}/`, data);
  }

  deleteInvoiceOther(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/invoice_others/${id}/`);
  }

  // Invoice other credit
  getInvoiceOtherCredits(
    { invoice = "", page = 1, searchString = "", sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_other_credits?invoice=${invoice}&page=${page}&searchString=${searchString}&sortField=${sortField}`
    );
  }

  // Invoice permission
  getInvoicePermissions({ invoice = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/invoice_permissions?invoice=${invoice}&page=${page}&searchString=${searchString}`
    );
  }

  getInvoicePermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/invoice_permissions/${id}/`);
  }

  createInvoicePermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/invoice_permissions/`, data);
  }

  updateInvoicePermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/invoice_permissions/${id}/`,
      data
    );
  }

  deleteInvoicePermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/invoice_permissions/${id}/`);
  }

  // Knowledge base document
  getKnowledgeBaseDocuments(
    { organisation = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/knowledge_base_documents?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getKnowledgeBaseDocument(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/knowledge_base_documents/${id}/`);
  }

  createKnowledgeBaseDocument(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/knowledge_base_documents/`, data);
  }

  updateKnowledgeBaseDocument(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/knowledge_base_documents/${id}/`,
      data
    );
  }

  deleteKnowledgeBaseDocument(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/knowledge_base_documents/${id}/`
    );
  }

  // Leave category
  getLeaveCategories(
    { organisation = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/leave_categories?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getLeaveCategory(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/leave_categories/${id}/`);
  }

  createLeaveCategory(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/leave_categories/`, data);
  }

  updateLeaveCategory(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/leave_categories/${id}/`, data);
  }

  deleteLeaveCategory(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/leave_categories/${id}/`);
  }

  // Leave request
  getLeaveRequests(
    {
      category = "",
      completedEnd = "",
      completedStart = "",
      organisation = "",
      page = 1,
      sortField = "",
      staff = "",
      status = "",
      submittedEnd = "",
      submittedStart = "",
      user = "",
      weekLabel = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/leave_requests?category=${category}&completedEnd=${completedEnd}&completedStart=${completedStart}&organisation=${organisation}&page=${page}&sortField=${sortField}&staff=${staff}&status=${status}&submittedEnd=${submittedEnd}&submittedStart=${submittedStart}&user=${user}&weekLabel=${weekLabel}`
    );
  }

  getLeaveRequest(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/leave_requests/${id}/`);
  }

  createLeaveRequest(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/leave_requests/`, data);
  }

  updateLeaveRequest(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/leave_requests/${id}/`, data);
  }

  deleteLeaveRequest(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/leave_requests/${id}/`);
  }

  cancelLeaveRequest(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/leave_requests/${id}/cancel`);
  }

  // Location
  getLocations({ organisation = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/locations?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getLocation(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/locations/${id}/`);
  }

  createLocation(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/locations/`, data);
  }

  updateLocation(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/locations/${id}/`, data);
  }

  deleteLocation(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/locations/${id}/`);
  }

  // Manager
  getManagers(
    { approvalRole = "", organisation = "", page = 1, searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/managers?approvalRole=${approvalRole}&organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getManager(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/managers/${id}/`);
  }

  updateManager(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/managers/${id}/`, data);
  }

  // Market
  getMarkets(
    { organisation = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/markets?organisation=${organisation}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getMarket(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/markets/${id}/`);
  }

  createMarket(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/markets/`, data);
  }

  updateMarket(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/markets/${id}/`, data);
  }

  deleteMarket(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/markets/${id}/`);
  }

  // Market case study
  getMarketCaseStudies({ market = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/market_case_studies?market=${market}&page=${page}&searchString=${searchString}`
    );
  }

  getMarketCaseStudy(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/market_case_studies/${id}/`);
  }

  createMarketCaseStudy(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/market_case_studies/`, data);
  }

  updateMarketCaseStudy(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/market_case_studies/${id}/`,
      data
    );
  }

  deleteMarketCaseStudy(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/market_case_studies/${id}/`);
  }

  // Market insight
  getMarketInsights({ market = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/market_insights?market=${market}&page=${page}&searchString=${searchString}`
    );
  }

  getMarketInsight(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/market_insights/${id}/`);
  }

  createMarketInsight(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/market_insights/`, data);
  }

  updateMarketInsight(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/market_insights/${id}/`, data);
  }

  deleteMarketInsight(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/market_insights/${id}/`);
  }

  // Market need
  getMarketNeeds({ market = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/market_needs?market=${market}&page=${page}&searchString=${searchString}`
    );
  }

  getMarketNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/market_needs/${id}/`);
  }

  createMarketNeed(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/market_needs/`, data);
  }

  updateMarketNeed(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/market_needs/${id}/`, data);
  }

  deleteMarketNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/market_needs/${id}/`);
  }

  // Market permission
  getMarketPermissions({ market = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/market_permissions?market=${market}&page=${page}&searchString=${searchString}`
    );
  }

  getMarketPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/market_permissions/${id}/`);
  }

  createMarketPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/market_permissions/`, data);
  }

  updateMarketPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/market_permissions/${id}/`, data);
  }

  deleteMarketPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/market_permissions/${id}/`);
  }

  // Membership
  getMemberships({ member = "", organisation = "", page = 1 }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/memberships?member=${member}&organisation=${organisation}&page=${page}`
    );
  }

  getMembership(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/memberships/${id}/`);
  }

  createMembership(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/memberships/`, data);
  }

  updateMembership(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/memberships/${id}/`, data);
  }

  deleteMembership(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/memberships/${id}/`);
  }

  // Need
  getNeeds(
    { organisation = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/needs?organisation=${organisation}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/needs/${id}/`);
  }

  createNeed(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/needs/`, data);
  }

  updateNeed(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/needs/${id}/`, data);
  }

  deleteNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/needs/${id}/`);
  }

  // Need permission
  getNeedPermissions({ need = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/need_permissions?need=${need}&page=${page}&searchString=${searchString}`
    );
  }

  getNeedPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/need_permissions/${id}/`);
  }

  createNeedPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/need_permissions/`, data);
  }

  updateNeedPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/need_permissions/${id}/`, data);
  }

  deleteNeedPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/need_permissions/${id}/`);
  }

  // Need question
  getNeedQuestions({ need = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/need_questions?need=${need}&page=${page}&searchString=${searchString}`
    );
  }

  getNeedQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/need_questions/${id}/`);
  }

  createNeedQuestion(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/need_questions/`, data);
  }

  updateNeedQuestion(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/need_questions/${id}/`, data);
  }

  deleteNeedQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/need_questions/${id}/`);
  }

  // Notification
  getNotifications({ organisation = "", page = 1 }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/notifications?organisation=${organisation}&page=${page}`
    );
  }

  deleteNotification(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/notifications/${id}/`);
  }

  // Opening
  getOpenings(
    {
      closedEnd = "",
      closedStart = "",
      location = "",
      manager = "",
      openedEnd = "",
      openedStart = "",
      organisation = "",
      page = 1,
      role = "",
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/openings?closedEnd=${closedEnd}&closedStart=${closedStart}&location=${location}&manager=${manager}&openedEnd=${openedEnd}&openedStart=${openedStart}&organisation=${organisation}&page=${page}&role=${role}&searchString=${searchString}&status=${status}`
    );
  }

  getOpening(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/openings/${id}/`);
  }

  createOpening(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/openings/`, data);
  }

  updateOpening(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/openings/${id}/`, data);
  }

  deleteOpening(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/openings/${id}/`);
  }

  // Opening application
  createOpeningApplication(data) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/opening_applications/`, data);
  }

  // Opening application configuration
  getOpeningApplicationConfiguration(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/opening_application_configurations/${id}/`
    );
  }

  updateOpeningApplicationConfiguration(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/opening_application_configurations/${id}/`,
      data
    );
  }

  applyOpeningApplicationConfiguration(id) {
    return axios.get(
      `${BACKEND_URL}/api/v1/opening_application_configurations/apply/${id}/`
    );
  }

  // Opening candidate
  getOpeningCandidates(
    { page = 1, opening = "", searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/opening_candidates?page=${page}&opening=${opening}&searchString=${searchString}&status=${status}`
    );
  }

  getOpeningCandidate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/opening_candidates/${id}/`);
  }

  updateOpeningCandidate(id, token, data) {
    //
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/opening_candidates/${id}/`, data);
  }

  // Opening document
  getOpeningDocument(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/opening_documents/${id}/`);
  }

  updateOpeningDocument(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/opening_documents/${id}/`, data);
  }

  // Opening evaluation
  getOpeningEvaluations(
    {
      interviewer = "",
      page = 1,
      opening = "",
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/opening_evaluations?interviewer=${interviewer}&page=${page}&opening=${opening}&searchString=${searchString}&status=${status}`
    );
  }

  getOpeningEvaluation(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/opening_evaluations/${id}/`);
  }

  createOpeningEvaluation(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/opening_evaluations/`, data);
  }

  updateOpeningEvaluation(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/opening_evaluations/${id}/`,
      data
    );
  }

  deleteOpeningEvaluation(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/opening_evaluations/${id}/`);
  }

  // Opening evaluation rating
  getOpeningEvaluationRatings({ evaluation = "", page = 1 }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/opening_evaluation_ratings?evaluation=${evaluation}&page=${page}`
    );
  }

  updateOpeningEvaluationRating(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/opening_evaluation_ratings/${id}/`,
      data
    );
  }

  // Opening file
  downloadOpening(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/opening_files/${id}/docx`);
  }

  // Opening permission
  getOpeningPermissions({ opening = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/opening_permissions?opening=${opening}&page=${page}&searchString=${searchString}`
    );
  }

  getOpeningPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/opening_permissions/${id}/`);
  }

  createOpeningPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/opening_permissions/`, data);
  }

  updateOpeningPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/opening_permissions/${id}/`,
      data
    );
  }

  deleteOpeningPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/opening_permissions/${id}/`);
  }

  // Organisation
  getOrganisations(
    { about = "", owner = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/organisations?about=${about}&owner=${owner}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getOrganisation(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/organisations/${id}/`);
  }

  createOrganisation(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/organisations/`, data);
  }

  updateOrganisation(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/organisations/${id}/`, data);
  }

  deleteOrganisation(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/organisations/${id}/`);
  }

  checkPermissions(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/organisations/${id}/check_own_permissions/`
    );
  }

  checkSubscription(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/organisations/${id}/check_subscription/`
    );
  }

  // Payment
  createCheckout(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/payments/create_checkout_session/`,
      data
    );
  }

  createCustomerPortal(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/payments/create_customer_portal_session/`,
      data
    );
  }

  // Proposal
  getProposals(
    {
      client = "",
      contact = "",
      dueEnd = "",
      dueStart = "",
      exclude = "",
      manager = "",
      organisation = "",
      page = 1,
      searchString = "",
      sortField = "",
      status = "",
      unlinked = "",
      user = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposals?client=${client}&contact=${contact}&dueEnd=${dueEnd}&dueStart=${dueStart}&exclude=${exclude}&manager=${manager}&organisation=${organisation}&page=${page}&searchString=${searchString}&sortField=${sortField}&status=${status}&unlinked=${unlinked}&user=${user}`
    );
  }

  getProposal(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposals/${id}/`);
  }

  createProposal(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposals/`, data);
  }

  updateProposal(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposals/${id}/`, data);
  }

  deleteProposal(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposals/${id}/`);
  }

  // Proposal action
  getProposalActions(
    {
      category = "",
      dueEnd = "",
      dueStart = "",
      owner = "",
      page = 1,
      proposal = "",
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_actions?category=${category}&dueEnd=${dueEnd}&dueStart=${dueStart}&owner=${owner}&page=${page}&proposal=${proposal}&searchString=${searchString}&status=${status}`
    );
  }

  getProposalAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_actions/${id}/`);
  }

  createProposalAction(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_actions/`, data);
  }

  updateProposalAction(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_actions/${id}/`, data);
  }

  deleteProposalAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_actions/${id}/`);
  }

  // Proposal AI
  predictWinProposal(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_ai/${id}/predict_win`);
  }

  // Proposal case study
  getProposalCaseStudies({ page = 1, proposal = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_case_studies?page=${page}&proposal=${proposal}`
    );
  }

  getProposalCaseStudy(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_case_studies/${id}/`);
  }

  createProposalCaseStudy(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_case_studies/`, data);
  }

  updateProposalCaseStudy(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_case_studies/${id}/`,
      data
    );
  }

  deleteProposalCaseStudy(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_case_studies/${id}/`);
  }

  // Proposal competitor
  getProposalCompetitors(
    { page = 1, proposal = "", searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_competitors?page=${page}&proposal=${proposal}&searchString=${searchString}`
    );
  }

  getProposalCompetitor(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_competitors/${id}/`);
  }

  createProposalCompetitor(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_competitors/`, data);
  }

  updateProposalCompetitor(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_competitors/${id}/`,
      data
    );
  }

  deleteProposalCompetitor(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_competitors/${id}/`);
  }

  // Proposal contact
  getProposalContacts({ page = 1, proposal = "", searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_contacts?page=${page}&proposal=${proposal}&searchString=${searchString}`
    );
  }

  getProposalContact(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_contacts/${id}/`);
  }

  createProposalContact(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_contacts/`, data);
  }

  updateProposalContact(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_contacts/${id}/`, data);
  }

  deleteProposalContact(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_contacts/${id}/`);
  }

  // Proposal document
  getProposalDocument(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_documents/${id}/`);
  }

  updateProposalDocument(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_documents/${id}/`, data);
  }

  // Proposal fee
  getProposalFees(
    { page = 1, proposal = "", proposalService = "", sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_fees?page=${page}&proposal=${proposal}&proposalService=${proposalService}&sortField=${sortField}`
    );
  }

  getProposalFee(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_fees/${id}/`);
  }

  updateProposalFee(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_fees/${id}/`, data);
  }

  // Proposal file
  downloadProposal(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_files/${id}/docx`);
  }

  // Proposal labour expense
  getProposalLabourExpenses(
    {
      page = 1,
      proposal = "",
      proposalService = "",
      role = "",
      sortField = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_labour_expenses?page=${page}&proposal=${proposal}&proposalService=${proposalService}&role=${role}&sortField=${sortField}`
    );
  }

  getProposalLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_labour_expenses/${id}/`);
  }

  createProposalLabourExpense(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_labour_expenses/`, data);
  }

  updateProposalLabourExpense(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_labour_expenses/${id}/`,
      data
    );
  }

  deleteProposalLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/proposal_labour_expenses/${id}/`
    );
  }

  // Proposal legal section
  getProposalLegalSections(
    {
      amended = "",
      attachment = "",
      comments = "",
      number = "",
      originator = "",
      page = 1,
      proposal = "",
      proposed = "",
      title = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_legal_sections?amended=${amended}&attachment=${attachment}&comments=${comments}&number=${number}&originator=${originator}&page=${page}&proposal=${proposal}&proposed=${proposed}&title=${title}&status=${status}`
    );
  }

  getProposalLegalSection(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_legal_sections/${id}/`);
  }

  createProposalLegalSection(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_legal_sections/`, data);
  }

  updateProposalLegalSection(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_legal_sections/${id}/`,
      data
    );
  }

  deleteProposalLegalSection(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_legal_sections/${id}/`);
  }

  // Proposal lesson
  getProposalLessons({ page = 1, proposal = "", searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_lessons?page=${page}&proposal=${proposal}&searchString=${searchString}`
    );
  }

  getProposalLesson(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_lessons/${id}/`);
  }

  createProposalLesson(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_lessons/`, data);
  }

  updateProposalLesson(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_lessons/${id}/`, data);
  }

  deleteProposalLesson(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_lessons/${id}/`);
  }

  // Proposal milestone
  getProposalMilestones(
    { page = 1, proposal = "", searchString = "", sortField = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_milestones?page=${page}&proposal=${proposal}&searchString=${searchString}&sortField=${sortField}&status=${status}`
    );
  }

  getProposalMilestone(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_milestones/${id}/`);
  }

  createProposalMilestone(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_milestones/`, data);
  }

  updateProposalMilestone(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_milestones/${id}/`,
      data
    );
  }

  deleteProposalMilestone(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_milestones/${id}/`);
  }

  // Proposal non-labour expense
  getProposalNonLabourExpenses(
    {
      category = "",
      page = 1,
      proposal = "",
      proposalService = "",
      sortField = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_non_labour_expenses?category=${category}&page=${page}&proposal=${proposal}&proposalService=${proposalService}&sortField=${sortField}`
    );
  }

  getProposalNonLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_non_labour_expenses/${id}/`
    );
  }

  createProposalNonLabourExpense(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/proposal_non_labour_expenses/`,
      data
    );
  }

  updateProposalNonLabourExpense(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_non_labour_expenses/${id}/`,
      data
    );
  }

  deleteProposalNonLabourExpense(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/proposal_non_labour_expenses/${id}/`
    );
  }

  // Proposal permission
  getProposalPermissions(
    { page = 1, proposal = "", searchString = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_permissions?page=${page}&proposal=${proposal}&searchString=${searchString}`
    );
  }

  getProposalPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_permissions/${id}/`);
  }

  createProposalPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_permissions/`, data);
  }

  updateProposalPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_permissions/${id}/`,
      data
    );
  }

  deleteProposalPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_permissions/${id}/`);
  }

  // Proposal question
  getProposalQuestions(
    {
      category = "",
      owner = "",
      page = 1,
      proposal = "",
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_questions?category=${category}&owner=${owner}&page=${page}&proposal=${proposal}&searchString=${searchString}&status=${status}`
    );
  }

  getProposalQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_questions/${id}/`);
  }

  createProposalQuestion(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_questions/`, data);
  }

  updateProposalQuestion(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_questions/${id}/`, data);
  }

  deleteProposalQuestion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_questions/${id}/`);
  }

  // Proposal rate
  getProposalRates(
    { page = 1, proposal = "", role = "", sortField = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_rates?page=${page}&proposal=${proposal}&role=${role}&sortField=${sortField}`
    );
  }

  getProposalRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_rates/${id}/`);
  }

  createProposalRate(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_rates/`, data);
  }

  updateProposalRate(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_rates/${id}/`, data);
  }

  deleteProposalRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_rates/${id}/`);
  }

  // Proposal requirement
  getProposalRequirements(
    {
      category = "",
      owner = "",
      page = 1,
      proposal = "",
      ref = "",
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_requirements?category=${category}&owner=${owner}&page=${page}&proposal=${proposal}&ref=${ref}&searchString=${searchString}&status=${status}`
    );
  }

  getProposalRequirement(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_requirements/${id}/`);
  }

  createProposalRequirement(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_requirements/`, data);
  }

  updateProposalRequirement(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_requirements/${id}/`,
      data
    );
  }

  deleteProposalRequirement(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_requirements/${id}/`);
  }

  // Proposal risk
  getProposalRisks(
    {
      category = "",
      impact = "",
      likelihood = "",
      name = "",
      owner = "",
      page = 1,
      proposal = "",
      searchString = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_risks?category=${category}&impact=${impact}&likelihood=${likelihood}&name=${name}&owner=${owner}&page=${page}&proposal=${proposal}&searchString=${searchString}&status=${status}`
    );
  }

  getProposalRisk(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_risks/${id}/`);
  }

  createProposalRisk(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_risks/`, data);
  }

  updateProposalRisk(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_risks/${id}/`, data);
  }

  deleteProposalRisk(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_risks/${id}/`);
  }

  // Proposal service
  getProposalServices({ page = 1, proposal = "", searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_services?page=${page}&proposal=${proposal}&searchString=${searchString}`
    );
  }

  getProposalService(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_services/${id}/`);
  }

  createProposalService(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_services/`, data);
  }

  updateProposalService(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_services/${id}/`, data);
  }

  deleteProposalService(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_services/${id}/`);
  }

  // Proposal service activity
  getProposalServiceActivities({ page = 1, proposal = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_service_activities?page=${page}&proposal=${proposal}`
    );
  }

  getProposalServiceActivity(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_service_activities/${id}/`
    );
  }

  createProposalServiceActivity(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/proposal_service_activities/`,
      data
    );
  }

  updateProposalServiceActivity(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_service_activities/${id}/`,
      data
    );
  }

  deleteProposalServiceActivity(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/proposal_service_activities/${id}/`
    );
  }

  // Proposal service deliverable
  getProposalServiceDeliverables(
    {
      dueEnd = "",
      dueStart = "",
      milestone = "",
      page = 1,
      proposal = "",
      searchString = "",
      service = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_service_deliverables?dueEnd=${dueEnd}&dueStart=${dueStart}&milestone=${milestone}&page=${page}&proposal=${proposal}&searchString=${searchString}&service=${service}`
    );
  }

  getProposalServiceDeliverable(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_service_deliverables/${id}/`
    );
  }

  createProposalServiceDeliverable(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/proposal_service_deliverables/`,
      data
    );
  }

  updateProposalServiceDeliverable(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/proposal_service_deliverables/${id}/`,
      data
    );
  }

  deleteProposalServiceDeliverable(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/proposal_service_deliverables/${id}/`
    );
  }

  // Proposal staff
  getProposalStaffList({ page = 1, proposal = "", searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_staff?page=${page}&proposal=${proposal}&searchString=${searchString}`
    );
  }

  getProposalStaff(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_staff/${id}/`);
  }

  createProposalStaff(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_staff/`, data);
  }

  updateProposalStaff(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_staff/${id}/`, data);
  }

  deleteProposalStaff(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_staff/${id}/`);
  }

  // Proposal upload
  getProposalUploads({ page = 1, proposal = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/proposal_uploads?page=${page}&proposal=${proposal}`
    );
  }

  getProposalUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/proposal_uploads/${id}/`);
  }

  createProposalUpload(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/proposal_uploads/`, data);
  }

  updateProposalUpload(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/proposal_uploads/${id}/`, data);
  }

  deleteProposalUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/proposal_uploads/${id}/`);
  }

  // Rank
  getRanks({ organisation = "", page = 1, searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/ranks?organisation=${organisation}&page=${page}&searchString=${searchString}`
    );
  }

  getRank(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/ranks/${id}/`);
  }

  createRank(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/ranks/`, data);
  }

  updateRank(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/ranks/${id}/`, data);
  }

  deleteRank(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/ranks/${id}/`);
  }

  // Rank cost rate
  getRankCostRates({ page = 1, rank = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/rank_cost_rates?page=${page}&rank=${rank}`
    );
  }

  getRankCostRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/rank_cost_rates/${id}/`);
  }

  createRankCostRate(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/rank_cost_rates/`, data);
  }

  updateRankCostRate(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/rank_cost_rates/${id}/`, data);
  }

  deleteRankCostRate(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/rank_cost_rates/${id}/`);
  }

  // Reimbursement
  getReimbursements(
    {
      organisation = "",
      page = 1,
      paidEnd = "",
      paidStart = "",
      searchString = "",
      sortField = "",
      staff = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/reimbursements?organisation=${organisation}&page=${page}&paidEnd=${paidEnd}&paidStart=${paidStart}&searchString=${searchString}&sortField=${sortField}&staff=${staff}&status=${status}`
    );
  }

  getReimbursement(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/reimbursements/${id}/`);
  }

  updateReimbursement(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/reimbursements/${id}/`, data);
  }

  // Role
  getRoles(
    { organisation = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/roles?organisation=${organisation}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getRole(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/roles/${id}/`);
  }

  createRole(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/roles/`, data);
  }

  updateRole(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/roles/${id}/`, data);
  }

  deleteRole(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/roles/${id}/`);
  }

  // Role competency
  getRoleCompetencies({ page = 1, role = "", searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/role_competencies?page=${page}&role=${role}&searchString=${searchString}`
    );
  }

  getRoleCompetency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/role_competencies/${id}/`);
  }

  createRoleCompetency(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/role_competencies/`, data);
  }

  updateRoleCompetency(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/role_competencies/${id}/`, data);
  }

  deleteRoleCompetency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/role_competencies/${id}/`);
  }

  // Role permission
  getRolePermissions({ page = 1, role = "", searchString = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/role_permissions?page=${page}&role=${role}&searchString=${searchString}`
    );
  }

  getRolePermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/role_permissions/${id}/`);
  }

  createRolePermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/role_permissions/`, data);
  }

  updateRolePermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/role_permissions/${id}/`, data);
  }

  deleteRolePermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/role_permissions/${id}/`);
  }

  // Salary benchmark
  getSalaryBenchmarks({ country = "", firm = "" }) {
    return axios.get(
      `${BACKEND_URL}/api/v1/salary_benchmarks/?country=${country}&firm=${firm}`
    );
  }

  // Seat
  getSeats(
    {
      expiresAtEnd = "",
      expiresAtStart = "",
      member = "",
      organisation = "",
      page = 1,
      sortField = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/seats?expiresAtEnd=${expiresAtEnd}&expiresAtStart=${expiresAtStart}&member=${member}&organisation=${organisation}&page=${page}&sortField=${sortField}&status=${status}`
    );
  }

  getSeat(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/seats/${id}/`);
  }

  updateSeat(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/seats/${id}/`, data);
  }

  // Service
  getServices(
    { organisation = "", page = 1, searchString = "", status = "" },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/services?organisation=${organisation}&page=${page}&searchString=${searchString}&status=${status}`
    );
  }

  getService(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/services/${id}/`);
  }

  createService(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/services/`, data);
  }

  updateService(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/services/${id}/`, data);
  }

  deleteService(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/services/${id}/`);
  }

  // Service activity
  getServiceActivities({ page = 1, searchString = "", service = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/service_activities?page=${page}&searchString=${searchString}&service=${service}`
    );
  }

  getServiceActivity(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/service_activities/${id}/`);
  }

  createServiceActivity(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/service_activities/`, data);
  }

  updateServiceActivity(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/service_activities/${id}/`, data);
  }

  deleteServiceActivity(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/service_activities/${id}/`);
  }

  // Service deliverable
  getServiceDeliverables({ page = 1, searchString = "", service = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/service_deliverables?page=${page}&searchString=${searchString}&service=${service}`
    );
  }

  getServiceDeliverable(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/service_deliverables/${id}/`);
  }

  createServiceDeliverable(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/service_deliverables/`, data);
  }

  updateServiceDeliverable(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/service_deliverables/${id}/`,
      data
    );
  }

  deleteServiceDeliverable(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/service_deliverables/${id}/`);
  }

  // Service need
  getServiceNeeds({ page = 1, searchString = "", service = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/service_needs?page=${page}&searchString=${searchString}&service=${service}`
    );
  }

  getServiceNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/service_needs/${id}/`);
  }

  createServiceNeed(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/service_needs/`, data);
  }

  updateServiceNeed(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/service_needs/${id}/`, data);
  }

  deleteServiceNeed(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/service_needs/${id}/`);
  }

  // Service permission
  getServicePermissions({ page = 1, searchString = "", service = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/service_permissions?page=${page}&searchString=${searchString}&service=${service}`
    );
  }

  getServicePermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/service_permissions/${id}/`);
  }

  createServicePermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/service_permissions/`, data);
  }

  updateServicePermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/service_permissions/${id}/`,
      data
    );
  }

  deleteServicePermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/service_permissions/${id}/`);
  }

  // Staff
  getStaffList(
    {
      bookingProfile = "",
      competencies = "",
      employment = "",
      freeTime = "",
      location = "",
      manager = "",
      organisation = "",
      page = 1,
      role = "",
      searchString = "",
      staff = "",
      utilisationScore = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff?bookingProfile=${bookingProfile}&competencies=${competencies}&employment=${employment}&freeTime=${freeTime}&location=${location}&manager=${manager}&organisation=${organisation}&page=${page}&role=${role}&searchString=${searchString}&staff=${staff}&utilisationScore=${utilisationScore}`
    );
  }

  getStaff(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff/${id}/`);
  }

  updateStaff(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/staff/${id}/`, data);
  }

  // Staff AI
  calculateRoleFitScoreStaff(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_ai/${id}/calculate_role_fit_score`
    );
  }

  generateUtilisationAnalysisStaff(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_ai/${id}/generate_utilisation_analysis`
    );
  }

  // Staff competency
  getStaffCompetencies({ page = 1, searchString = "", staff = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_competencies?page=${page}&searchString=${searchString}&staff=${staff}`
    );
  }

  getStaffCompetency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_competencies/${id}/`);
  }

  createStaffCompetency(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_competencies/`, data);
  }

  updateStaffCompetency(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/staff_competencies/${id}/`, data);
  }

  deleteStaffCompetency(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/staff_competencies/${id}/`);
  }

  // Staff development plan
  getStaffDevelopmentPlans(
    {
      dueEnd = "",
      dueStart = "",
      organisation = "",
      page = 1,
      searchString = "",
      staff = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_development_plans?dueEnd=${dueEnd}&dueStart=${dueStart}&organisation=${organisation}&page=${page}&searchString=${searchString}&staff=${staff}&status=${status}`
    );
  }

  getStaffDevelopmentPlan(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_development_plans/${id}/`);
  }

  createStaffDevelopmentPlan(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_development_plans/`, data);
  }

  updateStaffDevelopmentPlan(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/staff_development_plans/${id}/`,
      data
    );
  }

  deleteStaffDevelopmentPlan(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/staff_development_plans/${id}/`);
  }

  // Staff exit action
  getStaffExitActions(
    {
      dueEnd = "",
      dueStart = "",
      owner = "",
      page = 1,
      searchString = "",
      staff = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_exit_actions?dueEnd=${dueEnd}&dueStart=${dueStart}&owner=${owner}&page=${page}&searchString=${searchString}&staff=${staff}&status=${status}`
    );
  }

  getStaffExitAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_exit_actions/${id}/`);
  }

  createStaffExitAction(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_exit_actions/`, data);
  }

  updateStaffExitAction(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/staff_exit_actions/${id}/`, data);
  }

  deleteStaffExitAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/staff_exit_actions/${id}/`);
  }

  // Staff exit upload
  getStaffExitUploads({ category = "", page = 1, staff = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_exit_uploads?category=${category}&page=${page}&staff=${staff}`
    );
  }

  getStaffExitUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_exit_uploads/${id}/`);
  }

  createStaffExitUpload(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_exit_uploads/`, data);
  }

  updateStaffExitUpload(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/staff_exit_uploads/${id}/`, data);
  }

  deleteStaffExitUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/staff_exit_uploads/${id}/`);
  }

  // Staff goal
  getStaffGoals(
    {
      dueEnd = "",
      dueStart = "",
      organisation = "",
      page = 1,
      rating = "",
      searchString = "",
      sortField = "",
      staff = "",
      year = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_goals?dueEnd=${dueEnd}&dueStart=${dueStart}&organisation=${organisation}&page=${page}&rating=${rating}&searchString=${searchString}&sortField=${sortField}&staff=${staff}&year=${year}`
    );
  }

  getStaffGoal(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_goals/${id}/`);
  }

  createStaffGoal(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_goals/`, data);
  }

  updateStaffGoal(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/staff_goals/${id}/`, data);
  }

  deleteStaffGoal(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/staff_goals/${id}/`);
  }

  // Staff onboarding action
  getStaffOnboardingActions(
    {
      dueEnd = "",
      dueStart = "",
      owner = "",
      page = 1,
      searchString = "",
      staff = "",
      status = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_onboarding_actions?dueEnd=${dueEnd}&dueStart=${dueStart}&owner=${owner}&page=${page}&searchString=${searchString}&staff=${staff}&status=${status}`
    );
  }

  getStaffOnboardingAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_onboarding_actions/${id}/`);
  }

  createStaffOnboardingAction(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_onboarding_actions/`, data);
  }

  updateStaffOnboardingAction(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/staff_onboarding_actions/${id}/`,
      data
    );
  }

  deleteStaffOnboardingAction(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/staff_onboarding_actions/${id}/`
    );
  }

  // Staff onboarding upload
  getStaffOnboardingUploads({ category = "", page = 1, staff = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_onboarding_uploads?category=${category}&page=${page}&staff=${staff}`
    );
  }

  getStaffOnboardingUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_onboarding_uploads/${id}/`);
  }

  createStaffOnboardingUpload(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_onboarding_uploads/`, data);
  }

  updateStaffOnboardingUpload(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(
      `${BACKEND_URL}/api/v1/staff_onboarding_uploads/${id}/`,
      data
    );
  }

  deleteStaffOnboardingUpload(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(
      `${BACKEND_URL}/api/v1/staff_onboarding_uploads/${id}/`
    );
  }

  // Staff permission
  getStaffPermissions({ page = 1, searchString = "", staff = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/staff_permissions?page=${page}&searchString=${searchString}&staff=${staff}`
    );
  }

  getStaffPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/staff_permissions/${id}/`);
  }

  createStaffPermission(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/staff_permissions/`, data);
  }

  updateStaffPermission(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/staff_permissions/${id}/`, data);
  }

  deleteStaffPermission(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/staff_permissions/${id}/`);
  }

  // Text AI
  extractDataText(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/text_ai/${id}/extract_data/`,
      data
    );
  }

  extractDataFromFileText(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/text_ai/${id}/extract_data_from_file/`,
      data
    );
  }

  rewriteFieldText(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(
      `${BACKEND_URL}/api/v1/text_ai/${id}/rewrite_field/`,
      data
    );
  }

  // Timesheet
  getTimesheets(
    {
      completedEnd = "",
      completedStart = "",
      organisation = "",
      page = 1,
      range = "",
      staff = "",
      status = "",
      submittedEnd = "",
      submittedStart = "",
      user = "",
      weekLabel = "",
    },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/timesheets?completedEnd=${completedEnd}&completedStart=${completedStart}&organisation=${organisation}&page=${page}&range=${range}&staff=${staff}&status=${status}&submittedEnd=${submittedEnd}&submittedStart=${submittedStart}&user=${user}&weekLabel=${weekLabel}`
    );
  }

  getTimesheet(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/timesheets/${id}/`);
  }

  createTimesheet(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/timesheets/`, data);
  }

  updateTimesheet(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/timesheets/${id}/`, data);
  }

  deleteTimesheet(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/timesheets/${id}/`);
  }

  duplicateTimesheet(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/timesheets/${id}/duplicate`);
  }

  reverseTimesheet(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/timesheets/${id}/reverse/`, data);
  }

  // Timesheet entry
  getTimesheetEntries({ page = 1, timesheet = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/timesheet_entries?page=${page}&timesheet=${timesheet}`
    );
  }

  getTimesheetEntry(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/timesheet_entries/${id}/`);
  }

  createTimesheetEntry(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/timesheet_entries/`, data);
  }

  updateTimesheetEntry(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/timesheet_entries/${id}/`, data);
  }

  deleteTimesheetEntry(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/timesheet_entries/${id}/`);
  }

  // To-do
  getTodos(
    { category = "", daysAhead = "", done = "", organisation = "", page = 1 },
    token
  ) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/todos?category=${category}&daysAhead=${daysAhead}&done=${done}&organisation=${organisation}&page=${page}`
    );
  }

  updateTodo(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/todos/${id}/`, data);
  }

  // User
  changePassword(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.post(
      `${BACKEND_URL}/api/v1/dj-rest-auth/password/change/`,
      data
    );
  }

  confirmEmail(data) {
    axios.defaults.headers.common["Authorization"] = null;

    return axios.post(
      `${BACKEND_URL}/api/v1/dj-rest-auth/registration/account-confirm-email/`,
      data
    );
  }

  confirmEmailResend(data) {
    axios.defaults.headers.common["Authorization"] = null;

    return axios.post(
      `${BACKEND_URL}/api/v1/dj-rest-auth/registration/resend-email/`,
      data
    );
  }

  login(data) {
    axios.defaults.headers.common["Authorization"] = null;

    return axios.post(`${BACKEND_URL}/api/v1/dj-rest-auth/login/`, data);
  }

  getAccount(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/dj-rest-auth/user/`);
  }

  resetPassword(data) {
    axios.defaults.headers.common["Authorization"] = null;

    return axios.post(
      `${BACKEND_URL}/api/v1/dj-rest-auth/password/reset/`,
      data
    );
  }

  resetPasswordConfirm(data) {
    axios.defaults.headers.common["Authorization"] = null;

    return axios.post(
      `${BACKEND_URL}/api/v1/dj-rest-auth/password/reset/confirm/${data.uid}/${data.token}`,
      data
    );
  }

  signup(data) {
    axios.defaults.headers.common["Authorization"] = null;

    return axios.post(`${BACKEND_URL}/api/v1/dj-rest-auth/registration/`, data);
  }

  updateAccount(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.patch(`${BACKEND_URL}/api/v1/dj-rest-auth/user/`, data);
  }

  // User profile
  getUserProfile(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/user_profiles/`);
  }

  updateUserProfile(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/user_profiles/${id}/`, data);
  }

  // Week
  getWeeks(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/weeks/`);
  }

  // Workflow
  getWorkflows({ organisation = "", page = 1, status = "" }, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(
      `${BACKEND_URL}/api/v1/workflows?organisation=${organisation}&page=${page}&status=${status}`
    );
  }

  getWorkflow(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(`${BACKEND_URL}/api/v1/workflows/${id}/`);
  }

  createWorkflow(token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.post(`${BACKEND_URL}/api/v1/workflows/`, data);
  }

  updateWorkflow(id, token, data) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    return axios.patch(`${BACKEND_URL}/api/v1/workflows/${id}/`, data);
  }

  deleteWorkflow(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.delete(`${BACKEND_URL}/api/v1/workflows/${id}/`);
  }
}

const cortixDataService = new CortixDataService();
export default cortixDataService;
