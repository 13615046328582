// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Classes,
  Colors,
  Dialog,
  Divider,
  FormGroup,
  H5,
  HTMLTable,
  Tab,
  Tabs,
  Tag,
  Text,
  UL,
} from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";

// Component imports
import { AppToaster } from "../components/AppToaster";
import BookingChart2 from "../components/BookingChart2";
import BookingCodeInfo from "../components/BookingCodeInfo";
import BookingInfo from "../components/BookingInfo";
import CompetencyInfo from "../components/CompetencyInfo";
import EditableTableContainer from "../components/EditableTableContainer";
import CompetencyChart from "../components/CompetencyChart";
import DataCardContainer from "../components/DataCardContainer";
import DataCardSimple from "../components/DataCardSimple";
import DateValue from "../components/DateValue";
import DocumentCard from "../components/DocumentCard";
import ErrorAlert from "../components/ErrorAlert";
import ErrorList from "../components/ErrorList";
import FormDrawerBookingDaysField2 from "../components/FormDrawerBookingDaysField2";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerDurationField from "../components/FormDrawerDurationField";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSliderField from "../components/FormDrawerSliderField";
import FormDrawerSwitchField from "../components/FormDrawerSwitchField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import FormDrawerTimeField from "../components/FormDrawerTimeField";
import GenerateFromFileButton from "../components/GenerateFromFileButton";
import HelpDialog from "../components/HelpDialog";
import InfoPopup from "../components/InfoPopup";
import ItemCardList from "../components/ItemCardList";
import Kanban from "../components/Kanban";
import LeaveCategoryInfo from "../components/LeaveCategoryInfo";
import LocationInfo from "../components/LocationInfo";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import PercentageValue from "../components/PercentageValue";
import PermissionCard from "../components/PermissionCard";
import RoleInfo from "../components/RoleInfo";
import SearchField from "../components/SearchField";
import SelectField from "../components/SelectField";
import StaffAvailabilityCard from "../components/StaffAvailabilityCard";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionChartContainer from "../components/TabSectionChartContainer";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";
import Timeline4 from "../components/Timeline4";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";
import generateKanbanDataFromStaffExitActions from "../utilities/generateKanbanDataFromStaffExitActions";
import generateKanbanDataFromStaffOnboardingActions from "../utilities/generateKanbanDataFromStaffOnboardingActions";
import generateTimelineDataFromBookings from "../utilities/generateTimelineDataFromBookings";
import generateYearList from "../utilities/generateYearList";
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Booking
import {
  createBookingAction,
  deleteBookingAction,
  listBookingsAction,
  listBookingDetailsAction,
  resetCreateBookingAction,
  resetDeleteBookingAction,
  resetUpdateBookingAction,
  updateBookingAction,
} from "../actions/bookingActions";

// Booking code
import {
  listBookingCodeDetailsAction,
  listBookingCodesAction,
  resetListBookingCodeDetailsAction,
} from "../actions/bookingCodeActions";

// Claim
import {
  createClaimAction,
  deleteClaimAction,
  duplicateClaimAction,
  listClaimsAction,
  listClaimDetailsAction,
  resetCreateClaimAction,
  resetDeleteClaimAction,
  resetDuplicateClaimAction,
  resetReverseClaimAction,
  resetUpdateClaimAction,
  reverseClaimAction,
  updateClaimAction,
} from "../actions/claimActions";

// Competency
import { listCompetenciesAction } from "../actions/competencyActions";

// Expense
import {
  createExpenseAction,
  deleteExpenseAction,
  listExpensesAction,
  listExpenseDetailsAction,
  resetCreateExpenseAction,
  resetDeleteExpenseAction,
  resetUpdateExpenseAction,
  updateExpenseAction,
} from "../actions/expenseActions";

// Expense category
import { listExpenseCategoriesAction } from "../actions/expenseCategoryActions";

// Leave category
import { listLeaveCategoriesAction } from "../actions/leaveCategoryActions";

// Leave request
import {
  cancelLeaveRequestAction,
  createLeaveRequestAction,
  deleteLeaveRequestAction,
  listLeaveRequestsAction,
  listLeaveRequestDetailsAction,
  resetCreateLeaveRequestAction,
  resetDeleteLeaveRequestAction,
  resetCancelLeaveRequestAction,
  resetUpdateLeaveRequestAction,
  updateLeaveRequestAction,
} from "../actions/leaveRequestActions";

// Location
import { listLocationsAction } from "../actions/locationActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Role
import { listRolesAction } from "../actions/roleActions";

// Staff
import {
  calculateRoleFitScoreAction,
  generateUtilisationAnalysisAction,
  listStaffDetailsAction,
  resetCalculateRoleFitScoreAction,
  resetGenerateUtilisationAnalysisAction,
  resetListStaffAction,
  resetUpdateStaffAction,
  updateStaffAction,
} from "../actions/staffActions";

// Staff competency
import {
  createStaffCompetencyAction,
  deleteStaffCompetencyAction,
  listStaffCompetenciesAction,
  listStaffCompetencyDetailsAction,
  resetCreateStaffCompetencyAction,
  resetDeleteStaffCompetencyAction,
  resetUpdateStaffCompetencyAction,
  resetUploadStaffCompetenciesAction,
  updateStaffCompetencyAction,
  uploadStaffCompetenciesAction,
} from "../actions/staffCompetencyActions";

// Staff development plan
import {
  createStaffDevelopmentPlanAction,
  deleteStaffDevelopmentPlanAction,
  listStaffDevelopmentPlansAction,
  listStaffDevelopmentPlanDetailsAction,
  resetCreateStaffDevelopmentPlanAction,
  resetDeleteStaffDevelopmentPlanAction,
  resetUpdateStaffDevelopmentPlanAction,
  updateStaffDevelopmentPlanAction,
} from "../actions/staffDevelopmentPlanActions";

// Staff exit action
import {
  createStaffExitActionAction,
  deleteStaffExitActionAction,
  listStaffExitActionsAction,
  listStaffExitActionDetailsAction,
  resetCreateStaffExitActionAction,
  resetDeleteStaffExitActionAction,
  resetUpdateStaffExitActionAction,
  updateStaffExitActionAction,
} from "../actions/staffExitActionActions";

// Staff exit upload
import {
  createStaffExitUploadAction,
  deleteStaffExitUploadAction,
  listStaffExitUploadsAction,
  listStaffExitUploadDetailsAction,
  resetCreateStaffExitUploadAction,
  resetDeleteStaffExitUploadAction,
  resetUpdateStaffExitUploadAction,
  updateStaffExitUploadAction,
} from "../actions/staffExitUploadActions";

// Staff goal
import {
  createStaffGoalAction,
  deleteStaffGoalAction,
  listStaffGoalsAction,
  listStaffGoalDetailsAction,
  resetCreateStaffGoalAction,
  resetDeleteStaffGoalAction,
  resetUpdateStaffGoalAction,
  updateStaffGoalAction,
} from "../actions/staffGoalActions";

// Staff onboarding action
import {
  createStaffOnboardingActionAction,
  deleteStaffOnboardingActionAction,
  listStaffOnboardingActionsAction,
  listStaffOnboardingActionDetailsAction,
  resetCreateStaffOnboardingActionAction,
  resetDeleteStaffOnboardingActionAction,
  resetUpdateStaffOnboardingActionAction,
  updateStaffOnboardingActionAction,
} from "../actions/staffOnboardingActionActions";

// Staff onboarding upload
import {
  createStaffOnboardingUploadAction,
  deleteStaffOnboardingUploadAction,
  listStaffOnboardingUploadsAction,
  listStaffOnboardingUploadDetailsAction,
  resetCreateStaffOnboardingUploadAction,
  resetDeleteStaffOnboardingUploadAction,
  resetUpdateStaffOnboardingUploadAction,
  updateStaffOnboardingUploadAction,
} from "../actions/staffOnboardingUploadActions";

// Staff permission
import {
  createStaffPermissionAction,
  deleteStaffPermissionAction,
  listStaffPermissionsAction,
  listStaffPermissionDetailsAction,
  resetCreateStaffPermissionAction,
  resetDeleteStaffPermissionAction,
  resetUpdateStaffPermissionAction,
  updateStaffPermissionAction,
} from "../actions/staffPermissionActions";

// Timesheet
import {
  createTimesheetAction,
  deleteTimesheetAction,
  duplicateTimesheetAction,
  listTimesheetsAction,
  listTimesheetDetailsAction,
  resetCreateTimesheetAction,
  resetDeleteTimesheetAction,
  resetDuplicateTimesheetAction,
  resetReverseTimesheetAction,
  resetUpdateTimesheetAction,
  reverseTimesheetAction,
  updateTimesheetAction,
} from "../actions/timesheetActions";

// Timesheet entry
import {
  createTimesheetEntryAction,
  deleteTimesheetEntryAction,
  listTimesheetEntriesAction,
  listTimesheetEntryDetailsAction,
  resetCreateTimesheetEntryAction,
  resetDeleteTimesheetEntryAction,
  resetUpdateTimesheetEntryAction,
  updateTimesheetEntryAction,
} from "../actions/timesheetEntryActions";

// Week
import { listWeeksAction } from "../actions/weekActions";

// Main function
function StaffDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Staff id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Booking
  const bookingCreate = useSelector((state) => state.bookingCreate);

  const bookingDelete = useSelector((state) => state.bookingDelete);

  const bookingDetails = useSelector((state) => state.bookingDetails);

  const bookingList = useSelector((state) => state.bookingList);

  const bookingUpdate = useSelector((state) => state.bookingUpdate);

  // Booking code
  const bookingCodeDetails = useSelector((state) => state.bookingCodeDetails);

  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  // Claim
  const claimCreate = useSelector((state) => state.claimCreate);

  const claimDelete = useSelector((state) => state.claimDelete);

  const claimDetails = useSelector((state) => state.claimDetails);

  const claimDuplicate = useSelector((state) => state.claimDuplicate);

  const claimList = useSelector((state) => state.claimList);

  const claimReverse = useSelector((state) => state.claimReverse);

  const claimUpdate = useSelector((state) => state.claimUpdate);

  // Competency
  const competencyList = useSelector((state) => state.competencyList);

  // Expense
  const expenseCreate = useSelector((state) => state.expenseCreate);

  const expenseDelete = useSelector((state) => state.expenseDelete);

  const expenseDetails = useSelector((state) => state.expenseDetails);

  const expenseList = useSelector((state) => state.expenseList);

  const expenseUpdate = useSelector((state) => state.expenseUpdate);

  // Expense category
  const expenseCategoryList = useSelector((state) => state.expenseCategoryList);

  // Leave category
  const leaveCategoryList = useSelector((state) => state.leaveCategoryList);

  // Leave request
  const leaveRequestCancel = useSelector((state) => state.leaveRequestCancel);

  const leaveRequestCreate = useSelector((state) => state.leaveRequestCreate);

  const leaveRequestDelete = useSelector((state) => state.leaveRequestDelete);

  const leaveRequestDetails = useSelector((state) => state.leaveRequestDetails);

  const leaveRequestList = useSelector((state) => state.leaveRequestList);

  const leaveRequestUpdate = useSelector((state) => state.leaveRequestUpdate);

  // Location
  const locationList = useSelector((state) => state.locationList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Role
  const roleList = useSelector((state) => state.roleList);

  // Staff
  const staffCalculateRoleFitScore = useSelector(
    (state) => state.staffCalculateRoleFitScore
  );

  const staffDetails = useSelector((state) => state.staffDetails);

  const staffGenerateUtilisationAnalysis = useSelector(
    (state) => state.staffGenerateUtilisationAnalysis
  );

  const staffUpdate = useSelector((state) => state.staffUpdate);

  // Staff competency
  const staffCompetencyCreate = useSelector(
    (state) => state.staffCompetencyCreate
  );

  const staffCompetencyDelete = useSelector(
    (state) => state.staffCompetencyDelete
  );

  const staffCompetencyDetails = useSelector(
    (state) => state.staffCompetencyDetails
  );

  const staffCompetencyList = useSelector((state) => state.staffCompetencyList);

  const staffCompetencyUpdate = useSelector(
    (state) => state.staffCompetencyUpdate
  );

  const staffCompetencyUpload = useSelector(
    (state) => state.staffCompetencyUpload
  );

  // Staff development plan
  const staffDevelopmentPlanCreate = useSelector(
    (state) => state.staffDevelopmentPlanCreate
  );

  const staffDevelopmentPlanDelete = useSelector(
    (state) => state.staffDevelopmentPlanDelete
  );

  const staffDevelopmentPlanDetails = useSelector(
    (state) => state.staffDevelopmentPlanDetails
  );

  const staffDevelopmentPlanList = useSelector(
    (state) => state.staffDevelopmentPlanList
  );

  const staffDevelopmentPlanUpdate = useSelector(
    (state) => state.staffDevelopmentPlanUpdate
  );

  // Staff exit action
  const staffExitActionCreate = useSelector(
    (state) => state.staffExitActionCreate
  );

  const staffExitActionDelete = useSelector(
    (state) => state.staffExitActionDelete
  );

  const staffExitActionDetails = useSelector(
    (state) => state.staffExitActionDetails
  );

  const staffExitActionList = useSelector((state) => state.staffExitActionList);

  const staffExitActionUpdate = useSelector(
    (state) => state.staffExitActionUpdate
  );

  // Staff exit upload
  const staffExitUploadCreate = useSelector(
    (state) => state.staffExitUploadCreate
  );

  const staffExitUploadDelete = useSelector(
    (state) => state.staffExitUploadDelete
  );

  const staffExitUploadDetails = useSelector(
    (state) => state.staffExitUploadDetails
  );

  const staffExitUploadList = useSelector((state) => state.staffExitUploadList);

  const staffExitUploadUpdate = useSelector(
    (state) => state.staffExitUploadUpdate
  );

  // Staff goal
  const staffGoalCreate = useSelector((state) => state.staffGoalCreate);

  const staffGoalDelete = useSelector((state) => state.staffGoalDelete);

  const staffGoalDetails = useSelector((state) => state.staffGoalDetails);

  const staffGoalList = useSelector((state) => state.staffGoalList);

  const staffGoalUpdate = useSelector((state) => state.staffGoalUpdate);

  // Staff onboarding action
  const staffOnboardingActionCreate = useSelector(
    (state) => state.staffOnboardingActionCreate
  );

  const staffOnboardingActionDelete = useSelector(
    (state) => state.staffOnboardingActionDelete
  );

  const staffOnboardingActionDetails = useSelector(
    (state) => state.staffOnboardingActionDetails
  );

  const staffOnboardingActionList = useSelector(
    (state) => state.staffOnboardingActionList
  );

  const staffOnboardingActionUpdate = useSelector(
    (state) => state.staffOnboardingActionUpdate
  );

  // Staff onboarding upload
  const staffOnboardingUploadCreate = useSelector(
    (state) => state.staffOnboardingUploadCreate
  );

  const staffOnboardingUploadDelete = useSelector(
    (state) => state.staffOnboardingUploadDelete
  );

  const staffOnboardingUploadDetails = useSelector(
    (state) => state.staffOnboardingUploadDetails
  );

  const staffOnboardingUploadList = useSelector(
    (state) => state.staffOnboardingUploadList
  );

  const staffOnboardingUploadUpdate = useSelector(
    (state) => state.staffOnboardingUploadUpdate
  );

  // Staff permission
  const staffPermissionCreate = useSelector(
    (state) => state.staffPermissionCreate
  );

  const staffPermissionDelete = useSelector(
    (state) => state.staffPermissionDelete
  );

  const staffPermissionDetails = useSelector(
    (state) => state.staffPermissionDetails
  );

  const staffPermissionList = useSelector((state) => state.staffPermissionList);

  const staffPermissionUpdate = useSelector(
    (state) => state.staffPermissionUpdate
  );

  // Timesheet
  const timesheetCreate = useSelector((state) => state.timesheetCreate);

  const timesheetDelete = useSelector((state) => state.timesheetDelete);

  const timesheetDetails = useSelector((state) => state.timesheetDetails);

  const timesheetDuplicate = useSelector((state) => state.timesheetDuplicate);

  const timesheetList = useSelector((state) => state.timesheetList);

  const timesheetReverse = useSelector((state) => state.timesheetReverse);

  const timesheetUpdate = useSelector((state) => state.timesheetUpdate);

  // Timesheet entry
  const timesheetEntryCreate = useSelector(
    (state) => state.timesheetEntryCreate
  );

  const timesheetEntryDelete = useSelector(
    (state) => state.timesheetEntryDelete
  );

  const timesheetEntryDetails = useSelector(
    (state) => state.timesheetEntryDetails
  );

  const timesheetEntryList = useSelector((state) => state.timesheetEntryList);

  const timesheetEntryUpdate = useSelector(
    (state) => state.timesheetEntryUpdate
  );

  // Week
  const weekList = useSelector((state) => state.weekList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("staff-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Bookings
  const [bookingsDialogIsOpen, setBookingsDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Work hours
  const [updateWorkHoursDrawerIsOpen, setUpdateWorkHoursDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Onboarding actions
  const [addOnboardingActionDrawerIsOpen, setAddOnboardingActionDrawerIsOpen] =
    useState(false);
  const [
    updateOnboardingActionDrawerIsOpen,
    setUpdateOnboardingActionDrawerIsOpen,
  ] = useState(false);

  // Onboarding files
  const [addOnboardingUploadDrawerIsOpen, setAddOnboardingUploadDrawerIsOpen] =
    useState(false);
  const [
    updateOnboardingUploadDrawerIsOpen,
    setUpdateOnboardingUploadDrawerIsOpen,
  ] = useState(false);

  // Goals
  const [addGoalDrawerIsOpen, setAddGoalDrawerIsOpen] = useState(false);
  const [updateGoalDrawerIsOpen, setUpdateGoalDrawerIsOpen] = useState(false);

  // Competencies
  const [addCompetencyDrawerIsOpen, setAddCompetencyDrawerIsOpen] =
    useState(false);
  const [updateCompetencyDrawerIsOpen, setUpdateCompetencyDrawerIsOpen] =
    useState(false);

  // Development plans
  const [addDevelopmentPlanDrawerIsOpen, setAddDevelopmentPlanDrawerIsOpen] =
    useState(false);
  const [
    updateDevelopmentPlanDrawerIsOpen,
    setUpdateDevelopmentPlanDrawerIsOpen,
  ] = useState(false);

  // Bookings
  const [addBookingDrawerIsOpen, setAddBookingDrawerIsOpen] = useState(false);
  const [updateBookingDrawerIsOpen, setUpdateBookingDrawerIsOpen] =
    useState(false);

  // Timesheets
  const [addTimesheetDrawerIsOpen, setAddTimesheetDrawerIsOpen] =
    useState(false);
  const [updateTimesheetDrawerIsOpen, setUpdateTimesheetDrawerIsOpen] =
    useState(false);

  // Expense claims
  const [addClaimDrawerIsOpen, setAddClaimDrawerIsOpen] = useState(false);
  const [updateClaimDrawerIsOpen, setUpdateClaimDrawerIsOpen] = useState(false);

  // Leave requests
  const [addLeaveRequestDrawerIsOpen, setAddLeaveRequestDrawerIsOpen] =
    useState(false);
  const [updateLeaveRequestDrawerIsOpen, setUpdateLeaveRequestDrawerIsOpen] =
    useState(false);

  // Exit actions
  const [addExitActionDrawerIsOpen, setAddExitActionDrawerIsOpen] =
    useState(false);
  const [updateExitActionDrawerIsOpen, setUpdateExitActionDrawerIsOpen] =
    useState(false);

  // Exit files
  const [addExitUploadDrawerIsOpen, setAddExitUploadDrawerIsOpen] =
    useState(false);
  const [updateExitUploadDrawerIsOpen, setUpdateExitUploadDrawerIsOpen] =
    useState(false);

  // Other
  const [initialiseExitActionKanban, setInitialiseExitActionKanban] =
    useState(true);
  const [showExitActionKanban, setShowExitActionKanban] = useState(false);

  const [
    initialiseOnboardingActionKanban,
    setInitialiseOnboardingActionKanban,
  ] = useState(true);
  const [showOnboardingActionKanban, setShowOnboardingActionKanban] =
    useState(false);

  // Search and filter variables
  // Booking
  const bookingQueryDefault = {
    billable: "",
    bookingCode: "",
    bookingProfile: "week",
    endEnd: "",
    endStart: "",
    engagement: "",
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    staff: id,
    startEnd: "",
    startStart: "",
    type: "",
    user: "",
  };
  const [bookingQuery, setBookingQuery] = useState({
    ...bookingQueryDefault,
  });

  // Booking code
  const bookingCodeQueryDefault = {
    billable: "",
    engagement: "",
    holding: "",
    open: true,
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    year: "",
  };
  const [bookingCodeQuery, setBookingCodeQuery] = useState({
    ...bookingCodeQueryDefault,
  });

  // Claim
  const claimQueryDefault = {
    completedEnd: "",
    completedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    staff: id,
    status: "",
    submittedEnd: "",
    submittedStart: "",
    user: "",
  };
  const [claimQuery, setClaimQuery] = useState({
    ...claimQueryDefault,
  });

  // Competency
  const competencyQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [competencyQuery, setCompetencyQuery] = useState({
    ...competencyQueryDefault,
  });

  // Dates
  const a_year_ago = new Date();
  a_year_ago.setFullYear(a_year_ago.getFullYear() - 1);
  const three_years_from_now = new Date();
  three_years_from_now.setFullYear(three_years_from_now.getFullYear() + 3);

  // Expense
  const expenseQueryDefault = {
    billable: "",
    bookingCode: "",
    category: "",
    claim: "",
    dateEnd: "",
    dateStart: "",
    engagement: "",
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    reversed: "",
    sortField: "",
    staff: "",
  };
  const [expenseQuery, setExpenseQuery] = useState({
    ...expenseQueryDefault,
  });

  // Expense category
  const expenseCategoryQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [expenseCategoryQuery, setExpenseCategoryQuery] = useState({
    ...expenseCategoryQueryDefault,
  });

  // Leave category
  const leaveCategoryQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [leaveCategoryQuery, setLeaveCategoryQuery] = useState({
    ...leaveCategoryQueryDefault,
  });

  // Leave request
  const leaveRequestQueryDefault = {
    category: "",
    completedEnd: "",
    completedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    sortField: "",
    staff: id,
    status: "",
    submittedEnd: "",
    submittedStart: "",
    user: "",
    weekLabel: "",
  };
  const [leaveRequestQuery, setLeaveRequestQuery] = useState({
    ...leaveRequestQueryDefault,
  });

  // Location
  const locationQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [locationQuery, setLocationQuery] = useState({
    ...locationQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Role
  const roleQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [roleQuery, setRoleQuery] = useState({
    ...roleQueryDefault,
  });

  // Staff competency
  const staffCompetencyQueryDefault = {
    page: 1,
    searchString: "",
    staff: id,
  };
  const [staffCompetencyQuery, setStaffCompetencyQuery] = useState({
    ...staffCompetencyQueryDefault,
  });

  // Staff development plan
  const staffDevelopmentPlanQueryDefault = {
    dueEnd: "",
    dueStart: "",
    organisation: "",
    page: 1,
    searchString: "",
    staff: id,
    status: "",
  };
  const [staffDevelopmentPlanQuery, setStaffDevelopmentPlanQuery] = useState({
    ...staffDevelopmentPlanQueryDefault,
  });

  // Staff exit action
  const staffExitActionQueryDefault = {
    dueEnd: "",
    dueStart: "",
    owner: "",
    page: 1,
    searchString: "",
    staff: id,
    status: "",
  };
  const [staffExitActionQuery, setStaffExitActionQuery] = useState({
    ...staffExitActionQueryDefault,
  });

  // Staff exit upload
  const staffExitUploadQueryDefault = {
    category: "",
    page: 1,
    staff: id,
  };
  const [staffExitUploadQuery, setStaffExitUploadQuery] = useState({
    ...staffExitUploadQueryDefault,
  });

  // Staff goal
  const staffGoalQueryDefault = {
    dueEnd: "",
    dueStart: "",
    organisation: "",
    page: 1,
    rating: "",
    searchString: "",
    sortField: "",
    staff: id,
    year: "",
  };
  const [staffGoalQuery, setStaffGoalQuery] = useState({
    ...staffGoalQueryDefault,
  });

  // Staff onboarding action
  const staffOnboardingActionQueryDefault = {
    dueEnd: "",
    dueStart: "",
    owner: "",
    page: 1,
    searchString: "",
    staff: id,
    status: "",
  };
  const [staffOnboardingActionQuery, setStaffOnboardingActionQuery] = useState({
    ...staffOnboardingActionQueryDefault,
  });

  // Staff onboarding upload
  const staffOnboardingUploadQueryDefault = {
    category: "",
    page: 1,
    staff: id,
  };
  const [staffOnboardingUploadQuery, setStaffOnboardingUploadQuery] = useState({
    ...staffOnboardingUploadQueryDefault,
  });

  // Staff permission
  const staffPermissionQueryDefault = {
    page: 1,
    searchString: "",
    staff: id,
  };
  const [staffPermissionQuery, setStaffPermissionQuery] = useState({
    ...staffPermissionQueryDefault,
  });

  // Timesheet
  const timesheetQueryDefault = {
    completedEnd: "",
    completedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    range: "",
    staff: id,
    status: "",
    submittedEnd: "",
    submittedStart: "",
    user: "",
    weekLabel: "",
  };
  const [timesheetQuery, setTimesheetQuery] = useState({
    ...timesheetQueryDefault,
  });

  // Timesheet entry
  const timesheetEntryQueryDefault = {
    page: 1,
    timesheet: "",
  };
  const [timesheetEntryQuery, setTimesheetEntryQuery] = useState({
    ...timesheetEntryQueryDefault,
  });

  // Form variables
  // Booking
  const bookingDefault = {
    bookingCode: "",
    bookingCodeName: "",
    comments: "",
    detail: [],
    end: "",
    method: "Distribute",
    minutes: 60,
    start: "",
    type: "Tentative",
    weekdays: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: false,
      sun: false,
    },
  };
  const [booking, setBooking] = useState({
    ...bookingDefault,
  });

  // Claim
  const claimDefault = {
    comments: "",
    name: "",
    receipts: "",
    receiptsName: "",
    staff: "",
    value: "",
  };
  const [claim, setClaim] = useState({
    ...claimDefault,
  });

  const claimApprovalRequestDefault = {
    approver: "",
    approverName: "",
    submit: false,
  };
  const [claimApprovalRequest, setClaimApprovalRequest] = useState({
    ...claimApprovalRequestDefault,
  });

  const claimReversalDefault = {
    comments: "",
  };
  const [claimReversal, setClaimReversal] = useState({
    ...claimReversalDefault,
  });

  // Expense
  const expenseDefault = {
    bookingCode: "",
    bookingCodeName: "",
    category: "",
    categoryName: "",
    claim: "",
    comments: "",
    date: "",
    value: "",
  };
  const [expense, setExpense] = useState({
    ...expenseDefault,
  });

  // Leave request
  const leaveRequestDefault = {
    category: "",
    categoryName: "",
    comments: "",
    minutesFri: 0,
    minutesMon: 0,
    minutesSat: 0,
    minutesSun: 0,
    minutesTue: 0,
    minutesThu: 0,
    minutesWed: 0,
    weekLabel: "",
  };
  const [leaveRequest, setLeaveRequest] = useState({
    ...leaveRequestDefault,
  });

  const leaveRequestApprovalRequestDefault = {
    approver: "",
    approverName: "",
    submit: false,
  };
  const [leaveRequestApprovalRequest, setLeaveRequestApprovalRequest] =
    useState({
      ...leaveRequestApprovalRequestDefault,
    });

  // Staff
  const staffDefault = {
    customAvailability: false,
    employment: "Permanent",
    location: "",
    locationName: "",
    manager: "",
    managerName: "",
    minutesFri: 0,
    minutesMon: 0,
    minutesSat: 0,
    minutesSun: 0,
    minutesTue: 0,
    minutesThu: 0,
    minutesWed: 0,
    notes: "",
    resume: "",
    resumeName: "",
    role: "",
    roleName: "",
  };
  const [staff, setStaff] = useState({
    ...staffDefault,
  });

  // Staff competency
  const staffCompetencyDefault = {
    comments: "",
    competency: "",
    competencyName: "",
    level: 3,
  };
  const [staffCompetency, setStaffCompetency] = useState({
    ...staffCompetencyDefault,
  });

  // Staff development plan
  const staffDevelopmentPlanDefault = {
    comments: "",
    developmentPlan: "",
    due: "",
    name: "",
    status: "In-progress",
  };
  const [staffDevelopmentPlan, setStaffDevelopmentPlan] = useState({
    ...staffDevelopmentPlanDefault,
  });

  // Staff exit action
  const staffExitActionDefault = {
    action: "",
    comments: "",
    due: "",
    name: "",
    owner: "",
    ownerName: "",
    status: "In-progress",
  };
  const [staffExitAction, setStaffExitAction] = useState({
    ...staffExitActionDefault,
  });

  // Staff exit upload
  const staffExitUploadDefault = {
    category: "Other",
    description: "",
    file: "",
    fileName: "",
    name: "",
  };
  const [staffExitUpload, setStaffExitUpload] = useState({
    ...staffExitUploadDefault,
  });

  // Staff goal
  const staffGoalDefault = {
    comments: "",
    due: "",
    goal: "",
    name: "",
    rating: "Not rated",
  };
  const [staffGoal, setStaffGoal] = useState({
    ...staffGoalDefault,
  });

  // Staff onboarding action
  const staffOnboardingActionDefault = {
    action: "",
    comments: "",
    due: "",
    name: "",
    owner: "",
    ownerName: "",
    status: "In-progress",
  };
  const [staffOnboardingAction, setStaffOnboardingAction] = useState({
    ...staffOnboardingActionDefault,
  });

  // Staff onboarding upload
  const staffOnboardingUploadDefault = {
    category: "Other",
    description: "",
    file: "",
    fileName: "",
    name: "",
  };
  const [staffOnboardingUpload, setStaffOnboardingUpload] = useState({
    ...staffOnboardingUploadDefault,
  });

  // Staff permission
  const staffPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [staffPermission, setStaffPermission] = useState({
    ...staffPermissionDefault,
  });

  // Timesheet
  const timesheetDefault = {
    comments: "",
    staff: "",
    weekLabel: "",
  };
  const [timesheet, setTimesheet] = useState({
    ...timesheetDefault,
  });

  const timesheetApprovalRequestDefault = {
    approver: "",
    approverName: "",
    submit: false,
  };
  const [timesheetApprovalRequest, setTimesheetApprovalRequest] = useState({
    ...timesheetApprovalRequestDefault,
  });

  const timesheetReversalDefault = {
    comments: "",
  };
  const [timesheetReversal, setTimesheetReversal] = useState({
    ...timesheetReversalDefault,
  });

  // Timesheet entry
  const timesheetEntryDefault = {
    bookingCode: "",
    bookingCodeName: "",
    comments: "",
    engagementLabourExpense: "",
    minutesFri: 0,
    minutesMon: 0,
    minutesSat: 0,
    minutesSun: 0,
    minutesTue: 0,
    minutesThu: 0,
    minutesWed: 0,
    task: "",
    timesheet: "",
  };
  const [timesheetEntry, setTimesheetEntry] = useState({
    ...timesheetEntryDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Booking
    setBooking({
      ...bookingDefault,
    });

    // Claim
    setClaim({
      ...claimDefault,
    });

    setClaimApprovalRequest({
      ...claimApprovalRequestDefault,
    });

    setClaimReversal({
      ...claimReversalDefault,
    });

    // Expense
    setExpense({
      ...expenseDefault,
    });

    setEditExpenseId(null);
    setShowNewExpenseForm(false);

    // Leave request
    setLeaveRequest({
      ...leaveRequestDefault,
    });

    setLeaveRequestApprovalRequest({
      ...leaveRequestApprovalRequestDefault,
    });

    // Staff
    setStaff({
      ...staffDefault,
    });

    // Staff competency
    setStaffCompetency({
      ...staffCompetencyDefault,
    });

    // Staff development plan
    setStaffDevelopmentPlan({
      ...staffDevelopmentPlanDefault,
    });

    // Staff exit action
    setStaffExitAction({
      ...staffExitActionDefault,
    });

    // Staff exit upload
    setStaffExitUpload({
      ...staffExitUploadDefault,
    });

    // Staff goal
    setStaffGoal({
      ...staffGoalDefault,
    });

    // Staff onboarding action
    setStaffOnboardingAction({
      ...staffOnboardingActionDefault,
    });

    // Staff onboarding upload
    setStaffOnboardingUpload({
      ...staffOnboardingUploadDefault,
    });

    // Staff permission
    setStaffPermission({
      ...staffPermissionDefault,
    });

    // Timesheet
    setTimesheet({
      ...timesheetDefault,
    });

    setTimesheetApprovalRequest({
      ...timesheetApprovalRequestDefault,
    });

    setTimesheetReversal({
      ...timesheetReversalDefault,
    });

    // Timesheet entry
    setTimesheetEntry({
      ...timesheetEntryDefault,
    });

    setEditTimesheetEntryId(null);
    setShowNewTimesheetEntryForm(false);

    // Query variables
    // Booking code
    setBookingCodeQuery({
      ...bookingCodeQueryDefault,
    });

    // Competency
    setCompetencyQuery({
      ...competencyQueryDefault,
    });

    // Expense category
    setExpenseCategoryQuery({
      ...expenseCategoryQueryDefault,
    });

    // Leave category
    setLeaveCategoryQuery({
      ...leaveCategoryQueryDefault,
    });

    // Location
    setLocationQuery({
      ...locationQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Role
    setRoleQuery({
      ...roleQueryDefault,
    });
  };

  // Streaming data websocket connection
  const { lastJsonMessage } = useWebSocket(
    `${BACKEND_WS_URL}/api/v1/data/staff/${id}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  // Initialise drawer variables for booking
  useEffect(() => {
    setBooking({
      bookingCode: bookingDetails.booking.booking_code
        ? bookingDetails.booking.booking_code
        : bookingDefault.bookingCode,
      bookingCodeName: bookingDetails.booking.booking_code_data
        ? bookingDetails.booking.booking_code_data.name
        : staffCompetencyDefault.bookingCodeName,
      comments: bookingDetails.booking.comments
        ? bookingDetails.booking.comments
        : bookingDefault.comments,
      detail:
        bookingDetails.booking.detail &&
        Object.keys(bookingDetails.booking.detail).length > 0
          ? bookingDetails.booking.detail
          : bookingDefault.detail,
      end: bookingDetails.booking.end
        ? new Date(bookingDetails.booking.end)
        : bookingDefault.end,
      method: bookingDetails.booking.method
        ? bookingDetails.booking.method
        : bookingDefault.method,
      minutes: bookingDetails.booking.minutes
        ? bookingDetails.booking.minutes
        : bookingDefault.minutes,
      priority: bookingDetails.booking.priority
        ? bookingDetails.booking.priority
        : bookingDefault.priority,
      start: bookingDetails.booking.start
        ? new Date(bookingDetails.booking.start)
        : bookingDefault.start,
      type: bookingDetails.booking.type
        ? bookingDetails.booking.type
        : bookingDefault.type,
      weekdays:
        bookingDetails.booking.weekdays &&
        Object.keys(bookingDetails.booking.weekdays).length > 0
          ? bookingDetails.booking.weekdays
          : bookingDefault.weekdays,
    });
    // eslint-disable-next-line
  }, [bookingDetails.booking]);

  // Initialise drawer variables for claim
  useEffect(() => {
    setClaim({
      comments: claimDetails.claim.comments
        ? claimDetails.claim.comments
        : claimDefault.comments,
      name: claimDetails.claim.name
        ? claimDetails.claim.name
        : claimDefault.name,
      receipts: claimDetails.claim.receipts
        ? claimDetails.claim.receipts
        : claimDefault.receipts,
      receiptsName: claimDetails.claim.receipts
        ? claimDetails.claim.receipts.split("/").pop()
        : claimDefault.receiptsName,
      staff: claimDetails.claim.staff
        ? claimDetails.claim.staff
        : claimDefault.staff,
      value: claimDetails.claim.value
        ? claimDetails.claim.value
        : claimDefault.value,
    });
    // eslint-disable-next-line
  }, [claimDetails.claim]);

  // Initialise drawer variables for expense
  useEffect(() => {
    setExpense({
      bookingCode: expenseDetails.expense.booking_code
        ? expenseDetails.expense.booking_code
        : expenseDefault.bookingCode,
      bookingCodeName: expenseDetails.expense.booking_code_data
        ? expenseDetails.expense.booking_code_data.name
        : expenseDefault.bookingCodeName,
      category: expenseDetails.expense.category
        ? expenseDetails.expense.category
        : expenseDefault.category,
      categoryName: expenseDetails.expense.category_name
        ? expenseDetails.expense.category_name
        : expenseDefault.categoryName,
      claim: expenseDetails.expense.claim
        ? expenseDetails.expense.claim
        : expenseDefault.claim,
      comments: expenseDetails.expense.comments
        ? expenseDetails.expense.comments
        : expenseDefault.comments,
      date: expenseDetails.expense.date
        ? new Date(expenseDetails.expense.date)
        : expenseDefault.date,
      value: expenseDetails.expense.value
        ? expenseDetails.expense.value
        : expenseDefault.value,
    });
    // eslint-disable-next-line
  }, [expenseDetails.expense]);

  // Initialise drawer variables for leave request
  useEffect(() => {
    setLeaveRequest({
      category: leaveRequestDetails.leave_request.category
        ? leaveRequestDetails.leave_request.category
        : leaveRequestDefault.category,
      categoryName: leaveRequestDetails.leave_request.category_name
        ? leaveRequestDetails.leave_request.category_name
        : leaveRequestDefault.categoryName,
      comments: leaveRequestDetails.leave_request.comments
        ? leaveRequestDetails.leave_request.comments
        : leaveRequestDefault.comments,
      minutesFri: leaveRequestDetails.leave_request.minutes_fri
        ? leaveRequestDetails.leave_request.minutes_fri
        : leaveRequestDefault.minutesFri,
      minutesMon: leaveRequestDetails.leave_request.minutes_mon
        ? leaveRequestDetails.leave_request.minutes_mon
        : leaveRequestDefault.minutesMon,
      minutesSat: leaveRequestDetails.leave_request.minutes_sat
        ? leaveRequestDetails.leave_request.minutes_sat
        : leaveRequestDefault.minutesSat,
      minutesSun: leaveRequestDetails.leave_request.minutes_sun
        ? leaveRequestDetails.leave_request.minutes_sun
        : leaveRequestDefault.minutesSun,
      minutesTue: leaveRequestDetails.leave_request.minutes_tue
        ? leaveRequestDetails.leave_request.minutes_tue
        : leaveRequestDefault.minutesTue,
      minutesThu: leaveRequestDetails.leave_request.minutes_thu
        ? leaveRequestDetails.leave_request.minutes_thu
        : leaveRequestDefault.minutesThu,
      minutesWed: leaveRequestDetails.leave_request.minutes_wed
        ? leaveRequestDetails.leave_request.minutes_wed
        : leaveRequestDefault.minutesWed,
      weekLabel: leaveRequestDetails.leave_request.week_label
        ? leaveRequestDetails.leave_request.week_label
        : leaveRequestDefault.weekLabel,
    });
    // eslint-disable-next-line
  }, [leaveRequestDetails.leave_request]);

  // Initialise drawer variables for staff
  useEffect(() => {
    setStaff({
      customAvailability:
        staffDetails.staff.custom_availability !== null
          ? staffDetails.staff.custom_availability
          : staffDefault.customAvailability,
      employment: staffDetails.staff.employment
        ? staffDetails.staff.employment
        : staffDefault.employment,
      location: staffDetails.staff.location
        ? staffDetails.staff.location
        : staffDefault.location,
      locationName: staffDetails.staff.location_name
        ? staffDetails.staff.location_name
        : staffDefault.locationName,
      manager: staffDetails.staff.manager
        ? staffDetails.staff.manager
        : staffDefault.manager,
      managerName: staffDetails.staff.manager_name
        ? staffDetails.staff.manager_name
        : staffDefault.managerName,
      minutesFri: staffDetails.staff.minutes_fri
        ? staffDetails.staff.minutes_fri
        : staffDefault.minutesFri,
      minutesMon: staffDetails.staff.minutes_mon
        ? staffDetails.staff.minutes_mon
        : staffDefault.minutesMon,
      minutesSat: staffDetails.staff.minutes_sat
        ? staffDetails.staff.minutes_sat
        : staffDefault.minutesSat,
      minutesSun: staffDetails.staff.minutes_sun
        ? staffDetails.staff.minutes_sun
        : staffDefault.minutesSun,
      minutesTue: staffDetails.staff.minutes_tue
        ? staffDetails.staff.minutes_tue
        : staffDefault.minutesTue,
      minutesThu: staffDetails.staff.minutes_thu
        ? staffDetails.staff.minutes_thu
        : staffDefault.minutesThu,
      minutesWed: staffDetails.staff.minutes_wed
        ? staffDetails.staff.minutes_wed
        : staffDefault.minutesWed,
      notes: staffDetails.staff.notes
        ? staffDetails.staff.notes
        : staffDefault.notes,
      resume: staffDetails.staff.resume
        ? staffDetails.staff.resume
        : staffDefault.resume,
      resumeName: staffDetails.staff.resume
        ? staffDetails.staff.resume.split("/").pop()
        : staffDefault.resumeName,
      role: staffDetails.staff.role
        ? staffDetails.staff.role
        : staffDefault.role,
      roleName: staffDetails.staff.role_data
        ? staffDetails.staff.role_data.name
        : staffDefault.roleName,
    });
    // eslint-disable-next-line
  }, [staffDetails.staff]);

  // Initialise drawer variables for staff competency
  useEffect(() => {
    setStaffCompetency({
      comments: staffCompetencyDetails.staff_competency.comments
        ? staffCompetencyDetails.staff_competency.comments
        : staffCompetencyDefault.comments,
      competency: staffCompetencyDetails.staff_competency.competency
        ? staffCompetencyDetails.staff_competency.competency
        : staffCompetencyDefault.competency,
      competencyName: staffCompetencyDetails.staff_competency.competency_data
        ? staffCompetencyDetails.staff_competency.competency_data.name
        : staffCompetencyDefault.competencyName,
      level: staffCompetencyDetails.staff_competency.level
        ? staffCompetencyDetails.staff_competency.level
        : staffCompetencyDefault.level,
    });
    // eslint-disable-next-line
  }, [staffCompetencyDetails.staff_competency]);

  // Initialise drawer variables for staff development plan
  useEffect(() => {
    setStaffDevelopmentPlan({
      comments: staffDevelopmentPlanDetails.staff_development_plan.comments
        ? staffDevelopmentPlanDetails.staff_development_plan.comments
        : staffDevelopmentPlanDefault.comments,
      developmentPlan: staffDevelopmentPlanDetails.staff_development_plan
        .development_plan
        ? staffDevelopmentPlanDetails.staff_development_plan.development_plan
        : staffDevelopmentPlanDefault.developmentPlan,
      due: staffDevelopmentPlanDetails.staff_development_plan.due
        ? new Date(staffDevelopmentPlanDetails.staff_development_plan.due)
        : staffDevelopmentPlanDefault.due,
      name: staffDevelopmentPlanDetails.staff_development_plan.name
        ? staffDevelopmentPlanDetails.staff_development_plan.name
        : staffDevelopmentPlanDefault.name,
      status: staffDevelopmentPlanDetails.staff_development_plan.status
        ? staffDevelopmentPlanDetails.staff_development_plan.status
        : staffDevelopmentPlanDefault.status,
    });
    // eslint-disable-next-line
  }, [staffDevelopmentPlanDetails.staff_development_plan]);

  // Initialise drawer variables for staff exit action
  useEffect(() => {
    setStaffExitAction({
      action: staffExitActionDetails.staff_exit_action.action
        ? staffExitActionDetails.staff_exit_action.action
        : staffExitActionDefault.action,
      comments: staffExitActionDetails.staff_exit_action.comments
        ? staffExitActionDetails.staff_exit_action.comments
        : staffExitActionDefault.comments,
      due: staffExitActionDetails.staff_exit_action.due
        ? new Date(staffExitActionDetails.staff_exit_action.due)
        : staffExitActionDefault.due,
      name: staffExitActionDetails.staff_exit_action.name
        ? staffExitActionDetails.staff_exit_action.name
        : staffExitActionDefault.name,
      owner: staffExitActionDetails.staff_exit_action.owner
        ? staffExitActionDetails.staff_exit_action.owner
        : staffExitActionDefault.owner,
      ownerName: staffExitActionDetails.staff_exit_action.owner_name
        ? staffExitActionDetails.staff_exit_action.owner_name
        : staffExitActionDefault.ownerName,
      status: staffExitActionDetails.staff_exit_action.status
        ? staffExitActionDetails.staff_exit_action.status
        : staffExitActionDefault.status,
    });
    // eslint-disable-next-line
  }, [staffExitActionDetails.staff_exit_action]);

  // Initialise drawer variables for staff exit upload
  useEffect(() => {
    setStaffExitUpload({
      category: staffExitUploadDetails.staff_exit_upload.category
        ? staffExitUploadDetails.staff_exit_upload.category
        : staffExitUploadDefault.category,
      description: staffExitUploadDetails.staff_exit_upload.description
        ? staffExitUploadDetails.staff_exit_upload.description
        : staffExitUploadDefault.description,
      file: staffExitUploadDetails.staff_exit_upload.file
        ? staffExitUploadDetails.staff_exit_upload.file
        : staffExitUploadDefault.file,
      fileName: staffExitUploadDetails.staff_exit_upload.file
        ? staffExitUploadDetails.staff_exit_upload.file.split("/").pop()
        : staffExitUploadDefault.fileName,
      name: staffExitUploadDetails.staff_exit_upload.name
        ? staffExitUploadDetails.staff_exit_upload.name
        : staffExitUploadDefault.name,
    });
    // eslint-disable-next-line
  }, [staffExitUploadDetails.staff_exit_upload]);

  // Initialise drawer variables for staff goal
  useEffect(() => {
    setStaffGoal({
      comments: staffGoalDetails.staff_goal.comments
        ? staffGoalDetails.staff_goal.comments
        : staffGoalDefault.comments,
      due: staffGoalDetails.staff_goal.due
        ? new Date(staffGoalDetails.staff_goal.due)
        : staffGoalDefault.due,
      goal: staffGoalDetails.staff_goal.goal
        ? staffGoalDetails.staff_goal.goal
        : staffGoalDefault.goal,
      name: staffGoalDetails.staff_goal.name
        ? staffGoalDetails.staff_goal.name
        : staffGoalDefault.name,
      rating: staffGoalDetails.staff_goal.rating
        ? staffGoalDetails.staff_goal.rating
        : staffGoalDefault.rating,
    });
    // eslint-disable-next-line
  }, [staffGoalDetails.staff_goal]);

  // Initialise drawer variables for staff onboarding action
  useEffect(() => {
    setStaffOnboardingAction({
      action: staffOnboardingActionDetails.staff_onboarding_action.action
        ? staffOnboardingActionDetails.staff_onboarding_action.action
        : staffOnboardingActionDefault.action,
      comments: staffOnboardingActionDetails.staff_onboarding_action.comments
        ? staffOnboardingActionDetails.staff_onboarding_action.comments
        : staffOnboardingActionDefault.comments,
      due: staffOnboardingActionDetails.staff_onboarding_action.due
        ? new Date(staffOnboardingActionDetails.staff_onboarding_action.due)
        : staffOnboardingActionDefault.due,
      name: staffOnboardingActionDetails.staff_onboarding_action.name
        ? staffOnboardingActionDetails.staff_onboarding_action.name
        : staffOnboardingActionDefault.name,
      owner: staffOnboardingActionDetails.staff_onboarding_action.owner
        ? staffOnboardingActionDetails.staff_onboarding_action.owner
        : staffOnboardingActionDefault.owner,
      ownerName: staffOnboardingActionDetails.staff_onboarding_action.owner_name
        ? staffOnboardingActionDetails.staff_onboarding_action.owner_name
        : staffOnboardingActionDefault.ownerName,
      status: staffOnboardingActionDetails.staff_onboarding_action.status
        ? staffOnboardingActionDetails.staff_onboarding_action.status
        : staffOnboardingActionDefault.status,
    });
    // eslint-disable-next-line
  }, [staffOnboardingActionDetails.staff_onboarding_action]);

  // Initialise drawer variables for staff onboarding upload
  useEffect(() => {
    setStaffOnboardingUpload({
      category: staffOnboardingUploadDetails.staff_onboarding_upload.category
        ? staffOnboardingUploadDetails.staff_onboarding_upload.category
        : staffOnboardingUploadDefault.category,
      description: staffOnboardingUploadDetails.staff_onboarding_upload
        .description
        ? staffOnboardingUploadDetails.staff_onboarding_upload.description
        : staffOnboardingUploadDefault.description,
      file: staffOnboardingUploadDetails.staff_onboarding_upload.file
        ? staffOnboardingUploadDetails.staff_onboarding_upload.file
        : staffOnboardingUploadDefault.file,
      fileName: staffOnboardingUploadDetails.staff_onboarding_upload.file
        ? staffOnboardingUploadDetails.staff_onboarding_upload.file
            .split("/")
            .pop()
        : staffOnboardingUploadDefault.fileName,
      name: staffOnboardingUploadDetails.staff_onboarding_upload.name
        ? staffOnboardingUploadDetails.staff_onboarding_upload.name
        : staffOnboardingUploadDefault.name,
    });
    // eslint-disable-next-line
  }, [staffOnboardingUploadDetails.staff_onboarding_upload]);

  // Initialise drawer variables for staff permission
  useEffect(() => {
    setStaffPermission({
      level: staffPermissionDetails.staff_permission.level
        ? staffPermissionDetails.staff_permission.level
        : staffPermissionDefault.level,
      manager: staffPermissionDetails.staff_permission.manager
        ? staffPermissionDetails.staff_permission.manager
        : staffPermissionDefault.manager,
      managerName: staffPermissionDetails.staff_permission.manager_name
        ? staffPermissionDetails.staff_permission.manager_name
        : staffPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [staffPermissionDetails.staff_permission]);

  // Initialise drawer variables for timesheet
  useEffect(() => {
    setTimesheet({
      comments: timesheetDetails.timesheet.comments
        ? timesheetDetails.timesheet.comments
        : timesheetDefault.comments,
      staff: timesheetDetails.timesheet.staff
        ? timesheetDetails.timesheet.staff
        : timesheetDefault.staff,
      weekLabel: timesheetDetails.timesheet.week_label
        ? timesheetDetails.timesheet.week_label
        : timesheetDefault.weekLabel,
    });
    // eslint-disable-next-line
  }, [timesheetDetails.timesheet]);

  // Initialise drawer variables for timesheet entry
  useEffect(() => {
    setTimesheetEntry({
      bookingCode: timesheetEntryDetails.timesheet_entry.booking_code
        ? timesheetEntryDetails.timesheet_entry.booking_code
        : timesheetEntryDefault.bookingCode,
      bookingCodeName: timesheetEntryDetails.timesheet_entry.booking_code_data
        ? timesheetEntryDetails.timesheet_entry.booking_code_data.name
        : timesheetEntryDefault.bookingCodeName,
      comments: timesheetEntryDetails.timesheet_entry.comments
        ? timesheetEntryDetails.timesheet_entry.comments
        : timesheetEntryDefault.comments,
      engagementLabourExpense: timesheetEntryDetails.timesheet_entry
        .engagement_labour_expense
        ? timesheetEntryDetails.timesheet_entry.engagement_labour_expense
        : timesheetEntryDefault.engagementLabourExpense,
      minutesFri: timesheetEntryDetails.timesheet_entry.minutes_fri
        ? timesheetEntryDetails.timesheet_entry.minutes_fri
        : timesheetEntryDefault.minutesFri,
      minutesMon: timesheetEntryDetails.timesheet_entry.minutes_mon
        ? timesheetEntryDetails.timesheet_entry.minutes_mon
        : timesheetEntryDefault.minutesMon,
      minutesSat: timesheetEntryDetails.timesheet_entry.minutes_sat
        ? timesheetEntryDetails.timesheet_entry.minutes_sat
        : timesheetEntryDefault.minutesSat,
      minutesSun: timesheetEntryDetails.timesheet_entry.minutes_sun
        ? timesheetEntryDetails.timesheet_entry.minutes_sun
        : timesheetEntryDefault.minutesSun,
      minutesTue: timesheetEntryDetails.timesheet_entry.minutes_tue
        ? timesheetEntryDetails.timesheet_entry.minutes_tue
        : timesheetEntryDefault.minutesTue,
      minutesThu: timesheetEntryDetails.timesheet_entry.minutes_thu
        ? timesheetEntryDetails.timesheet_entry.minutes_thu
        : timesheetEntryDefault.minutesThu,
      minutesWed: timesheetEntryDetails.timesheet_entry.minutes_wed
        ? timesheetEntryDetails.timesheet_entry.minutes_wed
        : timesheetEntryDefault.minutesWed,
      task: timesheetEntryDetails.timesheet_entry.task
        ? timesheetEntryDetails.timesheet_entry.task
        : timesheetEntryDefault.task,
      timesheet: timesheetEntryDetails.timesheet_entry.timesheet
        ? timesheetEntryDetails.timesheet_entry.timesheet
        : timesheetEntryDefault.timesheet,
    });
    // eslint-disable-next-line
  }, [timesheetEntryDetails.timesheet_entry]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Load engagement role and task data
  const [engagementRoleOptions, setEngagementRoleOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);

  useEffect(() => {
    if (timesheetEntry.bookingCode) {
      dispatch(listBookingCodeDetailsAction(timesheetEntry.bookingCode));
    }
    // eslint-disable-next-line
  }, [timesheetEntry.bookingCode]);

  useEffect(() => {
    if (bookingCodeDetails.success) {
      const engagementRoleChoices = [];

      if (bookingCodeDetails.booking_code.engagement_roles_data) {
        bookingCodeDetails.booking_code.engagement_roles_data
          .filter((engagement_role) => engagement_role.staff === Number(id))
          .map((role) =>
            engagementRoleChoices.push({
              id: role.engagement_labour_expense,
              label: role.role_name,
              value: role.engagement_labour_expense,
            })
          );

        engagementRoleChoices.length > 0 &&
          setTimesheetEntry((prevState) => {
            return {
              ...prevState,
              engagementLabourExpense: engagementRoleChoices[0].value,
            };
          });
      }

      setEngagementRoleOptions(engagementRoleChoices);

      const taskChoices = [
        {
          id: 0,
          label: "None",
          value: "",
        },
      ];

      if (bookingCodeDetails.booking_code.tasks) {
        bookingCodeDetails.booking_code.tasks.map((task, i) =>
          taskChoices.push({
            id: i + 1,
            value: task,
          })
        );

        taskChoices.length > 0 &&
          setTimesheetEntry((prevState) => {
            return {
              ...prevState,
              task: taskChoices.filter(
                (task) => task.value === timesheetEntry.task
              )[0]
                ? taskChoices.filter(
                    (task) => task.value === timesheetEntry.task
                  )[0].value
                : taskChoices[0].value,
            };
          });
      }

      setTaskOptions(taskChoices);

      resetListBookingCodeDetailsAction();
    }
    // eslint-disable-next-line
  }, [bookingCodeDetails.success]);

  // Load week data
  const [weekOptions, setWeekOptions] = useState([]);

  useEffect(() => {
    dispatch(listWeeksAction({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (weekList.weeks.week_choices) {
      const weekChoices = [];

      weekList.weeks.week_choices.map((week) =>
        weekChoices.push({
          id: week,
          value: week,
        })
      );

      if (weekChoices.length > 0) {
        addLeaveRequestDrawerIsOpen &&
          setLeaveRequest((prevState) => {
            return {
              ...prevState,
              weekLabel: weekChoices[26].value,
            };
          });

        addTimesheetDrawerIsOpen &&
          setTimesheet((prevState) => {
            return {
              ...prevState,
              weekLabel: weekChoices[26].value,
            };
          });
      }

      setWeekOptions(weekChoices);
    }
    // eslint-disable-next-line
  }, [addLeaveRequestDrawerIsOpen, addTimesheetDrawerIsOpen, weekList.success]);

  // Load staff details
  useEffect(() => {
    dispatch(listStaffDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter bookings
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listBookingsAction({
            ...bookingQuery,
            endEnd: bookingQuery.endEnd
              ? getDjangoDate(bookingQuery.endEnd)
              : "",
            endStart: bookingQuery.endStart
              ? getDjangoDate(bookingQuery.endStart)
              : "",
            startEnd: bookingQuery.startEnd
              ? getDjangoDate(bookingQuery.startEnd)
              : "",
            startStart: bookingQuery.startStart
              ? getDjangoDate(bookingQuery.startStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingQuery]);

  // Filter booking codes
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listBookingCodesAction({
            ...bookingCodeQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingCodeQuery]);

  // Filter claims
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClaimsAction({
            ...claimQuery,
            completedEnd: claimQuery.completedEnd
              ? getDjangoDate(claimQuery.completedEnd)
              : getDjangoDate(claimQueryDefault.completedEnd),
            completedStart: claimQuery.completedStart
              ? getDjangoDate(claimQuery.completedStart)
              : getDjangoDate(claimQueryDefault.completedStart),
            submittedEnd: claimQuery.submittedEnd
              ? getDjangoDate(claimQuery.submittedEnd)
              : getDjangoDate(claimQueryDefault.submittedEnd),
            submittedStart: claimQuery.submittedStart
              ? getDjangoDate(claimQuery.submittedStart)
              : getDjangoDate(claimQueryDefault.submittedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [claimQuery]);

  // Filter competencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCompetenciesAction({
            ...competencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [competencyQuery]);

  // Filter expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listExpensesAction({
            ...expenseQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseQuery]);

  // Filter expense categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listExpenseCategoriesAction({
            ...expenseCategoryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseCategoryQuery]);

  // Filter leave categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listLeaveCategoriesAction({
            ...leaveCategoryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [leaveCategoryQuery]);

  // Filter leave requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listLeaveRequestsAction({
            ...leaveRequestQuery,
            completedEnd: leaveRequestQuery.completedEnd
              ? getDjangoDate(leaveRequestQuery.completedEnd)
              : getDjangoDate(leaveRequestQueryDefault.completedEnd),
            completedStart: leaveRequestQuery.completedStart
              ? getDjangoDate(leaveRequestQuery.completedStart)
              : getDjangoDate(leaveRequestQueryDefault.completedStart),
            submittedEnd: leaveRequestQuery.submittedEnd
              ? getDjangoDate(leaveRequestQuery.submittedEnd)
              : getDjangoDate(leaveRequestQueryDefault.submittedEnd),
            submittedStart: leaveRequestQuery.submittedStart
              ? getDjangoDate(leaveRequestQuery.submittedStart)
              : getDjangoDate(leaveRequestQueryDefault.submittedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [leaveRequestQuery]);

  // Filter locations
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listLocationsAction({ ...locationQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [locationQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  const [decisionRight, setDecisionRight] = useState("");
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  useEffect(() => {
    let managers = [];

    if (decisionRight === "Staff expense") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_staff_expense &&
            manager.approval_role_data.can_approve_staff_expense_threshold >=
              claimDetails.claim.value
          : false
      );
    }

    if (decisionRight === "Staff leave") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_staff_leave &&
            manager.approval_role_data.can_approve_staff_leave_threshold >=
              leaveRequestDetails.leave_request.total_minutes
          : false
      );
    }

    if (decisionRight === "Staff timesheet") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_staff_timesheet &&
            manager.approval_role_data.can_approve_staff_timesheet_threshold >=
              timesheetDetails.timesheet.total_minutes
          : false
      );
    }

    setFilteredManagerList(managers);
  }, [
    claimDetails.claim.value,
    decisionRight,
    leaveRequestDetails.leave_request.total_minutes,
    managerList.managers,
    timesheetDetails.timesheet.total_minutes,
  ]);

  // Filter roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRolesAction({
            ...roleQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleQuery]);

  // Filter staff competencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffCompetenciesAction({
            ...staffCompetencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffCompetencyQuery]);

  // Filter staff development plans
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffDevelopmentPlansAction({
            ...staffDevelopmentPlanQuery,
            dueEnd: staffDevelopmentPlanQuery.dueEnd
              ? getDjangoDate(staffDevelopmentPlanQuery.dueEnd)
              : "",
            dueStart: staffDevelopmentPlanQuery.dueStart
              ? getDjangoDate(staffDevelopmentPlanQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffDevelopmentPlanQuery]);

  // Filter staff exit actions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffExitActionsAction({
            ...staffExitActionQuery,
            dueEnd: staffExitActionQuery.dueEnd
              ? getDjangoDate(staffExitActionQuery.dueEnd)
              : "",
            dueStart: staffExitActionQuery.dueStart
              ? getDjangoDate(staffExitActionQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffExitActionQuery]);

  // Filter staff exit uploads
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffExitUploadsAction({
            ...staffExitUploadQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffExitUploadQuery]);

  // Filter staff goals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffGoalsAction({
            ...staffGoalQuery,
            dueEnd: staffGoalQuery.dueEnd
              ? getDjangoDate(staffGoalQuery.dueEnd)
              : "",
            dueStart: staffGoalQuery.dueStart
              ? getDjangoDate(staffGoalQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffGoalQuery]);

  // Filter staff onboarding actions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffOnboardingActionsAction({
            ...staffOnboardingActionQuery,
            dueEnd: staffOnboardingActionQuery.dueEnd
              ? getDjangoDate(staffOnboardingActionQuery.dueEnd)
              : "",
            dueStart: staffOnboardingActionQuery.dueStart
              ? getDjangoDate(staffOnboardingActionQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffOnboardingActionQuery]);

  // Filter staff onboarding uploads
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffOnboardingUploadsAction({
            ...staffOnboardingUploadQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffOnboardingUploadQuery]);

  // Filter staff permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listStaffPermissionsAction({ ...staffPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffPermissionQuery]);

  // Filter timesheets
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listTimesheetsAction({
            ...timesheetQuery,
            completedEnd: timesheetQuery.completedEnd
              ? getDjangoDate(timesheetQuery.completedEnd)
              : getDjangoDate(timesheetQueryDefault.completedEnd),
            completedStart: timesheetQuery.completedStart
              ? getDjangoDate(timesheetQuery.completedStart)
              : getDjangoDate(timesheetQueryDefault.completedStart),
            submittedEnd: timesheetQuery.submittedEnd
              ? getDjangoDate(timesheetQuery.submittedEnd)
              : getDjangoDate(timesheetQueryDefault.submittedEnd),
            submittedStart: timesheetQuery.submittedStart
              ? getDjangoDate(timesheetQuery.submittedStart)
              : getDjangoDate(timesheetQueryDefault.submittedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [timesheetQuery]);

  // Filter timesheet entries
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listTimesheetEntriesAction({
            ...timesheetEntryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [timesheetEntryQuery]);

  // Form submission
  // Booking
  // Add booking
  const addBooking = () => {
    // Prepare data
    const booking_data = new FormData();

    booking.bookingCode
      ? booking_data.append("booking_code", booking.bookingCode)
      : booking_data.append("booking_code", bookingDefault.bookingCode);

    booking.comments
      ? booking_data.append("comments", booking.comments)
      : booking_data.append("comments", bookingDefault.comments);

    booking.detail
      ? booking_data.append("detail", JSON.stringify(booking.detail))
      : booking_data.append("detail", JSON.stringify(bookingDefault.detail));

    booking.end
      ? booking_data.append("end", getDjangoDate(booking.end))
      : booking_data.append("end", getDjangoDate(bookingDefault.end));

    booking.method
      ? booking_data.append("method", booking.method)
      : booking_data.append("method", bookingDefault.method);

    booking.minutes
      ? booking_data.append("minutes", booking.minutes)
      : booking_data.append("minutes", bookingDefault.minutes);

    booking_data.append("organisation", activeOrganisation.id);

    booking_data.append("staff", id);

    booking.priority
      ? booking_data.append("priority", booking.priority)
      : booking_data.append("priority", bookingDefault.priority);

    booking.start
      ? booking_data.append("start", getDjangoDate(booking.start))
      : booking_data.append("start", getDjangoDate(bookingDefault.start));

    booking.type
      ? booking_data.append("type", booking.type)
      : booking_data.append("type", bookingDefault.type);

    booking.weekdays
      ? booking_data.append("weekdays", JSON.stringify(booking.weekdays))
      : booking_data.append(
          "weekdays",
          JSON.stringify(bookingDefault.weekdays)
        );

    // Dispatch action
    dispatch(createBookingAction(booking_data));
  };

  // Delete booking
  const deleteBooking = () => {
    // Dispatch action
    dispatch(deleteBookingAction(bookingDetails.booking.id));
  };

  // Update booking
  const updateBooking = () => {
    // Prepare data
    const booking_data = new FormData();

    booking.bookingCode
      ? booking_data.append("booking_code", booking.bookingCode)
      : booking_data.append("booking_code", bookingDefault.bookingCode);

    booking.comments
      ? booking_data.append("comments", booking.comments)
      : booking_data.append("comments", bookingDefault.comments);

    booking.detail
      ? booking_data.append("detail", JSON.stringify(booking.detail))
      : booking_data.append("detail", JSON.stringify(bookingDefault.detail));

    booking.end
      ? booking_data.append("end", getDjangoDate(booking.end))
      : booking_data.append("end", getDjangoDate(bookingDefault.end));

    booking.method
      ? booking_data.append("method", booking.method)
      : booking_data.append("method", bookingDefault.method);

    booking.minutes
      ? booking_data.append("minutes", booking.minutes)
      : booking_data.append("minutes", bookingDefault.minutes);

    booking.priority
      ? booking_data.append("priority", booking.priority)
      : booking_data.append("priority", bookingDefault.priority);

    booking.start
      ? booking_data.append("start", getDjangoDate(booking.start))
      : booking_data.append("start", getDjangoDate(bookingDefault.start));

    booking.type
      ? booking_data.append("type", booking.type)
      : booking_data.append("type", bookingDefault.type);

    booking.weekdays
      ? booking_data.append("weekdays", JSON.stringify(booking.weekdays))
      : booking_data.append(
          "weekdays",
          JSON.stringify(bookingDefault.weekdays)
        );

    // Dispatch action
    dispatch(updateBookingAction(bookingDetails.booking.id, booking_data));
  };

  // Claim
  // Add claim
  const addClaim = () => {
    // Prepare data
    const claim_data = new FormData();

    claim.name
      ? claim_data.append("name", claim.name)
      : claim_data.append("name", claimDefault.name);

    claim_data.append("organisation", activeOrganisation.id);

    claim_data.append("staff", id);

    // Dispatch action
    dispatch(createClaimAction(claim_data));
  };

  // Delete claim
  const deleteClaim = () => {
    // Dispatch action
    dispatch(deleteClaimAction(claimDetails.claim.id));
  };

  // Duplicate claim
  useEffect(() => {
    if (claimDuplicate.success) {
      dispatch(listClaimsAction({ ...claimQuery }));

      dispatch(listClaimDetailsAction(claimDuplicate.claim));

      setExpenseQuery({
        claim: claimDuplicate.claim,
        organisation: activeOrganisation.id,
        page: 1,
      });

      dispatch(resetDuplicateClaimAction());

      setUpdateClaimDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [claimDuplicate.success]);

  // Reverse claim
  const reverseClaim = () => {
    // Prepare data
    const reversal_data = new FormData();

    claimReversal.comments
      ? reversal_data.append("comments", claimReversal.comments)
      : reversal_data.append("comments", claimReversalDefault.comments);

    // Dispatch action
    dispatch(reverseClaimAction(claimDetails.claim.id, reversal_data));
  };

  useEffect(() => {
    if (claimReverse.success) {
      dispatch(listClaimsAction({ ...claimQuery }));

      setUpdateClaimDrawerIsOpen(false);

      dispatch(resetReverseClaimAction());

      resetDrawerVariables();

      AppToaster.show({
        icon: "warning-sign",
        intent: "warning",
        message: "Claim reversed.",
      });
    }
    // eslint-disable-next-line
  }, [claimReverse.success]);

  // Update claim
  useEffect(() => {
    if (claimCreate.success) {
      dispatch(listClaimDetailsAction(claimCreate.claim.id));

      setExpenseQuery({
        claim: claimCreate.claim.id,
        organisation: activeOrganisation.id,
      });

      dispatch(resetCreateClaimAction());

      setUpdateClaimDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [claimCreate.success]);

  useEffect(() => {
    if (updateClaimDrawerIsOpen) {
      setDecisionRight("Staff expense");
    }
    // eslint-disable-next-line
  }, [updateClaimDrawerIsOpen]);

  const updateClaim = () => {
    // Prepare approval request data
    const approval_request_data = new FormData();

    if (claimApprovalRequest.submit) {
      approval_request_data.append("approver", claimApprovalRequest.approver);

      approval_request_data.append("comments", claim.comments);

      approval_request_data.append("decision_right", "Staff expense");

      approval_request_data.append("item_id", claimDetails.claim.id);

      approval_request_data.append("organisation", activeOrganisation.id);

      approval_request_data.append("status", "Submitted");
    }

    // Prepare claim data
    const claim_data = new FormData();

    claim.comments
      ? claim_data.append("comments", claim.comments)
      : claim_data.append("comments", claimDefault.comments);

    claim.name
      ? claim_data.append("name", claim.name)
      : claim_data.append("name", claimDefault.name);

    if (claim.receipts && claim.receipts !== claimDetails.claim.receipts) {
      claim_data.append("receipts", claim.receipts);
    } else if (claim.receipts === "") {
      claim_data.append("receipts", claimDefault.receipts);
    }

    claim.value
      ? claim_data.append("value", claim.value)
      : claim_data.append("value", claimDefault.value);

    // Dispatch action
    dispatch(
      updateClaimAction(
        claimDetails.claim.id,
        approval_request_data,
        claim_data
      )
    );
  };

  // Expense
  const [editExpenseId, setEditExpenseId] = useState(null);
  const [showNewExpenseForm, setShowNewExpenseForm] = useState(false);

  // Add expense
  const addExpense = () => {
    // Prepare data
    const expense_data = new FormData();

    expense.bookingCode
      ? expense_data.append("booking_code", expense.bookingCode)
      : expense_data.append("booking_code", expenseDefault.bookingCode);

    expense.category
      ? expense_data.append("category", expense.category)
      : expense_data.append("category", expenseDefault.category);

    expense_data.append("claim", claimDetails.claim.id);

    expense.comments
      ? expense_data.append("comments", expense.comments)
      : expense_data.append("comments", expenseDefault.comments);

    expense.date
      ? expense_data.append("date", getDjangoDate(expense.date))
      : expense_data.append("date", getDjangoDate(expenseDefault.date));

    expense.value
      ? expense_data.append("value", expense.value)
      : expense_data.append("value", expenseDefault.value);

    // Dispatch action
    dispatch(createExpenseAction(expense_data));
  };

  // Delete expense
  const deleteExpense = () => {
    // Dispatch action
    dispatch(deleteExpenseAction(expenseDetails.expense.id));
  };

  // Update expense
  const updateExpense = () => {
    // Prepare data
    const expense_data = new FormData();

    expense.bookingCode
      ? expense_data.append("booking_code", expense.bookingCode)
      : expense_data.append("booking_code", expenseDefault.bookingCode);

    expense.category
      ? expense_data.append("category", expense.category)
      : expense_data.append("category", expenseDefault.category);

    expense.comments
      ? expense_data.append("comments", expense.comments)
      : expense_data.append("comments", expenseDefault.comments);

    expense.date
      ? expense_data.append("date", getDjangoDate(expense.date))
      : expense_data.append("date", getDjangoDate(expenseDefault.date));

    expense.value
      ? expense_data.append("value", expense.value)
      : expense_data.append("value", expenseDefault.value);

    // Dispatch action
    dispatch(updateExpenseAction(expenseDetails.expense.id, expense_data));
  };

  useEffect(() => {
    if (
      expenseCreate.success ||
      expenseDelete.success ||
      expenseUpdate.success
    ) {
      dispatch(listClaimsAction({ ...claimQuery }));
    }
    // eslint-disable-next-line
  }, [expenseCreate.success, expenseDelete.success, expenseUpdate.success]);

  // Leave request
  // Add leave request
  const addLeaveRequest = () => {
    // Prepare data
    const leave_request_data = new FormData();

    leave_request_data.append("organisation", activeOrganisation.id);

    leave_request_data.append("staff", id);

    leaveRequest.weekLabel
      ? leave_request_data.append("week_label", leaveRequest.weekLabel)
      : leave_request_data.append("week_label", leaveRequestDefault.weekLabel);

    // Dispatch action
    dispatch(createLeaveRequestAction(leave_request_data));
  };

  // Cancel leave request
  useEffect(() => {
    if (leaveRequestCancel.success) {
      dispatch(listLeaveRequestsAction({ ...leaveRequestQuery }));

      dispatch(resetCancelLeaveRequestAction());

      setUpdateLeaveRequestDrawerIsOpen(false);
    }
    // eslint-disable-next-line
  }, [leaveRequestCancel.success]);

  // Delete leave request
  const deleteLeaveRequest = () => {
    // Dispatch action
    dispatch(deleteLeaveRequestAction(leaveRequestDetails.leave_request.id));
  };

  // Update leave request
  useEffect(() => {
    if (leaveRequestCreate.success) {
      dispatch(
        listLeaveRequestDetailsAction(leaveRequestCreate.leave_request.id)
      );

      dispatch(resetCreateLeaveRequestAction());

      setUpdateLeaveRequestDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [leaveRequestCreate.success]);

  useEffect(() => {
    if (updateLeaveRequestDrawerIsOpen) {
      setDecisionRight("Staff leave");
    }
    // eslint-disable-next-line
  }, [updateLeaveRequestDrawerIsOpen]);

  const updateLeaveRequest = () => {
    // Prepare approval request data
    const approval_request_data = new FormData();

    if (leaveRequestApprovalRequest.submit) {
      approval_request_data.append(
        "approver",
        leaveRequestApprovalRequest.approver
      );

      approval_request_data.append("comments", leaveRequest.comments);

      approval_request_data.append("decision_right", "Staff leave");

      approval_request_data.append(
        "item_id",
        leaveRequestDetails.leave_request.id
      );

      approval_request_data.append("organisation", activeOrganisation.id);

      approval_request_data.append("status", "Submitted");
    }

    // Prepare leave request data
    const leave_request_data = new FormData();

    leaveRequest.category
      ? leave_request_data.append("category", leaveRequest.category)
      : leave_request_data.append("category", leaveRequestDefault.category);

    leaveRequest.comments
      ? leave_request_data.append("comments", leaveRequest.comments)
      : leave_request_data.append("comments", leaveRequestDefault.comments);

    leaveRequest.minutesFri
      ? leave_request_data.append("minutes_fri", leaveRequest.minutesFri)
      : leave_request_data.append(
          "minutes_fri",
          leaveRequestDefault.minutesFri
        );

    leaveRequest.minutesMon
      ? leave_request_data.append("minutes_mon", leaveRequest.minutesMon)
      : leave_request_data.append(
          "minutes_mon",
          leaveRequestDefault.minutesMon
        );

    leaveRequest.minutesSat
      ? leave_request_data.append("minutes_sat", leaveRequest.minutesSat)
      : leave_request_data.append(
          "minutes_sat",
          leaveRequestDefault.minutesSat
        );

    leaveRequest.minutesSun
      ? leave_request_data.append("minutes_sun", leaveRequest.minutesSun)
      : leave_request_data.append(
          "minutes_sun",
          leaveRequestDefault.minutesSun
        );

    leaveRequest.minutesTue
      ? leave_request_data.append("minutes_tue", leaveRequest.minutesTue)
      : leave_request_data.append(
          "minutes_tue",
          leaveRequestDefault.minutesTue
        );

    leaveRequest.minutesThu
      ? leave_request_data.append("minutes_thu", leaveRequest.minutesThu)
      : leave_request_data.append(
          "minutes_thu",
          leaveRequestDefault.minutesThu
        );

    leaveRequest.minutesWed
      ? leave_request_data.append("minutes_wed", leaveRequest.minutesWed)
      : leave_request_data.append(
          "minutes_wed",
          leaveRequestDefault.minutesWed
        );

    leave_request_data.append("organisation", activeOrganisation.id);

    leaveRequest.weekLabel
      ? leave_request_data.append("week_label", leaveRequest.weekLabel)
      : leave_request_data.append("week_label", leaveRequestDefault.weekLabel);

    // Dispatch action
    dispatch(
      updateLeaveRequestAction(
        leaveRequestDetails.leave_request.id,
        approval_request_data,
        leave_request_data
      )
    );
  };

  // Staff
  // Update staff summary
  const updateSummary = () => {
    // Prepare data
    const staff_data = new FormData();

    staff.employment
      ? staff_data.append("employment", staff.employment)
      : staff_data.append("employment", staffDefault.employment);

    staff.location
      ? staff_data.append("location", staff.location)
      : staff_data.append("location", staffDefault.location);

    staff.manager
      ? staff_data.append("manager", staff.manager)
      : staff_data.append("manager", staffDefault.manager);

    staff.notes
      ? staff_data.append("notes", staff.notes)
      : staff_data.append("notes", staffDefault.notes);

    staff.role
      ? staff_data.append("role", staff.role)
      : staff_data.append("role", staffDefault.role);

    // Dispatch action
    dispatch(updateStaffAction(staffDetails.staff.id, staff_data));
  };

  // Update staff work hours
  const updateWorkHours = () => {
    // Prepare data
    const staff_data = new FormData();

    staff.customAvailability !== null
      ? staff_data.append("custom_availability", staff.customAvailability)
      : staff_data.append(
          "custom_availability",
          staffDefault.customAvailability
        );

    staff.minutesFri
      ? staff_data.append("minutes_fri", staff.minutesFri)
      : staff_data.append("minutes_fri", staffDefault.minutesFri);

    staff.minutesMon
      ? staff_data.append("minutes_mon", staff.minutesMon)
      : staff_data.append("minutes_mon", staffDefault.minutesMon);

    staff.minutesSat
      ? staff_data.append("minutes_sat", staff.minutesSat)
      : staff_data.append("minutes_sat", staffDefault.minutesSat);

    staff.minutesSun
      ? staff_data.append("minutes_sun", staff.minutesSun)
      : staff_data.append("minutes_sun", staffDefault.minutesSun);

    staff.minutesTue
      ? staff_data.append("minutes_tue", staff.minutesTue)
      : staff_data.append("minutes_tue", staffDefault.minutesTue);

    staff.minutesThu
      ? staff_data.append("minutes_thu", staff.minutesThu)
      : staff_data.append("minutes_thu", staffDefault.minutesThu);

    staff.minutesWed
      ? staff_data.append("minutes_wed", staff.minutesWed)
      : staff_data.append("minutes_wed", staffDefault.minutesWed);

    // Dispatch action
    dispatch(updateStaffAction(staffDetails.staff.id, staff_data));
  };

  // Staff competency
  // Add competency
  const addCompetency = () => {
    // Prepare data
    const staff_competency_data = new FormData();

    staffCompetency.comments
      ? staff_competency_data.append("comments", staffCompetency.comments)
      : staff_competency_data.append(
          "comments",
          staffCompetencyDefault.comments
        );

    staffCompetency.competency
      ? staff_competency_data.append("competency", staffCompetency.competency)
      : staff_competency_data.append(
          "competency",
          staffCompetencyDefault.competency
        );

    staffCompetency.level
      ? staff_competency_data.append("level", staffCompetency.level)
      : staff_competency_data.append("level", staffCompetencyDefault.level);

    staff_competency_data.append("staff", id);

    // Dispatch action
    dispatch(createStaffCompetencyAction(staff_competency_data));
  };

  useEffect(() => {
    if (staffCompetencyUpload.success) {
      dispatch(listStaffCompetenciesAction({ staff: id }));

      dispatch(listStaffDetailsAction(id));

      dispatch(calculateRoleFitScoreAction(id));

      dispatch(resetUploadStaffCompetenciesAction());
    }
    // eslint-disable-next-line
  }, [staffCompetencyUpload.success]);

  // Delete competency
  const deleteCompetency = () => {
    // Dispatch action
    dispatch(
      deleteStaffCompetencyAction(staffCompetencyDetails.staff_competency.id)
    );
  };

  // Update competency
  const updateCompetency = () => {
    // Prepare data
    const staff_competency_data = new FormData();

    staffCompetency.comments
      ? staff_competency_data.append("comments", staffCompetency.comments)
      : staff_competency_data.append(
          "comments",
          staffCompetencyDefault.comments
        );

    staffCompetency.competency
      ? staff_competency_data.append("competency", staffCompetency.competency)
      : staff_competency_data.append(
          "competency",
          staffCompetencyDefault.competency
        );

    staffCompetency.level
      ? staff_competency_data.append("level", staffCompetency.level)
      : staff_competency_data.append("level", staffCompetencyDefault.level);

    // Dispatch action
    dispatch(
      updateStaffCompetencyAction(
        staffCompetencyDetails.staff_competency.id,
        staff_competency_data
      )
    );
  };

  // Staff development plan
  // Add development plan
  const addDevelopmentPlan = () => {
    // Prepare data
    const staff_development_plan = new FormData();

    staffDevelopmentPlan.developmentPlan
      ? staff_development_plan.append(
          "development_plan",
          staffDevelopmentPlan.developmentPlan
        )
      : staff_development_plan.append(
          "development_plan",
          staffDevelopmentPlanDefault.developmentPlan
        );

    staffDevelopmentPlan.due
      ? staff_development_plan.append(
          "due",
          getDjangoDate(staffDevelopmentPlan.due)
        )
      : staff_development_plan.append(
          "due",
          getDjangoDate(staffDevelopmentPlanDefault.due)
        );

    staffDevelopmentPlan.name
      ? staff_development_plan.append("name", staffDevelopmentPlan.name)
      : staff_development_plan.append("name", staffDevelopmentPlanDefault.name);

    staff_development_plan.append("staff", id);

    // Dispatch action
    dispatch(createStaffDevelopmentPlanAction(staff_development_plan));
  };

  // Delete development plan
  const deleteDevelopmentPlan = () => {
    // Dispatch action
    dispatch(
      deleteStaffDevelopmentPlanAction(
        staffDevelopmentPlanDetails.staff_development_plan.id
      )
    );
  };

  // Update development plan
  const updateDevelopmentPlan = () => {
    // Prepare data
    const staff_development_plan = new FormData();

    staffDevelopmentPlan.comments
      ? staff_development_plan.append("comments", staffDevelopmentPlan.comments)
      : staff_development_plan.append(
          "comments",
          staffDevelopmentPlanDefault.comments
        );

    staffDevelopmentPlan.developmentPlan
      ? staff_development_plan.append(
          "development_plan",
          staffDevelopmentPlan.developmentPlan
        )
      : staff_development_plan.append(
          "development_plan",
          staffDevelopmentPlanDefault.developmentPlan
        );

    staffDevelopmentPlan.due
      ? staff_development_plan.append(
          "due",
          getDjangoDate(staffDevelopmentPlan.due)
        )
      : staff_development_plan.append(
          "due",
          getDjangoDate(staffDevelopmentPlanDefault.due)
        );

    staffDevelopmentPlan.name
      ? staff_development_plan.append("name", staffDevelopmentPlan.name)
      : staff_development_plan.append("name", staffDevelopmentPlanDefault.name);

    staffDevelopmentPlan.status
      ? staff_development_plan.append("status", staffDevelopmentPlan.status)
      : staff_development_plan.append(
          "status",
          staffDevelopmentPlanDefault.status
        );

    // Dispatch action
    dispatch(
      updateStaffDevelopmentPlanAction(
        staffDevelopmentPlanDetails.staff_development_plan.id,
        staff_development_plan
      )
    );
  };

  // Staff exit action
  const [waitingToUpdateExitKanban, setWaitingToUpdateExitKanban] =
    useState(false);

  // Add exit action
  const addExitAction = () => {
    // Prepare data
    const staff_exit_action = new FormData();

    staffExitAction.action
      ? staff_exit_action.append("action", staffExitAction.action)
      : staff_exit_action.append("action", staffExitActionDefault.action);

    staffExitAction.due
      ? staff_exit_action.append("due", getDjangoDate(staffExitAction.due))
      : staff_exit_action.append(
          "due",
          getDjangoDate(staffExitActionDefault.due)
        );

    staffExitAction.name
      ? staff_exit_action.append("name", staffExitAction.name)
      : staff_exit_action.append("name", staffExitActionDefault.name);

    staffExitAction.owner
      ? staff_exit_action.append("owner", staffExitAction.owner)
      : staff_exit_action.append("owner", staffExitActionDefault.owner);

    staff_exit_action.append("staff", id);

    // Dispatch action
    dispatch(createStaffExitActionAction(staff_exit_action));
  };

  // Delete exit action
  const deleteExitAction = () => {
    // Dispatch action
    dispatch(
      deleteStaffExitActionAction(staffExitActionDetails.staff_exit_action.id)
    );
  };

  // Update exit action
  const updateExitAction = () => {
    // Prepare data
    const staff_exit_action = new FormData();

    staffExitAction.action
      ? staff_exit_action.append("action", staffExitAction.action)
      : staff_exit_action.append("action", staffExitActionDefault.action);

    staffExitAction.comments
      ? staff_exit_action.append("comments", staffExitAction.comments)
      : staff_exit_action.append("comments", staffExitActionDefault.comments);

    staffExitAction.due
      ? staff_exit_action.append("due", getDjangoDate(staffExitAction.due))
      : staff_exit_action.append(
          "due",
          getDjangoDate(staffExitActionDefault.due)
        );

    staffExitAction.name
      ? staff_exit_action.append("name", staffExitAction.name)
      : staff_exit_action.append("name", staffExitActionDefault.name);

    staffExitAction.owner
      ? staff_exit_action.append("owner", staffExitAction.owner)
      : staff_exit_action.append("owner", staffExitActionDefault.owner);

    staffExitAction.status
      ? staff_exit_action.append("status", staffExitAction.status)
      : staff_exit_action.append("status", staffExitActionDefault.status);

    // Dispatch action
    dispatch(
      updateStaffExitActionAction(
        staffExitActionDetails.staff_exit_action.id,
        staff_exit_action
      )
    );
  };

  // Update exit action status
  const updateExitActionStatus = (actionId, actionStatus) => {
    // Prepare data
    const staff_exit_action = new FormData();

    staff_exit_action.append("status", actionStatus);

    // Dispatch action
    dispatch(updateStaffExitActionAction(actionId, staff_exit_action));
  };

  useEffect(() => {
    if (
      staffExitActionCreate.success ||
      staffExitActionDelete.success ||
      staffExitActionUpdate.success
    ) {
      setWaitingToUpdateExitKanban(true);

      dispatch(listStaffDetailsAction(id));

      setStaffExitActionQuery({
        ...staffExitActionQueryDefault,
      });
    }
    // eslint-disable-next-line
  }, [
    staffExitActionCreate.success,
    staffExitActionDelete.success,
    staffExitActionUpdate.success,
  ]);

  useEffect(() => {
    if (staffDetails.success && waitingToUpdateExitKanban) {
      setWaitingToUpdateExitKanban(false);

      setInitialiseExitActionKanban(true);
    }
    // eslint-disable-next-line
  }, [staffDetails.success]);

  // Staff exit upload
  // Add exit upload
  const addExitUpload = () => {
    // Prepare data
    const staff_exit_upload = new FormData();

    staffExitUpload.category
      ? staff_exit_upload.append("category", staffExitUpload.category)
      : staff_exit_upload.append("category", staffExitUploadDefault.category);

    staffExitUpload.description
      ? staff_exit_upload.append("description", staffExitUpload.description)
      : staff_exit_upload.append(
          "description",
          staffExitUploadDefault.description
        );

    if (
      staffExitUpload.file &&
      staffExitUpload.file !== staffExitUploadDetails.staff_exit_upload.file
    ) {
      staff_exit_upload.append("file", staffExitUpload.file);
    } else if (staffExitUpload.file === "") {
      staff_exit_upload.append("file", staffExitUploadDefault.file);
    }

    staffExitUpload.name
      ? staff_exit_upload.append("name", staffExitUpload.name)
      : staff_exit_upload.append("name", staffExitUploadDefault.name);

    staff_exit_upload.append("staff", id);

    // Dispatch action
    dispatch(createStaffExitUploadAction(staff_exit_upload));
  };

  // Delete exit upload
  const deleteExitUpload = () => {
    // Dispatch action
    dispatch(
      deleteStaffExitUploadAction(staffExitUploadDetails.staff_exit_upload.id)
    );
  };

  // Update exit upload
  const updateExitUpload = () => {
    // Prepare data
    const staff_exit_upload = new FormData();

    staffExitUpload.category
      ? staff_exit_upload.append("category", staffExitUpload.category)
      : staff_exit_upload.append("category", staffExitUploadDefault.category);

    staffExitUpload.description
      ? staff_exit_upload.append("description", staffExitUpload.description)
      : staff_exit_upload.append(
          "description",
          staffExitUploadDefault.description
        );

    if (
      staffExitUpload.file &&
      staffExitUpload.file !== staffExitUploadDetails.staff_exit_upload.file
    ) {
      staff_exit_upload.append("file", staffExitUpload.file);
    } else if (staffExitUpload.file === "") {
      staff_exit_upload.append("file", staffExitUploadDefault.file);
    }

    staffExitUpload.name
      ? staff_exit_upload.append("name", staffExitUpload.name)
      : staff_exit_upload.append("name", staffExitUploadDefault.name);

    // Dispatch action
    dispatch(
      updateStaffExitUploadAction(
        staffExitUploadDetails.staff_exit_upload.id,
        staff_exit_upload
      )
    );
  };

  // Staff goal
  // Add goal
  const addGoal = () => {
    // Prepare data
    const staff_goal = new FormData();

    staffGoal.due
      ? staff_goal.append("due", getDjangoDate(staffGoal.due))
      : staff_goal.append("due", getDjangoDate(staffGoalDefault.due));

    staffGoal.goal
      ? staff_goal.append("goal", staffGoal.goal)
      : staff_goal.append("goal", staffGoalDefault.goal);

    staffGoal.name
      ? staff_goal.append("name", staffGoal.name)
      : staff_goal.append("name", staffGoalDefault.name);

    staff_goal.append("staff", id);

    // Dispatch action
    dispatch(createStaffGoalAction(staff_goal));
  };

  // Delete goal
  const deleteGoal = () => {
    // Dispatch action
    dispatch(deleteStaffGoalAction(staffGoalDetails.staff_goal.id));
  };

  // Update goal
  const updateGoal = () => {
    // Prepare data
    const staff_goal = new FormData();

    staffGoal.comments
      ? staff_goal.append("comments", staffGoal.comments)
      : staff_goal.append("comments", staffGoalDefault.comments);

    staffGoal.due
      ? staff_goal.append("due", getDjangoDate(staffGoal.due))
      : staff_goal.append("due", getDjangoDate(staffGoalDefault.due));

    staffGoal.goal
      ? staff_goal.append("goal", staffGoal.goal)
      : staff_goal.append("goal", staffGoalDefault.goal);

    staffGoal.name
      ? staff_goal.append("name", staffGoal.name)
      : staff_goal.append("name", staffGoalDefault.name);

    staffGoal.rating
      ? staff_goal.append("rating", staffGoal.rating)
      : staff_goal.append("rating", staffGoalDefault.rating);

    // Dispatch action
    dispatch(updateStaffGoalAction(staffGoalDetails.staff_goal.id, staff_goal));
  };

  // Staff onboarding action
  const [waitingToUpdateOnboardingKanban, setWaitingToUpdateOnboardingKanban] =
    useState(false);

  // Add onboarding action
  const addOnboardingAction = () => {
    // Prepare data
    const staff_onboarding_action = new FormData();

    staffOnboardingAction.action
      ? staff_onboarding_action.append("action", staffOnboardingAction.action)
      : staff_onboarding_action.append(
          "action",
          staffOnboardingActionDefault.action
        );

    staffOnboardingAction.due
      ? staff_onboarding_action.append(
          "due",
          getDjangoDate(staffOnboardingAction.due)
        )
      : staff_onboarding_action.append(
          "due",
          getDjangoDate(staffOnboardingActionDefault.due)
        );

    staffOnboardingAction.name
      ? staff_onboarding_action.append("name", staffOnboardingAction.name)
      : staff_onboarding_action.append(
          "name",
          staffOnboardingActionDefault.name
        );

    staffOnboardingAction.owner
      ? staff_onboarding_action.append("owner", staffOnboardingAction.owner)
      : staff_onboarding_action.append(
          "owner",
          staffOnboardingActionDefault.owner
        );

    staff_onboarding_action.append("staff", id);

    // Dispatch action
    dispatch(createStaffOnboardingActionAction(staff_onboarding_action));
  };

  // Delete onboarding action
  const deleteOnboardingAction = () => {
    // Dispatch action
    dispatch(
      deleteStaffOnboardingActionAction(
        staffOnboardingActionDetails.staff_onboarding_action.id
      )
    );
  };

  // Update onboarding action
  const updateOnboardingAction = () => {
    // Prepare data
    const staff_onboarding_action = new FormData();

    staffOnboardingAction.action
      ? staff_onboarding_action.append("action", staffOnboardingAction.action)
      : staff_onboarding_action.append(
          "action",
          staffOnboardingActionDefault.action
        );

    staffOnboardingAction.comments
      ? staff_onboarding_action.append(
          "comments",
          staffOnboardingAction.comments
        )
      : staff_onboarding_action.append(
          "comments",
          staffOnboardingActionDefault.comments
        );

    staffOnboardingAction.due
      ? staff_onboarding_action.append(
          "due",
          getDjangoDate(staffOnboardingAction.due)
        )
      : staff_onboarding_action.append(
          "due",
          getDjangoDate(staffOnboardingActionDefault.due)
        );

    staffOnboardingAction.name
      ? staff_onboarding_action.append("name", staffOnboardingAction.name)
      : staff_onboarding_action.append(
          "name",
          staffOnboardingActionDefault.name
        );

    staffOnboardingAction.owner
      ? staff_onboarding_action.append("owner", staffOnboardingAction.owner)
      : staff_onboarding_action.append(
          "owner",
          staffOnboardingActionDefault.owner
        );

    staffOnboardingAction.status
      ? staff_onboarding_action.append("status", staffOnboardingAction.status)
      : staff_onboarding_action.append(
          "status",
          staffOnboardingActionDefault.status
        );

    // Dispatch action
    dispatch(
      updateStaffOnboardingActionAction(
        staffOnboardingActionDetails.staff_onboarding_action.id,
        staff_onboarding_action
      )
    );
  };

  // Update onboarding action status
  const updateOnboardingActionStatus = (actionId, actionStatus) => {
    // Prepare data
    const staff_onboarding_action = new FormData();

    staff_onboarding_action.append("status", actionStatus);

    // Dispatch action
    dispatch(
      updateStaffOnboardingActionAction(actionId, staff_onboarding_action)
    );
  };

  useEffect(() => {
    if (
      staffOnboardingActionCreate.success ||
      staffOnboardingActionDelete.success ||
      staffOnboardingActionUpdate.success
    ) {
      setWaitingToUpdateOnboardingKanban(true);

      dispatch(listStaffDetailsAction(id));

      setStaffOnboardingActionQuery({
        ...staffOnboardingActionQueryDefault,
      });
    }
    // eslint-disable-next-line
  }, [
    staffOnboardingActionCreate.success,
    staffOnboardingActionDelete.success,
    staffOnboardingActionUpdate.success,
  ]);

  useEffect(() => {
    if (staffDetails.success && waitingToUpdateOnboardingKanban) {
      setWaitingToUpdateOnboardingKanban(false);

      setInitialiseOnboardingActionKanban(true);
    }
    // eslint-disable-next-line
  }, [staffDetails.success]);

  // Staff onboarding upload
  // Add onboarding upload
  const addOnboardingUpload = () => {
    // Prepare data
    const staff_onboarding_upload = new FormData();

    staffOnboardingUpload.category
      ? staff_onboarding_upload.append(
          "category",
          staffOnboardingUpload.category
        )
      : staff_onboarding_upload.append(
          "category",
          staffOnboardingUploadDefault.category
        );

    staffOnboardingUpload.description
      ? staff_onboarding_upload.append(
          "description",
          staffOnboardingUpload.description
        )
      : staff_onboarding_upload.append(
          "description",
          staffOnboardingUploadDefault.description
        );

    if (
      staffOnboardingUpload.file &&
      staffOnboardingUpload.file !==
        staffOnboardingUploadDetails.staff_onboarding_upload.file
    ) {
      staff_onboarding_upload.append("file", staffOnboardingUpload.file);
    } else if (staffOnboardingUpload.file === "") {
      staff_onboarding_upload.append("file", staffOnboardingUploadDefault.file);
    }

    staffOnboardingUpload.name
      ? staff_onboarding_upload.append("name", staffOnboardingUpload.name)
      : staff_onboarding_upload.append(
          "name",
          staffOnboardingUploadDefault.name
        );

    staff_onboarding_upload.append("staff", id);

    // Dispatch action
    dispatch(createStaffOnboardingUploadAction(staff_onboarding_upload));
  };

  // Delete onboarding upload
  const deleteOnboardingUpload = () => {
    // Dispatch action
    dispatch(
      deleteStaffOnboardingUploadAction(
        staffOnboardingUploadDetails.staff_onboarding_upload.id
      )
    );
  };

  // Update onboarding upload
  const updateOnboardingUpload = () => {
    // Prepare data
    const staff_onboarding_upload = new FormData();

    staffOnboardingUpload.category
      ? staff_onboarding_upload.append(
          "category",
          staffOnboardingUpload.category
        )
      : staff_onboarding_upload.append(
          "category",
          staffOnboardingUploadDefault.category
        );

    staffOnboardingUpload.description
      ? staff_onboarding_upload.append(
          "description",
          staffOnboardingUpload.description
        )
      : staff_onboarding_upload.append(
          "description",
          staffOnboardingUploadDefault.description
        );

    if (
      staffOnboardingUpload.file &&
      staffOnboardingUpload.file !==
        staffOnboardingUploadDetails.staff_onboarding_upload.file
    ) {
      staff_onboarding_upload.append("file", staffOnboardingUpload.file);
    } else if (staffOnboardingUpload.file === "") {
      staff_onboarding_upload.append("file", staffOnboardingUploadDefault.file);
    }

    staffOnboardingUpload.name
      ? staff_onboarding_upload.append("name", staffOnboardingUpload.name)
      : staff_onboarding_upload.append(
          "name",
          staffOnboardingUploadDefault.name
        );

    // Dispatch action
    dispatch(
      updateStaffOnboardingUploadAction(
        staffOnboardingUploadDetails.staff_onboarding_upload.id,
        staff_onboarding_upload
      )
    );
  };

  // Staff permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const staff_permission = new FormData();

    staffPermission.level
      ? staff_permission.append("level", staffPermission.level)
      : staff_permission.append("level", staffPermissionDefault.level);

    staffPermission.manager
      ? staff_permission.append("manager", staffPermission.manager)
      : staff_permission.append("manager", staffPermissionDefault.manager);

    staff_permission.append("staff", id);

    // Dispatch action
    dispatch(createStaffPermissionAction(staff_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteStaffPermissionAction(staffPermissionDetails.staff_permission.id)
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const staff_permission = new FormData();

    staffPermission.level
      ? staff_permission.append("level", staffPermission.level)
      : staff_permission.append("level", staffPermissionDefault.level);

    staffPermission.manager
      ? staff_permission.append("manager", staffPermission.manager)
      : staff_permission.append("manager", staffPermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateStaffPermissionAction(
        staffPermissionDetails.staff_permission.id,
        staff_permission
      )
    );
  };

  // Timesheet
  // Add timesheet
  const addTimesheet = () => {
    // Prepare data
    const timesheet_data = new FormData();

    timesheet_data.append("organisation", activeOrganisation.id);

    timesheet_data.append("staff", id);

    timesheet.weekLabel
      ? timesheet_data.append("week_label", timesheet.weekLabel)
      : timesheet_data.append("week_label", timesheetDefault.weekLabel);

    // Dispatch action
    dispatch(createTimesheetAction(timesheet_data));
  };

  // Delete timesheet
  const deleteTimesheet = () => {
    // Dispatch action
    dispatch(deleteTimesheetAction(timesheetDetails.timesheet.id));
  };

  // Duplicate timesheet
  useEffect(() => {
    if (timesheetDuplicate.success) {
      dispatch(listTimesheetsAction({ ...timesheetQuery }));

      dispatch(listTimesheetDetailsAction(timesheetDuplicate.timesheet));

      setTimesheetEntryQuery({
        page: 1,
        timesheet: timesheetDuplicate.timesheet,
      });

      dispatch(resetDuplicateTimesheetAction());

      setUpdateTimesheetDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [timesheetDuplicate.success]);

  // Reverse timesheet
  const reverseTimesheet = () => {
    // Prepare data
    const reversal_data = new FormData();

    timesheetReversal.comments
      ? reversal_data.append("comments", timesheetReversal.comments)
      : reversal_data.append("comments", timesheetReversalDefault.comments);

    // Dispatch action
    dispatch(
      reverseTimesheetAction(timesheetDetails.timesheet.id, reversal_data)
    );
  };

  useEffect(() => {
    if (timesheetReverse.success) {
      dispatch(listTimesheetsAction({ ...timesheetQuery }));

      setUpdateTimesheetDrawerIsOpen(false);

      dispatch(resetReverseTimesheetAction());

      resetDrawerVariables();

      AppToaster.show({
        icon: "warning-sign",
        intent: "warning",
        message: "Timesheet reversed.",
      });
    }
    // eslint-disable-next-line
  }, [timesheetReverse.success]);

  // Update timesheet
  useEffect(() => {
    if (timesheetCreate.success) {
      dispatch(listTimesheetDetailsAction(timesheetCreate.timesheet.id));

      setTimesheetEntryQuery({
        timesheet: timesheetCreate.timesheet.id,
      });

      dispatch(resetCreateTimesheetAction());

      setUpdateTimesheetDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [timesheetCreate.success]);

  useEffect(() => {
    if (updateTimesheetDrawerIsOpen) {
      setDecisionRight("Staff timesheet");
    }
    // eslint-disable-next-line
  }, [updateTimesheetDrawerIsOpen]);

  const updateTimesheet = () => {
    // Prepare approval request data
    const approval_request_data = new FormData();

    if (timesheetApprovalRequest.submit) {
      approval_request_data.append(
        "approver",
        timesheetApprovalRequest.approver
      );

      approval_request_data.append("comments", timesheet.comments);

      approval_request_data.append("decision_right", "Staff timesheet");

      approval_request_data.append("item_id", timesheetDetails.timesheet.id);

      approval_request_data.append("organisation", activeOrganisation.id);

      approval_request_data.append("status", "Submitted");
    }

    // Prepare timesheet data
    const timesheet_data = new FormData();

    timesheet.comments
      ? timesheet_data.append("comments", timesheet.comments)
      : timesheet_data.append("comments", timesheetDefault.comments);

    timesheet.weekLabel
      ? timesheet_data.append("week_label", timesheet.weekLabel)
      : timesheet_data.append("week_label", timesheetDefault.weekLabel);

    // Dispatch action
    dispatch(
      updateTimesheetAction(
        timesheetDetails.timesheet.id,
        approval_request_data,
        timesheet_data
      )
    );
  };

  // Timesheet entry
  const [editTimesheetEntryId, setEditTimesheetEntryId] = useState(null);
  const [showNewTimesheetEntryForm, setShowNewTimesheetEntryForm] =
    useState(false);

  // Add timesheet entry
  const addTimesheetEntry = () => {
    // Prepare data
    const timesheet_entry = new FormData();

    timesheetEntry.bookingCode
      ? timesheet_entry.append("booking_code", timesheetEntry.bookingCode)
      : timesheet_entry.append(
          "booking_code",
          timesheetEntryDefault.bookingCode
        );

    timesheetEntry.comments
      ? timesheet_entry.append("comments", timesheetEntry.comments)
      : timesheet_entry.append("comments", timesheetEntryDefault.comments);

    timesheetEntry.engagementLabourExpense
      ? timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntry.engagementLabourExpense
        )
      : timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntryDefault.engagementLabourExpense
        );

    timesheetEntry.minutesFri
      ? timesheet_entry.append("minutes_fri", timesheetEntry.minutesFri)
      : timesheet_entry.append("minutes_fri", timesheetEntryDefault.minutesFri);

    timesheetEntry.minutesMon
      ? timesheet_entry.append("minutes_mon", timesheetEntry.minutesMon)
      : timesheet_entry.append("minutes_mon", timesheetEntryDefault.minutesMon);

    timesheetEntry.minutesSat
      ? timesheet_entry.append("minutes_sat", timesheetEntry.minutesSat)
      : timesheet_entry.append("minutes_sat", timesheetEntryDefault.minutesSat);

    timesheetEntry.minutesSun
      ? timesheet_entry.append("minutes_sun", timesheetEntry.minutesSun)
      : timesheet_entry.append("minutes_sun", timesheetEntryDefault.minutesSun);

    timesheetEntry.minutesTue
      ? timesheet_entry.append("minutes_tue", timesheetEntry.minutesTue)
      : timesheet_entry.append("minutes_tue", timesheetEntryDefault.minutesTue);

    timesheetEntry.minutesThu
      ? timesheet_entry.append("minutes_thu", timesheetEntry.minutesThu)
      : timesheet_entry.append("minutes_thu", timesheetEntryDefault.minutesThu);

    timesheetEntry.minutesWed
      ? timesheet_entry.append("minutes_wed", timesheetEntry.minutesWed)
      : timesheet_entry.append("minutes_wed", timesheetEntryDefault.minutesWed);

    timesheetEntry.task
      ? timesheet_entry.append("task", timesheetEntry.task)
      : timesheet_entry.append("task", timesheetEntryDefault.task);

    timesheet_entry.append("timesheet", timesheetDetails.timesheet.id);

    // Dispatch action
    dispatch(createTimesheetEntryAction(timesheet_entry));
  };

  // Delete timesheet entry
  const deleteTimesheetEntry = () => {
    // Dispatch action
    dispatch(
      deleteTimesheetEntryAction(timesheetEntryDetails.timesheet_entry.id)
    );
  };

  // Update timesheet entry
  const updateTimesheetEntry = () => {
    // Prepare data
    const timesheet_entry = new FormData();

    timesheetEntry.bookingCode
      ? timesheet_entry.append("booking_code", timesheetEntry.bookingCode)
      : timesheet_entry.append(
          "booking_code",
          timesheetEntryDefault.bookingCode
        );

    timesheetEntry.comments
      ? timesheet_entry.append("comments", timesheetEntry.comments)
      : timesheet_entry.append("comments", timesheetEntryDefault.comments);

    timesheetEntry.engagementLabourExpense
      ? timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntry.engagementLabourExpense
        )
      : timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntryDefault.engagementLabourExpense
        );

    timesheetEntry.minutesFri
      ? timesheet_entry.append("minutes_fri", timesheetEntry.minutesFri)
      : timesheet_entry.append("minutes_fri", timesheetEntryDefault.minutesFri);

    timesheetEntry.minutesMon
      ? timesheet_entry.append("minutes_mon", timesheetEntry.minutesMon)
      : timesheet_entry.append("minutes_mon", timesheetEntryDefault.minutesMon);

    timesheetEntry.minutesSat
      ? timesheet_entry.append("minutes_sat", timesheetEntry.minutesSat)
      : timesheet_entry.append("minutes_sat", timesheetEntryDefault.minutesSat);

    timesheetEntry.minutesSun
      ? timesheet_entry.append("minutes_sun", timesheetEntry.minutesSun)
      : timesheet_entry.append("minutes_sun", timesheetEntryDefault.minutesSun);

    timesheetEntry.minutesTue
      ? timesheet_entry.append("minutes_tue", timesheetEntry.minutesTue)
      : timesheet_entry.append("minutes_tue", timesheetEntryDefault.minutesTue);

    timesheetEntry.minutesThu
      ? timesheet_entry.append("minutes_thu", timesheetEntry.minutesThu)
      : timesheet_entry.append("minutes_thu", timesheetEntryDefault.minutesThu);

    timesheetEntry.minutesWed
      ? timesheet_entry.append("minutes_wed", timesheetEntry.minutesWed)
      : timesheet_entry.append("minutes_wed", timesheetEntryDefault.minutesWed);

    timesheetEntry.task
      ? timesheet_entry.append("task", timesheetEntry.task)
      : timesheet_entry.append("task", timesheetEntryDefault.task);

    // Dispatch action
    dispatch(
      updateTimesheetEntryAction(
        timesheetEntryDetails.timesheet_entry.id,
        timesheet_entry
      )
    );
  };

  useEffect(() => {
    if (
      timesheetEntryCreate.success ||
      timesheetEntryDelete.success ||
      timesheetEntryUpdate.success
    ) {
      dispatch(listTimesheetsAction({ ...timesheetQuery }));
    }
    // eslint-disable-next-line
  }, [
    timesheetEntryCreate.success,
    timesheetEntryDelete.success,
    timesheetEntryUpdate.success,
  ]);

  // Refresh role fit score
  const [roleFitScore, setRoleFitScore] = useState(null);

  useEffect(() => {
    if (roleFitScore === null && staffDetails.success) {
      setRoleFitScore(staffDetails.staff.role_fit_score);
    }
    // eslint-disable-next-line
  }, [staffDetails.success]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.role_fit_score !== undefined) {
        setRoleFitScore(lastJsonMessage.message.role_fit_score);
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (staffCalculateRoleFitScore.success) {
      dispatch(resetCalculateRoleFitScoreAction());
    } else if (staffCalculateRoleFitScore.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not calculate the role fit score. Please refresh the page and try again.",
      });
    }
    // eslint-disable-next-line
  }, [staffCalculateRoleFitScore.loading]);

  // Refresh utilisation analysis
  const [utilisationAnalysis, setUtilisationAnalysis] = useState("");
  const [utilisationScore, setUtilisationScore] = useState(null);

  useEffect(() => {
    if (utilisationScore === null && staffDetails.success) {
      setUtilisationAnalysis(staffDetails.staff.utilisation_analysis);

      setUtilisationScore(staffDetails.staff.utilisation_score);
    }
    // eslint-disable-next-line
  }, [staffDetails.success]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.utilisation_analysis) {
        setUtilisationAnalysis(lastJsonMessage.message.utilisation_analysis);
      }

      if (lastJsonMessage.message.utilisation_score !== undefined) {
        setUtilisationScore(lastJsonMessage.message.utilisation_score);
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (staffGenerateUtilisationAnalysis.success) {
      dispatch(resetGenerateUtilisationAnalysisAction());
    } else if (staffGenerateUtilisationAnalysis.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not generate the utilisation analysis. Please refresh the page and try again.",
      });
    }
    // eslint-disable-next-line
  }, [staffGenerateUtilisationAnalysis.loading]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the team member's details</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Role */}
                  <FormDrawerSelectField2
                    error={staffUpdate.error}
                    fieldName="role"
                    helperText="You can choose from your organisation's standard roles"
                    icon="hat"
                    info={
                      <RoleInfo roleId={staff.role} query={roleQueryDefault} />
                    }
                    items={roleList.roles}
                    label="Role"
                    loading={roleList.loading}
                    noOptionOnClick
                    placeholder="Select a role"
                    query={roleQuery.searchString}
                    setQueryObject={setRoleQuery}
                    setQueryVariable="searchString"
                    setValueObject={setStaff}
                    setValueIdVariable="role"
                    setValueNameVariable="roleName"
                    showInfo={staff.role}
                    value={staff.roleName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Location */}
                  <FormDrawerSelectField2
                    error={staffUpdate.error}
                    fieldName="location"
                    helperText="You can choose from your organisation's locations"
                    icon="area-of-interest"
                    info={
                      <LocationInfo
                        locationId={staff.location}
                        query={locationQueryDefault}
                      />
                    }
                    items={locationList.locations}
                    label="Location"
                    loading={locationList.loading}
                    noOptionOnClick
                    placeholder="Select a location"
                    query={locationQuery.searchString}
                    setQueryObject={setLocationQuery}
                    setQueryVariable="searchString"
                    setValueObject={setStaff}
                    setValueIdVariable="location"
                    setValueNameVariable="locationName"
                    showInfo={staff.location}
                    value={staff.locationName}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "30%" }}>
                  {/* Employment type */}
                  <FormDrawerSelectSimpleField3
                    error={staffUpdate.error}
                    fieldName="employment"
                    label="Employment type"
                    options={[
                      { id: 1, icon: "full-circle", value: "Permanent" },
                      {
                        id: 2,
                        icon: "ring",
                        value: "Contract",
                      },
                      { id: 3, icon: "circle", value: "Casual" },
                    ]}
                    setValueObject={setStaff}
                    setValueVariable="employment"
                    value={staff.employment}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "45%",
                  }}
                >
                  {/* Resume */}
                  <FormDrawerFileField2
                    error={staffUpdate.error}
                    fileName="resume"
                    helperText="advaise.app can generate information from this file"
                    label="Resume"
                    onSave={(file) => {
                      const staff_data = new FormData();

                      staff_data.append("resume", file);

                      dispatch(
                        updateStaffAction(staffDetails.staff.id, staff_data)
                      );
                    }}
                    placeholder="Upload the resume"
                    postSave={() => {
                      setStaff((prevState) => {
                        return {
                          ...prevState,
                          resume: staffUpdate.staff.resume,
                        };
                      });

                      dispatch(resetUpdateStaffAction());
                    }}
                    saveSuccess={staffUpdate.success}
                    setValueObject={setStaff}
                    setValueFileVariable="resume"
                    setValueNameVariable="resumeName"
                    valueFile={staff.resume}
                    valueName={staff.resumeName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    paddingTop: "22px",
                    width: "20%",
                  }}
                >
                  {staffDetails.staff.resume && (
                    <GenerateFromFileButton
                      disabled={
                        staff.notes ||
                        !staffDetails.staff.name_alias ||
                        !staff.resume ||
                        staffUpdate.loading
                      }
                      filename={staff.resume}
                      fill
                      objectDescription={staffDetails.staff.name_alias}
                      objectType="staff"
                      onSuccess={(result) => {
                        if (!("notes" in result) || result.notes === "") {
                          AppToaster.show({
                            icon: "warning-sign",
                            intent: "warning",
                            message: "Unable to generate suitable content.",
                          });
                        } else {
                          AppToaster.show({
                            icon: "tick-circle",
                            intent: "success",
                            message: "Generation complete.",
                          });
                        }

                        setStaff((prevState) => {
                          return {
                            ...prevState,
                            notes: result.notes,
                          };
                        });
                      }}
                      text="Generate Notes"
                    />
                  )}
                </div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={staffUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the team member"
                setValueObject={setStaff}
                setValueVariable="notes"
                value={staff.notes}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      staffDetails.staff.user_access_status === "edit" ||
                      staffDetails.staff.user_access_status === "view"
                    }
                    error={staffUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the team member - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setStaff}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={staff.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the staff manager, you will not be
                    able to access it again, unless you are organisation
                    administrator/owner or have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={staffUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Updating a staff record"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={staffDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listStaffDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateStaffAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffUpdate.success}
        saveSuccessMessage="Staff record updated."
        saving={staffUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* Work hours form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                margin: staff.customAvailability ? "0 0 10px 0" : undefined,
                width: "100%",
              }}
            >
              <H5>Specify the team member's work arrangement</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Enable custom work hours */}
                  <FormDrawerSwitchField
                    error={staffUpdate.error}
                    fieldName="custom_availability"
                    helperText="Create a personalised work schedule for this team member"
                    label="Enable custom work hours"
                    setValueObject={setStaff}
                    setValueVariable="customAvailability"
                    value={staff.customAvailability}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}>
                  <Callout intent="primary">
                    If you activate custom work hours, the system will use the
                    settings below to calculate availability and utilisation.
                    Otherwise, the system will use the organisation's default
                    work time per day.
                  </Callout>
                </div>
              </div>
            </Card>

            {staff.customAvailability && (
              <Card style={{ width: "100%" }}>
                <H5>Define the team member's capacity</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <HTMLTable style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <FormDrawerTimeField
                          error={staffUpdate.error}
                          fieldName="minutes_mon"
                          setValueObject={setStaff}
                          setValueVariable="minutesMon"
                          value={staff.minutesMon}
                        />
                      </td>

                      <td>
                        <FormDrawerTimeField
                          error={staffUpdate.error}
                          fieldName="minutes_tue"
                          setValueObject={setStaff}
                          setValueVariable="minutesTue"
                          value={staff.minutesTue}
                        />
                      </td>

                      <td>
                        <FormDrawerTimeField
                          error={staffUpdate.error}
                          fieldName="minutes_wed"
                          setValueObject={setStaff}
                          setValueVariable="minutesWed"
                          value={staff.minutesWed}
                        />
                      </td>

                      <td>
                        <FormDrawerTimeField
                          error={staffUpdate.error}
                          fieldName="minutes_thu"
                          setValueObject={setStaff}
                          setValueVariable="minutesThu"
                          value={staff.minutesThu}
                        />
                      </td>

                      <td>
                        <FormDrawerTimeField
                          error={staffUpdate.error}
                          fieldName="minutes_fri"
                          setValueObject={setStaff}
                          setValueVariable="minutesFri"
                          value={staff.minutesFri}
                        />
                      </td>

                      <td>
                        <FormDrawerTimeField
                          error={staffUpdate.error}
                          fieldName="minutes_sat"
                          setValueObject={setStaff}
                          setValueVariable="minutesSat"
                          value={staff.minutesSat}
                        />
                      </td>

                      <td>
                        <FormDrawerTimeField
                          error={staffUpdate.error}
                          fieldName="minutes_sun"
                          setValueObject={setStaff}
                          setValueVariable="minutesSun"
                          value={staff.minutesSun}
                        />
                      </td>

                      <td>
                        <div style={{ paddingTop: "5px" }}>
                          <strong>
                            {convertMinutesToHoursAndMinutes(
                              staff.minutesFri +
                                staff.minutesMon +
                                staff.minutesSat +
                                staff.minutesSun +
                                staff.minutesThu +
                                staff.minutesTue +
                                staff.minutesWed
                            )}
                          </strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <thead>
                    <tr>
                      <td>Monday</td>

                      <td>Tuesday</td>

                      <td>Wednesday</td>

                      <td>Thursday</td>

                      <td>Friday</td>

                      <td>Saturday</td>

                      <td>Sunday</td>

                      <td>Total</td>
                    </tr>
                  </thead>
                </HTMLTable>
              </Card>
            )}
          </div>
        }
        error={staffUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Set work hours"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Setting work hours"
        icon="edit"
        isOpen={updateWorkHoursDrawerIsOpen}
        loading={staffDetails.loading}
        onSave={updateWorkHours}
        refreshList={() => {
          dispatch(listStaffDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateStaffAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffUpdate.success}
        saveSuccessMessage="Staff record updated."
        saving={staffUpdate.loading}
        setIsOpen={setUpdateWorkHoursDrawerIsOpen}
        size="medium"
        title="Set work hours"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={staffPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setStaffPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={staffPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={staffPermissionCreate.error}
              fieldName="level"
              intent={
                staffPermission.level === "Edit"
                  ? "success"
                  : staffPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setStaffPermission}
              setValueVariable="level"
              value={staffPermission.level}
            />
          </Card>
        }
        error={staffPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listStaffPermissionsAction({ staff: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={staffPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={staffPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setStaffPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={staffPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={staffPermissionUpdate.error}
              fieldName="level"
              intent={
                staffPermission.level === "Edit"
                  ? "success"
                  : staffPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setStaffPermission}
              setValueVariable="level"
              value={staffPermission.level}
            />
          </Card>
        }
        deleteSuccess={staffPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={staffPermissionDelete.loading}
        error={staffPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Contacts"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={staffPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listStaffPermissionsAction({ ...staffPermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffPermissionAction());

          dispatch(resetUpdateStaffPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={staffPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New onboarding action form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the action</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={staffOnboardingActionCreate.error}
                  fieldName="name"
                  icon="take-action"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the action a title"
                  setValueObject={setStaffOnboardingAction}
                  setValueVariable="name"
                  value={staffOnboardingAction.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Action */}
            <FormDrawerTextAreaField3
              error={staffOnboardingActionCreate.error}
              fieldName="action"
              height="100px"
              label="Action"
              labelInfo="(required)"
              placeholder="Enter the action"
              setValueObject={setStaffOnboardingAction}
              setValueVariable="action"
              value={staffOnboardingAction.action}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={staffOnboardingActionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for completing the action"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setStaffOnboardingAction}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={staffOnboardingAction.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={staffOnboardingActionCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the action due date"
                  setValueObject={setStaffOnboardingAction}
                  setValueVariable="due"
                  value={staffOnboardingAction.due}
                />
              </div>
            </div>
          </Card>
        }
        error={staffOnboardingActionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create onboarding action"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing onboarding actions"
        icon="edit"
        isOpen={addOnboardingActionDrawerIsOpen}
        onSave={addOnboardingAction}
        refreshList={() => {
          dispatch(listStaffOnboardingActionsAction({ staff: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffOnboardingActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffOnboardingActionCreate.success}
        saveSuccessMessage="Action created."
        saving={staffOnboardingActionCreate.loading}
        setIsOpen={setAddOnboardingActionDrawerIsOpen}
        title="Create onboarding action"
      />

      {/* Existing onboarding action form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the action</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={staffOnboardingActionUpdate.error}
                    fieldName="name"
                    icon="shield"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the action a title"
                    setValueObject={setStaffOnboardingAction}
                    setValueVariable="name"
                    value={staffOnboardingAction.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Action */}
              <FormDrawerTextAreaField3
                error={staffOnboardingActionUpdate.error}
                fieldName="action"
                height="100px"
                label="Action"
                labelInfo="(required)"
                placeholder="Enter the action"
                setValueObject={setStaffOnboardingAction}
                setValueVariable="action"
                value={staffOnboardingAction.action}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={staffOnboardingActionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setStaffOnboardingAction}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={staffOnboardingAction.ownerName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                >
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={staffOnboardingActionUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the action due date"
                    setValueObject={setStaffOnboardingAction}
                    setValueVariable="due"
                    value={staffOnboardingAction.due}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  tag={
                    staffOnboardingActionDetails.staff_onboarding_action
                      .owner_done
                      ? "Ready to close"
                      : "Working on it"
                  }
                  tagIntent={
                    staffOnboardingActionDetails.staff_onboarding_action
                      .owner_done
                      ? "success"
                      : "primary"
                  }
                  text={
                    staffOnboardingActionDetails.staff_onboarding_action
                      .owner_comments
                  }
                  title="Update from action owner"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={staffOnboardingActionUpdate.error}
                    fieldName="status"
                    intent={
                      staffOnboardingAction.status === "Completed"
                        ? "success"
                        : staffOnboardingAction.status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Completed",
                      },
                      {
                        id: 2,
                        icon: "refresh",
                        value: "In-progress",
                      },
                      {
                        id: 3,
                        icon: "remove",
                        value: "On-hold",
                      },
                    ]}
                    setValueObject={setStaffOnboardingAction}
                    setValueVariable="status"
                    value={staffOnboardingAction.status}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                ></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={staffOnboardingActionUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setStaffOnboardingAction}
                setValueVariable="comments"
                value={staffOnboardingAction.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={staffOnboardingActionDelete.success}
        deleteSuccessMessage="Action deleted."
        deleting={staffOnboardingActionDelete.loading}
        error={staffOnboardingActionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update onboarding action"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing onboarding actions"
        icon="edit"
        isOpen={updateOnboardingActionDrawerIsOpen}
        loading={staffOnboardingActionDetails.loading}
        onDelete={deleteOnboardingAction}
        onSave={updateOnboardingAction}
        refreshList={() => {
          dispatch(
            listStaffOnboardingActionsAction({ ...staffOnboardingActionQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffOnboardingActionAction());

          dispatch(resetUpdateStaffOnboardingActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffOnboardingActionUpdate.success}
        saveSuccessMessage="Action updated."
        saving={staffOnboardingActionUpdate.loading}
        setIsOpen={setUpdateOnboardingActionDrawerIsOpen}
        title="Update onboarding action"
      />

      {/* New onboarding upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the upload details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  error={staffOnboardingUploadCreate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setStaffOnboardingUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={staffOnboardingUpload.file}
                  valueName={staffOnboardingUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  error={staffOnboardingUploadCreate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setStaffOnboardingUpload}
                  setValueVariable="name"
                  value={staffOnboardingUpload.name}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={staffOnboardingUploadCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    { id: 1, icon: "following", value: "Background Check" },
                    { id: 2, icon: "saved", value: "Education Certificate" },
                    { id: 3, icon: "id-number", value: "Identity Document" },
                    { id: 4, icon: "draw", value: "Declaration" },
                    {
                      id: 5,
                      icon: "manually-entered-data",
                      value: "Employment Contract",
                    },
                    { id: 6, icon: "new-person", value: "Reference" },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setStaffOnboardingUpload}
                  setValueVariable="category"
                  value={staffOnboardingUpload.category}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={staffOnboardingUploadCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarise the file contents"
              setValueObject={setStaffOnboardingUpload}
              setValueVariable="description"
              value={staffOnboardingUpload.description}
            />
          </Card>
        }
        error={staffOnboardingUploadCreate.error}
        icon="edit"
        isOpen={addOnboardingUploadDrawerIsOpen}
        onSave={addOnboardingUpload}
        refreshList={() => {
          dispatch(listStaffOnboardingUploadsAction({ staff: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffOnboardingUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffOnboardingUploadCreate.success}
        saveSuccessMessage="File uploaded."
        saving={staffOnboardingUploadCreate.loading}
        setIsOpen={setAddOnboardingUploadDrawerIsOpen}
        title="Upload file"
      />

      {/* Existing onboarding upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the file details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  disabled={staffDetails.staff.user_access_status === "view"}
                  error={staffOnboardingUploadUpdate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setStaffOnboardingUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={staffOnboardingUpload.file}
                  valueName={staffOnboardingUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  disabled={staffDetails.staff.user_access_status === "view"}
                  error={staffOnboardingUploadUpdate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setStaffOnboardingUpload}
                  setValueVariable="name"
                  value={staffOnboardingUpload.name}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={staffOnboardingUploadUpdate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    { id: 1, icon: "following", value: "Background Check" },
                    { id: 2, icon: "saved", value: "Education Certificate" },
                    { id: 3, icon: "id-number", value: "Identity Document" },
                    { id: 4, icon: "draw", value: "Declaration" },
                    {
                      id: 5,
                      icon: "manually-entered-data",
                      value: "Employment Contract",
                    },
                    { id: 6, icon: "new-person", value: "Reference" },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setStaffOnboardingUpload}
                  setValueVariable="category"
                  value={staffOnboardingUpload.category}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              disabled={staffDetails.staff.user_access_status === "view"}
              error={staffOnboardingUploadUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarise the file contents"
              setValueObject={setStaffOnboardingUpload}
              setValueVariable="description"
              value={staffOnboardingUpload.description}
            />

            <Button
              disabled={!staffOnboardingUpload.file}
              icon="download"
              intent="primary"
              onClick={() => {
                saveAs(
                  staffOnboardingUpload.file,
                  staffOnboardingUpload.fileName
                );
              }}
              text="Download"
            />
          </Card>
        }
        deleteSuccess={staffOnboardingUploadDelete.success}
        deleteSuccessMessage="File deleted."
        deleting={staffOnboardingUploadDelete.loading}
        disabled={staffDetails.staff.user_access_status === "view"}
        error={staffOnboardingUploadUpdate.error}
        icon="edit"
        isOpen={updateOnboardingUploadDrawerIsOpen}
        loading={staffOnboardingUploadDetails.loading}
        onDelete={deleteOnboardingUpload}
        onSave={updateOnboardingUpload}
        refreshList={() => {
          dispatch(
            listStaffOnboardingUploadsAction({ ...staffOnboardingUploadQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffOnboardingUploadAction());

          dispatch(resetUpdateStaffOnboardingUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffOnboardingUploadUpdate.success}
        saveSuccessMessage="File updated."
        saving={staffOnboardingUploadUpdate.loading}
        setIsOpen={setUpdateOnboardingUploadDrawerIsOpen}
        title="Update file"
      />

      {/* New goal form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the goal</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={staffGoalCreate.error}
                  fieldName="name"
                  icon="locate"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the goal a title"
                  setValueObject={setStaffGoal}
                  setValueVariable="name"
                  value={staffGoal.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={staffGoalCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  maxDate={three_years_from_now}
                  minDate={a_year_ago}
                  placeholder="Enter the goal due date"
                  setValueObject={setStaffGoal}
                  setValueVariable="due"
                  value={staffGoal.due}
                />
              </div>
            </div>

            {/* Goal */}
            <FormDrawerTextAreaField3
              error={staffGoalCreate.error}
              fieldName="goal"
              label="Goal"
              labelInfo="(required)"
              placeholder="Enter the goal"
              setValueObject={setStaffGoal}
              setValueVariable="goal"
              value={staffGoal.goal}
            />
          </Card>
        }
        error={staffGoalCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create goal"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing goals"
        icon="edit"
        isOpen={addGoalDrawerIsOpen}
        onSave={addGoal}
        refreshList={() => {
          dispatch(listStaffGoalsAction({ staff: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffGoalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffGoalCreate.success}
        saveSuccessMessage="Goal created."
        saving={staffGoalCreate.loading}
        setIsOpen={setAddGoalDrawerIsOpen}
        title="Create goal"
      />

      {/* Existing goal form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the goal</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={staffGoalUpdate.error}
                    fieldName="name"
                    icon="locate"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the goal a title"
                    setValueObject={setStaffGoal}
                    setValueVariable="name"
                    value={staffGoal.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={staffGoalUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    maxDate={three_years_from_now}
                    minDate={a_year_ago}
                    placeholder="Enter the goal due date"
                    setValueObject={setStaffGoal}
                    setValueVariable="due"
                    value={staffGoal.due}
                  />
                </div>
              </div>

              {/* Goal */}
              <FormDrawerTextAreaField3
                error={staffGoalUpdate.error}
                fieldName="goal"
                label="Goal"
                labelInfo="(required)"
                placeholder="Enter the goal"
                setValueObject={setStaffGoal}
                setValueVariable="goal"
                value={staffGoal.goal}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  text={staffGoalDetails.staff_goal.staff_comments}
                  title="Staff update"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Rating */}
                  <FormDrawerSelectSimpleField3
                    error={staffGoalUpdate.error}
                    fieldName="rating"
                    intent={
                      staffGoal.rating === "Met"
                        ? "warning"
                        : staffGoal.rating === "Exceeded"
                        ? "success"
                        : staffGoal.rating === "Not met"
                        ? "danger"
                        : "primary"
                    }
                    label="Rating"
                    options={[
                      { id: 1, icon: "arrow-right", value: "Met" },
                      { id: 2, icon: "arrow-up", value: "Exceeded" },
                      { id: 3, icon: "arrow-down", value: "Not met" },
                      { id: 4, icon: "remove", value: "Not rated" },
                    ]}
                    setValueObject={setStaffGoal}
                    setValueVariable="rating"
                    value={staffGoal.rating}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={staffGoalUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setStaffGoal}
                setValueVariable="comments"
                value={staffGoal.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={staffGoalDelete.success}
        deleteSuccessMessage="Goal deleted."
        deleting={staffGoalDelete.loading}
        error={staffGoalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update goal"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing goals"
        icon="edit"
        isOpen={updateGoalDrawerIsOpen}
        loading={staffGoalDetails.loading}
        onDelete={deleteGoal}
        onSave={updateGoal}
        refreshList={() => {
          dispatch(listStaffGoalsAction({ ...staffGoalQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffGoalAction());

          dispatch(resetUpdateStaffGoalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffGoalUpdate.success}
        saveSuccessMessage="Goal updated."
        saving={staffGoalUpdate.loading}
        setIsOpen={setUpdateGoalDrawerIsOpen}
        title="Update goal"
      />

      {/* New competency form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ margin: "0 0 15px 0" }}>
            <H5>Enter the competency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Competency */}
                <FormDrawerSelectField2
                  error={staffCompetencyCreate.error}
                  fieldName="competency"
                  helperText="You can select from your organisation's competencies"
                  icon="learning"
                  info={
                    <CompetencyInfo
                      competencyId={staffCompetency.competency}
                      query={competencyQueryDefault}
                    />
                  }
                  items={competencyList.competencies}
                  label="Competency"
                  loading={competencyList.loading}
                  placeholder="Select a competency"
                  query={competencyQuery.searchString}
                  setQueryObject={setCompetencyQuery}
                  setQueryVariable="searchString"
                  setValueObject={setStaffCompetency}
                  setValueIdVariable="competency"
                  setValueNameVariable="competencyName"
                  showInfo={staffCompetency.competency}
                  value={staffCompetency.competencyName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level */}
                <FormDrawerSliderField
                  error={staffCompetencyCreate.error}
                  fieldName="level"
                  intent="success"
                  label="Level"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Beginner";
                      case 5:
                        return "Expert";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setStaffCompetency}
                  setValueVariable="level"
                  value={staffCompetency.level}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={staffCompetencyCreate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Describe relevant experience"
              setValueObject={setStaffCompetency}
              setValueVariable="comments"
              value={staffCompetency.comments}
            />
          </Card>
        }
        error={staffCompetencyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add competency"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Defining competencies"
        icon="edit"
        isOpen={addCompetencyDrawerIsOpen}
        onSave={addCompetency}
        refreshList={() => {
          dispatch(listStaffCompetenciesAction({ staff: id }));

          dispatch(listStaffDetailsAction(id));

          dispatch(calculateRoleFitScoreAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffCompetencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffCompetencyCreate.success}
        saveSuccessMessage="Competency created."
        saving={staffCompetencyCreate.loading}
        setIsOpen={setAddCompetencyDrawerIsOpen}
        title="Add competency"
      />

      {/* Existing competency form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ margin: "0 0 15px 0" }}>
            <H5>Enter the competency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Competency */}
                <FormDrawerSelectField2
                  error={staffCompetencyUpdate.error}
                  fieldName="competency"
                  helperText="You can select from your organisation's competencies"
                  icon="learning"
                  info={
                    <CompetencyInfo
                      competencyId={staffCompetency.competency}
                      query={competencyQueryDefault}
                    />
                  }
                  items={competencyList.competencies}
                  label="Competency"
                  loading={competencyList.loading}
                  placeholder="Select a competency"
                  query={competencyQuery.searchString}
                  setQueryObject={setCompetencyQuery}
                  setQueryVariable="searchString"
                  setValueObject={setStaffCompetency}
                  setValueIdVariable="competency"
                  setValueNameVariable="competencyName"
                  showInfo={staffCompetency.competency}
                  value={staffCompetency.competencyName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level */}
                <FormDrawerSliderField
                  error={staffCompetencyUpdate.error}
                  fieldName="level"
                  intent="success"
                  label="Level"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Beginner";
                      case 5:
                        return "Expert";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setStaffCompetency}
                  setValueVariable="level"
                  value={staffCompetency.level}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={staffCompetencyUpdate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Describe relevant experience"
              setValueObject={setStaffCompetency}
              setValueVariable="comments"
              value={staffCompetency.comments}
            />
          </Card>
        }
        deleteSuccess={staffCompetencyDelete.success}
        deleteSuccessMessage="Competency deleted."
        deleting={staffCompetencyDelete.loading}
        error={staffCompetencyUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update competency"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Defining competencies"
        icon="edit"
        isOpen={updateCompetencyDrawerIsOpen}
        loading={staffCompetencyDetails.loading}
        onDelete={deleteCompetency}
        onSave={updateCompetency}
        refreshList={() => {
          dispatch(listStaffCompetenciesAction({ ...staffCompetencyQuery }));

          dispatch(listStaffDetailsAction(id));

          dispatch(calculateRoleFitScoreAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffCompetencyAction());

          dispatch(resetUpdateStaffCompetencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffCompetencyUpdate.success}
        saveSuccessMessage="Competency updated."
        saving={staffCompetencyUpdate.loading}
        setIsOpen={setUpdateCompetencyDrawerIsOpen}
        title="Update competency"
      />

      {/* New development plan form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the development plan</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={staffDevelopmentPlanCreate.error}
                  fieldName="name"
                  icon="learning"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the development plan a title"
                  setValueObject={setStaffDevelopmentPlan}
                  setValueVariable="name"
                  value={staffDevelopmentPlan.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={staffDevelopmentPlanCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  labelInfo="(required)"
                  maxDate={three_years_from_now}
                  minDate={a_year_ago}
                  placeholder="Enter the development plan due date"
                  setValueObject={setStaffDevelopmentPlan}
                  setValueVariable="due"
                  value={staffDevelopmentPlan.due}
                />
              </div>
            </div>

            {/* Development plan */}
            <FormDrawerTextAreaField3
              error={staffDevelopmentPlanCreate.error}
              fieldName="development_plan"
              label="Development plan"
              labelInfo="(required)"
              placeholder="Enter the development plan"
              setValueObject={setStaffDevelopmentPlan}
              setValueVariable="developmentPlan"
              value={staffDevelopmentPlan.developmentPlan}
            />
          </Card>
        }
        error={staffDevelopmentPlanCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create development plan"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Developing staff"
        icon="edit"
        isOpen={addDevelopmentPlanDrawerIsOpen}
        onSave={addDevelopmentPlan}
        refreshList={() => {
          dispatch(listStaffDevelopmentPlansAction({ staff: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffDevelopmentPlanAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffDevelopmentPlanCreate.success}
        saveSuccessMessage="Development plan created."
        saving={staffDevelopmentPlanCreate.loading}
        setIsOpen={setAddDevelopmentPlanDrawerIsOpen}
        title="Create development plan"
      />

      {/* Existing development plan form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the development plan</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={staffDevelopmentPlanUpdate.error}
                    fieldName="name"
                    icon="learning"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the development plan a title"
                    setValueObject={setStaffDevelopmentPlan}
                    setValueVariable="name"
                    value={staffDevelopmentPlan.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={staffDevelopmentPlanUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    labelInfo="(required)"
                    maxDate={three_years_from_now}
                    minDate={a_year_ago}
                    placeholder="Enter the development plan due date"
                    setValueObject={setStaffDevelopmentPlan}
                    setValueVariable="due"
                    value={staffDevelopmentPlan.due}
                  />
                </div>
              </div>

              {/* Development plan */}
              <FormDrawerTextAreaField3
                error={staffDevelopmentPlanUpdate.error}
                fieldName="development_plan"
                label="Development plan"
                labelInfo="(required)"
                placeholder="Enter the development plan"
                setValueObject={setStaffDevelopmentPlan}
                setValueVariable="developmentPlan"
                value={staffDevelopmentPlan.developmentPlan}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  text={
                    staffDevelopmentPlanDetails.staff_development_plan
                      .staff_comments
                  }
                  title="Staff update"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={staffDevelopmentPlanUpdate.error}
                    fieldName="status"
                    intent={
                      staffDevelopmentPlan.status === "Completed"
                        ? "success"
                        : staffDevelopmentPlan.status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "confirm", value: "Completed" },
                      { id: 2, icon: "refresh", value: "In-progress" },
                      { id: 3, icon: "remove", value: "On-hold" },
                    ]}
                    setValueObject={setStaffDevelopmentPlan}
                    setValueVariable="status"
                    value={staffDevelopmentPlan.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={staffDevelopmentPlanUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setStaffDevelopmentPlan}
                setValueVariable="comments"
                value={staffDevelopmentPlan.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={staffDevelopmentPlanDelete.success}
        deleteSuccessMessage="Development plan deleted."
        deleting={staffDevelopmentPlanDelete.loading}
        error={staffDevelopmentPlanUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update development plan"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Developing staff"
        icon="edit"
        isOpen={updateDevelopmentPlanDrawerIsOpen}
        loading={staffDevelopmentPlanDetails.loading}
        onDelete={deleteDevelopmentPlan}
        onSave={updateDevelopmentPlan}
        refreshList={() => {
          dispatch(
            listStaffDevelopmentPlansAction({ ...staffDevelopmentPlanQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffDevelopmentPlanAction());

          dispatch(resetUpdateStaffDevelopmentPlanAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffDevelopmentPlanUpdate.success}
        saveSuccessMessage="Development plan updated."
        saving={staffDevelopmentPlanUpdate.loading}
        setIsOpen={setUpdateDevelopmentPlanDrawerIsOpen}
        title="Update development plan"
      />

      {/* New booking form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the booking</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Booking code */}
                    <FormDrawerSelectField2
                      error={bookingCreate.error}
                      fieldName="booking_code"
                      icon="barcode"
                      info={
                        <BookingCodeInfo
                          bookingCodeId={booking.bookingCode}
                          query={bookingCodeQueryDefault}
                        />
                      }
                      items={bookingCodeList.booking_codes}
                      label="Booking code"
                      labelInfo="(required)"
                      loading={bookingCodeList.loading}
                      placeholder="Select a booking code"
                      query={bookingCodeQuery.searchString}
                      setQueryObject={setBookingCodeQuery}
                      setQueryVariable="searchString"
                      setValueObject={setBooking}
                      setValueIdVariable="bookingCode"
                      setValueNameVariable="bookingCodeName"
                      showInfo={booking.bookingCode}
                      value={booking.bookingCodeName}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}>
                    {/* Booking type */}
                    <FormDrawerSelectSimpleField3
                      error={bookingCreate.error}
                      fieldName="type"
                      helperText={
                        booking.type === "Confirmed"
                          ? "You have confirmed the booking"
                          : "You are waiting to confirm the booking"
                      }
                      intent={
                        booking.type === "Confirmed" ? "primary" : "warning"
                      }
                      label="Booking type"
                      options={[
                        { id: 1, icon: "circle", value: "Confirmed" },
                        { id: 2, icon: "remove", value: "Tentative" },
                      ]}
                      setValueObject={setBooking}
                      setValueVariable="type"
                      value={booking.type}
                    />
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "35%" }}>
                    {/* Start date */}
                    <FormDrawerDateField2
                      error={bookingCreate.error}
                      fieldName="start"
                      icon="calendar"
                      label="Start date"
                      labelInfo="(required)"
                      maxDate={three_years_from_now}
                      minDate={a_year_ago}
                      placeholder="Enter the booking start date"
                      setValueObject={setBooking}
                      setValueVariable="start"
                      value={booking.start}
                    />
                  </div>

                  <div style={{ margin: "0 10px 0 10px", width: "35%" }}>
                    {/* End date */}
                    <FormDrawerDateField2
                      error={bookingCreate.error}
                      fieldName="end"
                      icon="calendar"
                      label="End date"
                      labelInfo="(required)"
                      maxDate={three_years_from_now}
                      minDate={a_year_ago}
                      placeholder="Enter the booking end date"
                      setValueObject={setBooking}
                      setValueVariable="end"
                      value={booking.end}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "30%" }}>
                    {/* Effort */}
                    <FormDrawerDurationField
                      error={bookingCreate.error}
                      fieldName="minutes"
                      helperText="Expected effort in hours"
                      icon="stopwatch"
                      label="Effort"
                      min={0.1}
                      placeholder="Enter the number of hours"
                      setValueObject={setBooking}
                      setValueVariable="minutes"
                      value={booking.minutes}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Comments */}
                <FormDrawerTextAreaField3
                  error={bookingCreate.error}
                  fieldName="comments"
                  height="150px"
                  label="Comments"
                  placeholder="Enter any notes you want to capture"
                  setValueObject={setBooking}
                  setValueVariable="comments"
                  value={booking.comments}
                />
              </div>
            </div>

            <FormDrawerBookingDaysField2
              bookingProfile={staffDetails.staff.booking_profile}
              setValueObject={setBooking}
              staff={id}
              valueObject={booking}
            />
          </Card>
        }
        error={bookingCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create booking"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing staff bookings"
        icon="edit"
        isOpen={addBookingDrawerIsOpen}
        onSave={addBooking}
        refreshList={() => {
          dispatch(
            listBookingsAction({
              bookingProfile: "week",
              organisation: activeOrganisation.id,
              staff: id,
            })
          );

          dispatch(listStaffDetailsAction(id));

          dispatch(generateUtilisationAnalysisAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateBookingAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={bookingCreate.success}
        saveSuccessMessage="Booking created."
        saving={bookingCreate.loading}
        setIsOpen={setAddBookingDrawerIsOpen}
        size="large"
        title="Create booking"
      />

      {/* Existing booking form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the booking</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Booking code */}
                    <FormDrawerSelectField2
                      error={bookingUpdate.error}
                      fieldName="booking_code"
                      icon="barcode"
                      info={
                        <BookingCodeInfo
                          bookingCodeId={booking.bookingCode}
                          query={bookingCodeQueryDefault}
                        />
                      }
                      items={bookingCodeList.booking_codes}
                      label="Booking code"
                      labelInfo="(required)"
                      loading={bookingCodeList.loading}
                      placeholder="Select a booking code"
                      query={bookingCodeQuery.searchString}
                      setQueryObject={setBookingCodeQuery}
                      setQueryVariable="searchString"
                      setValueObject={setBooking}
                      setValueIdVariable="bookingCode"
                      setValueNameVariable="bookingCodeName"
                      showInfo={booking.bookingCode}
                      value={booking.bookingCodeName}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}>
                    {/* Booking type */}
                    <FormDrawerSelectSimpleField3
                      error={bookingUpdate.error}
                      fieldName="type"
                      helperText={
                        booking.type === "Confirmed"
                          ? "You have confirmed the booking"
                          : "You are waiting to confirm the booking"
                      }
                      intent={
                        booking.type === "Confirmed" ? "primary" : "warning"
                      }
                      label="Booking type"
                      options={[
                        { id: 1, icon: "circle", value: "Confirmed" },
                        { id: 2, icon: "remove", value: "Tentative" },
                      ]}
                      setValueObject={setBooking}
                      setValueVariable="type"
                      value={booking.type}
                    />
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "35%" }}>
                    {/* Start date */}
                    <FormDrawerDateField2
                      error={bookingUpdate.error}
                      fieldName="start"
                      icon="calendar"
                      label="Start date"
                      labelInfo="(required)"
                      maxDate={three_years_from_now}
                      minDate={a_year_ago}
                      placeholder="Enter the booking start date"
                      setValueObject={setBooking}
                      setValueVariable="start"
                      value={booking.start}
                    />
                  </div>

                  <div style={{ margin: "0 10px 0 10px", width: "35%" }}>
                    {/* End date */}
                    <FormDrawerDateField2
                      error={bookingUpdate.error}
                      fieldName="end"
                      icon="calendar"
                      label="End date"
                      labelInfo="(required)"
                      maxDate={three_years_from_now}
                      minDate={a_year_ago}
                      placeholder="Enter the booking end date"
                      setValueObject={setBooking}
                      setValueVariable="end"
                      value={booking.end}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "30%" }}>
                    {/* Effort */}
                    <FormDrawerDurationField
                      error={bookingUpdate.error}
                      fieldName="minutes"
                      helperText="Expected effort in hours"
                      icon="stopwatch"
                      label="Effort"
                      min={0.1}
                      placeholder="Enter the number of hours"
                      setValueObject={setBooking}
                      setValueVariable="minutes"
                      value={booking.minutes}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Comments */}
                <FormDrawerTextAreaField3
                  error={bookingUpdate.error}
                  fieldName="comments"
                  height="150px"
                  label="Comments"
                  placeholder="Enter any notes you want to capture"
                  setValueObject={setBooking}
                  setValueVariable="comments"
                  value={booking.comments}
                />
              </div>
            </div>

            <FormDrawerBookingDaysField2
              bookingProfile={staffDetails.staff.booking_profile}
              setValueObject={setBooking}
              staff={id}
              valueObject={booking}
            />
          </Card>
        }
        cannotDelete={bookingDetails.booking.cannot_delete}
        deleteSuccess={bookingDelete.success}
        deleteSuccessMessage="Booking deleted."
        deleting={bookingDelete.loading}
        error={bookingUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update booking"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing staff bookings"
        icon="edit"
        isOpen={updateBookingDrawerIsOpen}
        loading={bookingDetails.loading}
        onDelete={deleteBooking}
        onSave={updateBooking}
        refreshList={() => {
          dispatch(listBookingsAction({ ...bookingQuery }));

          dispatch(listStaffDetailsAction(id));

          dispatch(generateUtilisationAnalysisAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteBookingAction());

          dispatch(resetUpdateBookingAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={bookingUpdate.success}
        saveSuccessMessage="Booking updated."
        saving={bookingUpdate.loading}
        setIsOpen={setUpdateBookingDrawerIsOpen}
        size="large"
        title="Update booking"
      />

      {/* New timesheet form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Select the week</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Week */}
            <div style={{ margin: "0 0 15px 0" }}>
              <div style={{ marginBottom: "5px" }}>Week</div>

              <SelectField
                options={weekOptions}
                setValueObject={setTimesheet}
                setValueVariable="weekLabel"
                value={timesheet.weekLabel}
              />
            </div>
          </Card>
        }
        error={timesheetCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create timesheet"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a timesheet"
        icon="edit"
        isOpen={addTimesheetDrawerIsOpen}
        onSave={addTimesheet}
        refreshList={() => {
          dispatch(
            listTimesheetsAction({
              organisation: activeOrganisation.id,
              user: userLogin.user,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={timesheetCreate.success}
        saveSuccessMessage="Timesheet created."
        saving={timesheetCreate.loading}
        setIsOpen={setAddTimesheetDrawerIsOpen}
        size="small"
        title="Create timesheet"
      />

      {/* Existing timesheet form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            {timesheetDetails.timesheet.can_be_reversed
              ? timesheetDetails.timesheet.can_be_reversed
                  .has_invoiced_bookings === true &&
                timesheetDetails.timesheet.can_be_reversed
                  .user_is_admin_or_owner === true && (
                  <Callout intent="primary" style={{ margin: "0 0 10px 0" }}>
                    This timesheet cannot be reversed because it contains
                    bookings that have been invoiced.
                  </Callout>
                )
              : undefined}

            <Card
              style={{
                margin: timesheetDetails.timesheet.can_be_reversed
                  ? timesheetDetails.timesheet.can_be_reversed.result === true
                    ? "0 0 10px 0"
                    : undefined
                  : undefined,
                width: "100%",
              }}
            >
              <H5>Enter the timesheet details</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {timesheetDetails.timesheet.status === "Reversed" && (
                <Callout intent="warning" style={{ margin: "0 0 20px 0" }}>
                  This timesheet was reversed by{" "}
                  <strong>{timesheetDetails.timesheet.reversed_by}</strong> on{" "}
                  {new Date(
                    timesheetDetails.timesheet.reversed_on
                  ).toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                  {timesheetDetails.timesheet.reversed_comments
                    ? ", with the following comments: " +
                      timesheetDetails.timesheet.reversed_comments
                    : "."}
                </Callout>
              )}

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Week */}
                      <div style={{ margin: "0 0 15px 0" }}>
                        <div style={{ marginBottom: "5px" }}>Week</div>

                        <SelectField
                          disabled={
                            timesheetDetails.timesheet.status !== "Draft"
                          }
                          options={weekOptions}
                          setValueObject={setTimesheet}
                          setValueVariable="weekLabel"
                          value={timesheet.weekLabel}
                        />
                      </div>
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}>
                      {/* Status */}
                      <div style={{ marginBottom: "5px" }}>Status</div>

                      <Tag
                        intent={
                          timesheetDetails.timesheet.status === "Approved"
                            ? "success"
                            : timesheetDetails.timesheet.status === "Draft"
                            ? "primary"
                            : timesheetDetails.timesheet.status === "Rejected"
                            ? "danger"
                            : "warning"
                        }
                        large
                        style={{
                          minWidth: "100px",
                          textAlign: "center",
                        }}
                      >
                        {timesheetDetails.timesheet.status}
                      </Tag>
                    </div>
                  </div>

                  {/* Comments */}
                  <FormDrawerTextAreaField3
                    disabled={timesheetDetails.timesheet.status !== "Draft"}
                    error={timesheetUpdate.error}
                    fieldName="comments"
                    height="100px"
                    label="Comments"
                    noRewrite={timesheetDetails.timesheet.status !== "Draft"}
                    placeholder="Enter any notes you want to capture"
                    setValueObject={setTimesheet}
                    setValueVariable="comments"
                    value={timesheet.comments}
                  />
                </div>

                {timesheetDetails.timesheet.status === "Draft" ? (
                  <Card style={{ marginLeft: "10px", width: "40%" }}>
                    {/* Approver */}
                    <FormDrawerSelectField2
                      fieldName="approver"
                      helperText="You can select people in your organisation who have authority to approve timesheets"
                      icon="user"
                      items={filteredManagerList}
                      label="Approver"
                      labelInfo="(required)"
                      loading={managerList.loading}
                      placeholder="Select an approver"
                      query={managerQuery.searchString}
                      setQueryObject={setManagerQuery}
                      setQueryVariable="searchString"
                      setValueObject={setTimesheetApprovalRequest}
                      setValueIdVariable="approver"
                      setValueNameVariable="approverName"
                      value={timesheetApprovalRequest.approverName}
                    />

                    {/* Submit */}
                    <FormDrawerButtonField
                      fieldName="status"
                      helperText="Do not submit to keep the timesheet in draft"
                      label="Submit"
                      options={[
                        {
                          icon: "pause",
                          id: 1,
                          intent: "primary",
                          label: "Do not submit",
                          value: false,
                        },
                        {
                          icon: "play",
                          id: 2,
                          intent: "success",
                          label: "Submit for review",
                          value: true,
                        },
                      ]}
                      setValueObject={setTimesheetApprovalRequest}
                      setValueVariable="submit"
                      value={timesheetApprovalRequest.submit}
                    />
                  </Card>
                ) : (
                  <Card style={{ marginLeft: "10px", width: "40%" }}>
                    <p>
                      <strong>Reviewer</strong>
                    </p>

                    <div
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <NameValue
                        email={
                          timesheetDetails.timesheet.approval_data
                            ? timesheetDetails.timesheet.approval_data
                                .approver_name
                            : undefined
                        }
                        image={
                          timesheetDetails.timesheet.approval_data
                            ? timesheetDetails.timesheet.approval_data
                                .approver_profile_photo
                            : undefined
                        }
                      />
                    </div>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <p>
                          <strong>Submitted</strong>
                        </p>

                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <DateValue
                            date={timesheetDetails.timesheet.submitted}
                          />
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <p>
                          <strong>Reviewed</strong>
                        </p>

                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <DateValue
                            date={timesheetDetails.timesheet.completed}
                          />
                        </div>
                      </div>
                    </div>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <p>
                      <strong>Comments</strong>
                    </p>

                    <p>
                      {timesheetDetails.timesheet.approval_data ? (
                        timesheetDetails.timesheet.approval_data.comments ? (
                          timesheetDetails.timesheet.approval_data.comments
                        ) : (
                          <span className={Classes.TEXT_MUTED}>
                            No comments.
                          </span>
                        )
                      ) : undefined}
                    </p>
                  </Card>
                )}
              </div>

              {/* Timesheet entries */}
              <div style={{ margin: "20px 0 20px 0" }}>
                {/* Error alerts */}
                {timesheetEntryCreate.error && (
                  <ErrorList
                    error={timesheetEntryCreate.error}
                    message="You cannot book time to this code."
                  />
                )}

                {timesheetEntryDelete.error && (
                  <ErrorList
                    error={timesheetEntryDelete.error}
                    message="Unable to delete the timesheet entry."
                  />
                )}

                {timesheetEntryUpdate.error && (
                  <ErrorList
                    error={timesheetEntryUpdate.error}
                    message="You cannot book time to this code."
                  />
                )}

                {/* Data table */}
                <EditableTableContainer
                  activeRecord={editTimesheetEntryId}
                  body={
                    <>
                      {timesheetEntryList.timesheet_entries.map((entry) => {
                        return (
                          <tr
                            key={entry.id}
                            onClick={
                              editTimesheetEntryId ||
                              showNewTimesheetEntryForm ||
                              timesheetDetails.timesheet.status !== "Draft"
                                ? undefined
                                : () => {
                                    dispatch(
                                      listTimesheetEntryDetailsAction(entry.id)
                                    );

                                    setEditTimesheetEntryId(entry.id);
                                  }
                            }
                          >
                            {/* Booking code */}
                            <td style={{ verticalAlign: "middle" }}>
                              {editTimesheetEntryId === entry.id ? (
                                <div style={{ margin: "0 0 -15px 0" }}>
                                  <FormDrawerSelectField2
                                    error={timesheetEntryUpdate.error}
                                    fieldName="booking_code"
                                    items={bookingCodeList.booking_codes.filter(
                                      (booking_code) =>
                                        !booking_code.engagement ||
                                        booking_code.engagement_roles_data.filter(
                                          (engagement_role) =>
                                            engagement_role.staff === Number(id)
                                        ).length > 0
                                    )}
                                    loading={bookingCodeList.loading}
                                    placeholder="Select a booking code"
                                    query={bookingCodeQuery.searchString}
                                    setQueryObject={setBookingCodeQuery}
                                    setQueryVariable="searchString"
                                    setValueObject={setTimesheetEntry}
                                    setValueIdVariable="bookingCode"
                                    setValueNameVariable="bookingCodeName"
                                    value={timesheetEntry.bookingCodeName}
                                  />
                                </div>
                              ) : (
                                <Text ellipsize>
                                  {entry.booking_code_data.name}
                                </Text>
                              )}
                            </td>

                            {/* Task */}
                            <td style={{ verticalAlign: "middle" }}>
                              {editTimesheetEntryId === entry.id ? (
                                <div style={{ margin: "0 0 -15px 0" }}>
                                  <FormGroup
                                    subLabel={
                                      timesheetEntryUpdate.error ? (
                                        timesheetEntryUpdate.error.response
                                          .data["task"] ? (
                                          <span style={{ color: Colors.RED3 }}>
                                            {
                                              timesheetEntryUpdate.error
                                                .response.data["task"]
                                            }
                                          </span>
                                        ) : undefined
                                      ) : undefined
                                    }
                                  >
                                    <SelectField
                                      options={taskOptions}
                                      setValueObject={setTimesheetEntry}
                                      setValueVariable="task"
                                      value={timesheetEntry.task}
                                    />
                                  </FormGroup>
                                </div>
                              ) : (
                                <Text ellipsize>
                                  {entry.task ? entry.task : "-"}
                                </Text>
                              )}
                            </td>

                            {/* Engagement role */}
                            <td style={{ verticalAlign: "middle" }}>
                              {editTimesheetEntryId === entry.id ? (
                                <div style={{ margin: "0 0 -15px 0" }}>
                                  <FormGroup
                                    subLabel={
                                      timesheetEntryUpdate.error ? (
                                        timesheetEntryUpdate.error.response
                                          .data["engagement_labour_expense"] ? (
                                          <span style={{ color: Colors.RED3 }}>
                                            {
                                              timesheetEntryUpdate.error
                                                .response.data[
                                                "engagement_labour_expense"
                                              ]
                                            }
                                          </span>
                                        ) : undefined
                                      ) : undefined
                                    }
                                  >
                                    <SelectField
                                      options={
                                        engagementRoleOptions.length > 0
                                          ? engagementRoleOptions
                                          : [
                                              {
                                                id: 0,
                                                label: "None",
                                                value: "",
                                              },
                                            ]
                                      }
                                      setValueObject={setTimesheetEntry}
                                      setValueVariable="engagementLabourExpense"
                                      value={
                                        timesheetEntry.engagementLabourExpense
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              ) : (
                                <Text ellipsize>
                                  {entry.engagement_role_name
                                    ? entry.engagement_role_name
                                    : "-"}
                                </Text>
                              )}
                            </td>

                            {/* Mon */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {editTimesheetEntryId === entry.id ? (
                                <TimePicker
                                  onChange={(newTime) => {
                                    const minutes =
                                      newTime.getHours() * 60 +
                                      newTime.getMinutes();

                                    setTimesheetEntry((prevState) => {
                                      return {
                                        ...prevState,
                                        minutesMon: minutes,
                                      };
                                    });
                                  }}
                                  value={
                                    new Date(
                                      0,
                                      0,
                                      0,
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesMon
                                      ).split(":")[0],
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesMon
                                      ).split(":")[1]
                                    )
                                  }
                                />
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.minutes_mon
                                  )}
                                </div>
                              )}
                            </td>

                            {/* Tue */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {editTimesheetEntryId === entry.id ? (
                                <TimePicker
                                  onChange={(newTime) => {
                                    const minutes =
                                      newTime.getHours() * 60 +
                                      newTime.getMinutes();

                                    setTimesheetEntry((prevState) => {
                                      return {
                                        ...prevState,
                                        minutesTue: minutes,
                                      };
                                    });
                                  }}
                                  value={
                                    new Date(
                                      0,
                                      0,
                                      0,
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesTue
                                      ).split(":")[0],
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesTue
                                      ).split(":")[1]
                                    )
                                  }
                                />
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.minutes_tue
                                  )}
                                </div>
                              )}
                            </td>

                            {/* Wed */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {editTimesheetEntryId === entry.id ? (
                                <TimePicker
                                  onChange={(newTime) => {
                                    const minutes =
                                      newTime.getHours() * 60 +
                                      newTime.getMinutes();

                                    setTimesheetEntry((prevState) => {
                                      return {
                                        ...prevState,
                                        minutesWed: minutes,
                                      };
                                    });
                                  }}
                                  value={
                                    new Date(
                                      0,
                                      0,
                                      0,
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesWed
                                      ).split(":")[0],
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesWed
                                      ).split(":")[1]
                                    )
                                  }
                                />
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.minutes_wed
                                  )}
                                </div>
                              )}
                            </td>

                            {/* Thu */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {editTimesheetEntryId === entry.id ? (
                                <TimePicker
                                  onChange={(newTime) => {
                                    const minutes =
                                      newTime.getHours() * 60 +
                                      newTime.getMinutes();

                                    setTimesheetEntry((prevState) => {
                                      return {
                                        ...prevState,
                                        minutesThu: minutes,
                                      };
                                    });
                                  }}
                                  value={
                                    new Date(
                                      0,
                                      0,
                                      0,
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesThu
                                      ).split(":")[0],
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesThu
                                      ).split(":")[1]
                                    )
                                  }
                                />
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.minutes_thu
                                  )}
                                </div>
                              )}
                            </td>

                            {/* Fri */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {editTimesheetEntryId === entry.id ? (
                                <TimePicker
                                  onChange={(newTime) => {
                                    const minutes =
                                      newTime.getHours() * 60 +
                                      newTime.getMinutes();

                                    setTimesheetEntry((prevState) => {
                                      return {
                                        ...prevState,
                                        minutesFri: minutes,
                                      };
                                    });
                                  }}
                                  value={
                                    new Date(
                                      0,
                                      0,
                                      0,
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesFri
                                      ).split(":")[0],
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesFri
                                      ).split(":")[1]
                                    )
                                  }
                                />
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.minutes_fri
                                  )}
                                </div>
                              )}
                            </td>

                            {/* Sat */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {editTimesheetEntryId === entry.id ? (
                                <TimePicker
                                  onChange={(newTime) => {
                                    const minutes =
                                      newTime.getHours() * 60 +
                                      newTime.getMinutes();

                                    setTimesheetEntry((prevState) => {
                                      return {
                                        ...prevState,
                                        minutesSat: minutes,
                                      };
                                    });
                                  }}
                                  value={
                                    new Date(
                                      0,
                                      0,
                                      0,
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesSat
                                      ).split(":")[0],
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesSat
                                      ).split(":")[1]
                                    )
                                  }
                                />
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.minutes_sat
                                  )}
                                </div>
                              )}
                            </td>

                            {/* Sun */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {editTimesheetEntryId === entry.id ? (
                                <TimePicker
                                  onChange={(newTime) => {
                                    const minutes =
                                      newTime.getHours() * 60 +
                                      newTime.getMinutes();

                                    setTimesheetEntry((prevState) => {
                                      return {
                                        ...prevState,
                                        minutesSun: minutes,
                                      };
                                    });
                                  }}
                                  value={
                                    new Date(
                                      0,
                                      0,
                                      0,
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesSun
                                      ).split(":")[0],
                                      convertMinutesToHoursAndMinutes(
                                        timesheetEntry.minutesSun
                                      ).split(":")[1]
                                    )
                                  }
                                />
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.minutes_sun
                                  )}
                                </div>
                              )}
                            </td>

                            {/* Total */}
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <strong>
                                {editTimesheetEntryId === entry.id ? (
                                  <div>
                                    {convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesFri +
                                        timesheetEntry.minutesMon +
                                        timesheetEntry.minutesSat +
                                        timesheetEntry.minutesSun +
                                        timesheetEntry.minutesThu +
                                        timesheetEntry.minutesTue +
                                        timesheetEntry.minutesWed
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {convertMinutesToHoursAndMinutes(
                                      entry.total_minutes
                                    )}
                                  </div>
                                )}
                              </strong>
                            </td>

                            {/* Comments */}
                            <td style={{ verticalAlign: "middle" }}>
                              {editTimesheetEntryId === entry.id ? (
                                <div style={{ margin: "0 0 -15px 0" }}>
                                  <FormDrawerTextField2
                                    error={timesheetEntryUpdate.error}
                                    fieldName="comments"
                                    placeholder="Enter comments"
                                    setValueObject={setTimesheetEntry}
                                    setValueVariable="comments"
                                    value={timesheetEntry.comments}
                                  />
                                </div>
                              ) : (
                                <TableText text={entry.comments} />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  }
                  //bordered={false}
                  count={timesheetEntryList.count}
                  createSuccess={timesheetEntryCreate.success}
                  createSuccessMessage="Timesheet entry created"
                  deleteSuccess={timesheetEntryDelete.success}
                  deleteSuccessMessage="Timesheet entry deleted."
                  footer={
                    <tr>
                      <td colSpan="3" style={{ textAlign: "end" }}>
                        Total
                      </td>

                      {/* Mon */}
                      <td style={{ textAlign: "center" }}>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesMon -
                                timesheetEntryDetails.timesheet_entry
                                  .minutes_mon +
                                timesheetEntry.minutesMon
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesMon +
                                timesheetEntry.minutesMon
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesMon
                            )}
                      </td>

                      {/* Tue */}
                      <td style={{ textAlign: "center" }}>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesTue -
                                timesheetEntryDetails.timesheet_entry
                                  .minutes_tue +
                                timesheetEntry.minutesTue
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesTue +
                                timesheetEntry.minutesTue
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesTue
                            )}
                      </td>

                      {/* Wed */}
                      <td style={{ textAlign: "center" }}>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesWed -
                                timesheetEntryDetails.timesheet_entry
                                  .minutes_wed +
                                timesheetEntry.minutesWed
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesWed +
                                timesheetEntry.minutesWed
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesWed
                            )}
                      </td>

                      {/* Thu */}
                      <td style={{ textAlign: "center" }}>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesThu -
                                timesheetEntryDetails.timesheet_entry
                                  .minutes_thu +
                                timesheetEntry.minutesThu
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesThu +
                                timesheetEntry.minutesThu
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesThu
                            )}
                      </td>

                      {/* Fri */}
                      <td style={{ textAlign: "center" }}>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesFri -
                                timesheetEntryDetails.timesheet_entry
                                  .minutes_fri +
                                timesheetEntry.minutesFri
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesFri +
                                timesheetEntry.minutesFri
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesFri
                            )}
                      </td>

                      {/* Sat */}
                      <td style={{ textAlign: "center" }}>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesSat -
                                timesheetEntryDetails.timesheet_entry
                                  .minutes_sat +
                                timesheetEntry.minutesSat
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesSat +
                                timesheetEntry.minutesSat
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesSat
                            )}
                      </td>

                      {/* Sun */}
                      <td style={{ textAlign: "center" }}>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesSun -
                                timesheetEntryDetails.timesheet_entry
                                  .minutes_sun +
                                timesheetEntry.minutesSun
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesSun +
                                timesheetEntry.minutesSun
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutesSun
                            )}
                      </td>

                      {/* Total */}
                      <td style={{ textAlign: "center" }}>
                        <strong>
                          {editTimesheetEntryId
                            ? convertMinutesToHoursAndMinutes(
                                timesheetEntryList.totalMinutes -
                                  timesheetEntryDetails.timesheet_entry
                                    .total_minutes +
                                  timesheetEntry.minutesFri +
                                  timesheetEntry.minutesMon +
                                  timesheetEntry.minutesSat +
                                  timesheetEntry.minutesSun +
                                  timesheetEntry.minutesThu +
                                  timesheetEntry.minutesTue +
                                  timesheetEntry.minutesWed
                              )
                            : showNewTimesheetEntryForm
                            ? convertMinutesToHoursAndMinutes(
                                timesheetEntryList.totalMinutes +
                                  timesheetEntry.minutesFri +
                                  timesheetEntry.minutesMon +
                                  timesheetEntry.minutesSat +
                                  timesheetEntry.minutesSun +
                                  timesheetEntry.minutesThu +
                                  timesheetEntry.minutesTue +
                                  timesheetEntry.minutesWed
                              )
                            : convertMinutesToHoursAndMinutes(
                                timesheetEntryList.totalMinutes
                              )}
                        </strong>
                      </td>

                      <td></td>
                    </tr>
                  }
                  head={
                    <tr>
                      {/* Booking code */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          width: "250px",
                        }}
                      >
                        Booking code
                      </th>

                      {/* Task */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          width: "250px",
                        }}
                      >
                        Task
                      </th>

                      {/* Engagement role */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          width: "250px",
                        }}
                      >
                        Engagement role
                      </th>

                      {/* Mon */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        Mon
                      </th>

                      {/* Tue */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        Tue
                      </th>

                      {/* Wed */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        Wed
                      </th>

                      {/* Thu */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        Thu
                      </th>

                      {/* Fri */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        Fri
                      </th>

                      {/* Sat */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        Sat
                      </th>

                      {/* Sun */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        Sun
                      </th>

                      {/* Total */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          textAlign: "center",
                          width: "90px",
                        }}
                      >
                        Total
                      </th>

                      {/* Comments */}
                      <th
                        className={Classes.TEXT_SMALL}
                        style={{
                          padding: "15px",
                          width: "300px",
                        }}
                      >
                        Comments
                      </th>
                    </tr>
                  }
                  interactive={
                    !editTimesheetEntryId &&
                    !showNewTimesheetEntryForm &&
                    timesheetDetails.timesheet.status === "Draft"
                  }
                  loading={timesheetEntryList.loading}
                  newForm={
                    <tr>
                      {/* Booking code */}
                      <td style={{ verticalAlign: "middle" }}>
                        <div style={{ margin: "0 0 -15px 0" }}>
                          <FormDrawerSelectField2
                            error={timesheetEntryCreate.error}
                            fieldName="booking_code"
                            items={bookingCodeList.booking_codes.filter(
                              (booking_code) =>
                                !booking_code.engagement ||
                                booking_code.engagement_roles_data.filter(
                                  (engagement_role) =>
                                    engagement_role.staff === Number(id)
                                ).length > 0
                            )}
                            loading={bookingCodeList.loading}
                            placeholder="Select a booking code"
                            query={bookingCodeQuery.searchString}
                            setQueryObject={setBookingCodeQuery}
                            setQueryVariable="searchString"
                            setValueObject={setTimesheetEntry}
                            setValueIdVariable="bookingCode"
                            setValueNameVariable="bookingCodeName"
                            value={timesheetEntry.bookingCodeName}
                          />
                        </div>
                      </td>

                      {/* Task */}
                      <td style={{ verticalAlign: "middle" }}>
                        {taskOptions.length > 1 ? (
                          <SelectField
                            options={taskOptions}
                            setValueObject={setTimesheetEntry}
                            setValueVariable="task"
                            value={timesheetEntry.task}
                          />
                        ) : (
                          <Text>-</Text>
                        )}
                      </td>

                      {/* Engagement role */}
                      <td style={{ verticalAlign: "middle" }}>
                        {engagementRoleOptions.length > 0 ? (
                          <SelectField
                            options={engagementRoleOptions}
                            setValueObject={setTimesheetEntry}
                            setValueVariable="engagementLabourExpense"
                            value={timesheetEntry.engagementLabourExpense}
                          />
                        ) : (
                          <Text>-</Text>
                        )}
                      </td>

                      {/* Mon */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <TimePicker
                          onChange={(newTime) => {
                            const minutes =
                              newTime.getHours() * 60 + newTime.getMinutes();

                            setTimesheetEntry((prevState) => {
                              return {
                                ...prevState,
                                minutesMon: minutes,
                              };
                            });
                          }}
                          value={
                            new Date(
                              0,
                              0,
                              0,
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesMon
                              ).split(":")[0],
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesMon
                              ).split(":")[1]
                            )
                          }
                        />
                      </td>

                      {/* Tue */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <TimePicker
                          onChange={(newTime) => {
                            const minutes =
                              newTime.getHours() * 60 + newTime.getMinutes();

                            setTimesheetEntry((prevState) => {
                              return {
                                ...prevState,
                                minutesTue: minutes,
                              };
                            });
                          }}
                          value={
                            new Date(
                              0,
                              0,
                              0,
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesTue
                              ).split(":")[0],
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesTue
                              ).split(":")[1]
                            )
                          }
                        />
                      </td>

                      {/* Wed */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <TimePicker
                          onChange={(newTime) => {
                            const minutes =
                              newTime.getHours() * 60 + newTime.getMinutes();

                            setTimesheetEntry((prevState) => {
                              return {
                                ...prevState,
                                minutesWed: minutes,
                              };
                            });
                          }}
                          value={
                            new Date(
                              0,
                              0,
                              0,
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesWed
                              ).split(":")[0],
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesWed
                              ).split(":")[1]
                            )
                          }
                        />
                      </td>

                      {/* Thu */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <TimePicker
                          onChange={(newTime) => {
                            const minutes =
                              newTime.getHours() * 60 + newTime.getMinutes();

                            setTimesheetEntry((prevState) => {
                              return {
                                ...prevState,
                                minutesThu: minutes,
                              };
                            });
                          }}
                          value={
                            new Date(
                              0,
                              0,
                              0,
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesThu
                              ).split(":")[0],
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesThu
                              ).split(":")[1]
                            )
                          }
                        />
                      </td>

                      {/* Fri */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <TimePicker
                          onChange={(newTime) => {
                            const minutes =
                              newTime.getHours() * 60 + newTime.getMinutes();

                            setTimesheetEntry((prevState) => {
                              return {
                                ...prevState,
                                minutesFri: minutes,
                              };
                            });
                          }}
                          value={
                            new Date(
                              0,
                              0,
                              0,
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesFri
                              ).split(":")[0],
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesFri
                              ).split(":")[1]
                            )
                          }
                        />
                      </td>

                      {/* Sat */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <TimePicker
                          onChange={(newTime) => {
                            const minutes =
                              newTime.getHours() * 60 + newTime.getMinutes();

                            setTimesheetEntry((prevState) => {
                              return {
                                ...prevState,
                                minutesSat: minutes,
                              };
                            });
                          }}
                          value={
                            new Date(
                              0,
                              0,
                              0,
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesSat
                              ).split(":")[0],
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesSat
                              ).split(":")[1]
                            )
                          }
                        />
                      </td>

                      {/* Sun */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <TimePicker
                          onChange={(newTime) => {
                            const minutes =
                              newTime.getHours() * 60 + newTime.getMinutes();

                            setTimesheetEntry((prevState) => {
                              return {
                                ...prevState,
                                minutesSun: minutes,
                              };
                            });
                          }}
                          value={
                            new Date(
                              0,
                              0,
                              0,
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesSun
                              ).split(":")[0],
                              convertMinutesToHoursAndMinutes(
                                timesheetEntry.minutesSun
                              ).split(":")[1]
                            )
                          }
                        />
                      </td>

                      {/* Total */}
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <strong>
                          {convertMinutesToHoursAndMinutes(
                            timesheetEntry.minutesFri +
                              timesheetEntry.minutesMon +
                              timesheetEntry.minutesSat +
                              timesheetEntry.minutesSun +
                              timesheetEntry.minutesThu +
                              timesheetEntry.minutesTue +
                              timesheetEntry.minutesWed
                          )}
                        </strong>
                      </td>

                      {/* Comments */}
                      <td style={{ verticalAlign: "middle" }}>
                        <div style={{ margin: "0 0 -15px 0" }}>
                          <FormDrawerTextField2
                            error={timesheetEntryCreate.error}
                            fieldName="comments"
                            placeholder="Enter comments"
                            setValueObject={setTimesheetEntry}
                            setValueVariable="comments"
                            value={timesheetEntry.comments}
                          />
                        </div>
                      </td>
                    </tr>
                  }
                  noMargin
                  noResultsMessage="Add entries."
                  noResultsTitle="No timesheet entries found"
                  page={timesheetEntryQuery.page}
                  refreshList={() => {
                    dispatch(
                      listTimesheetEntriesAction({
                        timesheet: timesheetDetails.timesheet.id,
                      })
                    );
                  }}
                  resetAction={() => {
                    dispatch(resetCreateTimesheetEntryAction());

                    dispatch(resetDeleteTimesheetEntryAction());

                    dispatch(resetUpdateTimesheetEntryAction());
                  }}
                  resetFormVariables={() => {
                    setTimesheetEntry({
                      ...timesheetEntryDefault,
                    });
                  }}
                  setActiveRecord={setEditTimesheetEntryId}
                  setPageObject={setTimesheetEntryQuery}
                  setPageVariable="page"
                  setShowNewForm={setShowNewTimesheetEntryForm}
                  showNewForm={showNewTimesheetEntryForm}
                  striped={false}
                  updateSuccess={timesheetEntryUpdate.success}
                  updateSuccessMessage="Timesheet entry updated."
                />

                {/* Buttons */}
                <div style={{ margin: "15px 0 0 0" }}>
                  {/* Neither adding nor editing record */}
                  {!editTimesheetEntryId && !showNewTimesheetEntryForm && (
                    <Button
                      disabled={
                        timesheetDetails.timesheet.status === "Approved" ||
                        timesheetDetails.timesheet.status === "Rejected" ||
                        timesheetDetails.timesheet.status === "Submitted"
                      }
                      icon="add"
                      intent="primary"
                      onClick={() => {
                        setShowNewTimesheetEntryForm(true);
                      }}
                      text="Add"
                    />
                  )}

                  {/* Adding record */}
                  {showNewTimesheetEntryForm && (
                    <>
                      <Button
                        icon="add"
                        intent="success"
                        onClick={() => {
                          dispatch(resetCreateTimesheetEntryAction());

                          addTimesheetEntry();
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Add"
                      />

                      <Button
                        icon="delete"
                        intent="primary"
                        onClick={() => {
                          dispatch(resetCreateTimesheetEntryAction());

                          setShowNewTimesheetEntryForm(false);
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Discard"
                      />
                    </>
                  )}

                  {/* Editing record */}
                  {editTimesheetEntryId && (
                    <>
                      <Button
                        icon="updated"
                        intent="success"
                        onClick={() => {
                          dispatch(resetUpdateTimesheetEntryAction());

                          updateTimesheetEntry();
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Update"
                      />

                      <Button
                        icon="disable"
                        intent="primary"
                        onClick={() => {
                          dispatch(resetUpdateTimesheetEntryAction());

                          setEditTimesheetEntryId(null);
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Discard"
                      />

                      <Button
                        icon="delete"
                        intent="danger"
                        onClick={() => {
                          dispatch(resetUpdateTimesheetEntryAction());

                          deleteTimesheetEntry();
                        }}
                        text="Remove"
                      />
                    </>
                  )}
                </div>
              </div>
            </Card>

            {timesheetDetails.timesheet.can_be_reversed
              ? timesheetDetails.timesheet.can_be_reversed.result === true && (
                  <Card style={{ width: "100%" }}>
                    <H5>Reverse timesheet</H5>

                    <Divider style={{ margin: "0 0 15px 0" }} />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "60%" }}>
                        {/* Reason */}
                        <FormDrawerTextAreaField3
                          error={timesheetReverse.error}
                          fieldName="comments"
                          height="100px"
                          label="Reason"
                          placeholder="Enter the reason for the reversal"
                          setValueObject={setTimesheetReversal}
                          setValueVariable="comments"
                          value={timesheetReversal.comments}
                        />

                        <Button
                          icon="undo"
                          intent="danger"
                          onClick={reverseTimesheet}
                          text="Reverse this timesheet"
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "40%" }}>
                        <Callout intent="primary">
                          You can reverse a timesheet if the following
                          conditions are met:
                          <UL>
                            <li>
                              You are an administrator or the owner of the
                              organisation.
                            </li>

                            <li>The timesheet is in Approved status.</li>

                            <li>
                              The timesheet does not contain time that has been
                              invoiced.
                            </li>
                          </UL>
                        </Callout>
                      </div>
                    </div>
                  </Card>
                )
              : undefined}
          </div>
        }
        deleteSuccess={timesheetDelete.success}
        deleteSuccessMessage="Timesheet deleted."
        deleting={timesheetDelete.loading}
        disabled={editTimesheetEntryId || showNewTimesheetEntryForm}
        error={timesheetUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update timesheet"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a timesheet"
        icon="edit"
        isOpen={updateTimesheetDrawerIsOpen}
        loading={timesheetDetails.loading}
        onDelete={
          timesheetDetails.timesheet.status === "Draft" && deleteTimesheet
        }
        onSave={
          timesheetDetails.timesheet.status === "Draft" && updateTimesheet
        }
        refreshList={() => {
          dispatch(listTimesheetsAction({ ...timesheetQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteTimesheetAction());

          dispatch(resetUpdateTimesheetAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={timesheetUpdate.success}
        saveSuccessMessage="Timesheet updated."
        saving={timesheetUpdate.loading}
        setIsOpen={setUpdateTimesheetDrawerIsOpen}
        size="large"
        title="Update timesheet"
      />

      {/* New expense claim form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter a title</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Claim title */}
            <FormDrawerTextField2
              error={claimCreate.error}
              fieldName="name"
              icon="bank-account"
              label="Claim title"
              labelInfo="(required)"
              placeholder="Give the expense claim a title"
              setValueObject={setClaim}
              setValueVariable="name"
              value={claim.name}
            />
          </Card>
        }
        error={claimCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create expense claim"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting an expense claim"
        icon="edit"
        isOpen={addClaimDrawerIsOpen}
        onSave={addClaim}
        refreshList={() => {
          dispatch(
            listClaimsAction({
              organisation: activeOrganisation.id,
              user: userLogin.user,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={claimCreate.success}
        saveSuccessMessage="Expense claim created."
        saving={claimCreate.loading}
        setIsOpen={setAddClaimDrawerIsOpen}
        size="small"
        title="Create expense claim"
      />

      {/* Existing expense claim form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            {claimDetails.claim.can_be_reversed
              ? claimDetails.claim.can_be_reversed.has_invoiced_expenses ===
                  true &&
                claimDetails.claim.can_be_reversed.user_is_admin_or_owner ===
                  true && (
                  <Callout intent="primary" style={{ margin: "0 0 10px 0" }}>
                    This claim cannot be reversed because it contains expenses
                    that have been invoiced.
                  </Callout>
                )
              : undefined}

            <Card
              style={{
                margin: claimDetails.claim.can_be_reversed
                  ? claimDetails.claim.can_be_reversed.result === true
                    ? "0 0 10px 0"
                    : undefined
                  : undefined,
                width: "100%",
              }}
            >
              <H5>Enter the expense claim details</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {claimDetails.claim.status === "Reversed" && (
                <Callout intent="warning" style={{ margin: "0 0 20px 0" }}>
                  This claim was reversed by{" "}
                  <strong>{claimDetails.claim.reversed_by}</strong> on{" "}
                  {new Date(claimDetails.claim.reversed_on).toLocaleString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  )}
                  {claimDetails.claim.reversed_comments
                    ? ", with the following comments: " +
                      claimDetails.claim.reversed_comments
                    : "."}
                </Callout>
              )}

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Claim title */}
                      <FormDrawerTextField2
                        disabled={claimDetails.claim.status !== "Draft"}
                        error={claimUpdate.error}
                        fieldName="name"
                        icon="bank-account"
                        label="Claim title"
                        labelInfo="(required)"
                        placeholder="Give the expense claim a title"
                        setValueObject={setClaim}
                        setValueVariable="name"
                        value={claim.name}
                      />
                    </div>

                    <div
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "35%",
                      }}
                    >
                      {/* Receipts */}
                      <FormDrawerFileField2
                        disabled={claimDetails.claim.status !== "Draft"}
                        error={claimUpdate.error}
                        fileName="receipts"
                        helperText="Combine your receipts into a single file"
                        label="Receipts"
                        placeholder="Upload receipts"
                        setValueObject={setClaim}
                        setValueFileVariable="receipts"
                        setValueNameVariable="receiptsName"
                        valueFile={claim.receipts}
                        valueName={claim.receiptsName}
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "15%" }}>
                      {/* Status */}
                      <div style={{ marginBottom: "5px" }}>Status</div>

                      <Tag
                        intent={
                          claimDetails.claim.status === "Approved"
                            ? "success"
                            : claimDetails.claim.status === "Draft"
                            ? "primary"
                            : claimDetails.claim.status === "Paid"
                            ? "success"
                            : claimDetails.claim.status === "Rejected"
                            ? "danger"
                            : "warning"
                        }
                        large
                        style={{
                          minWidth: "100px",
                          textAlign: "center",
                        }}
                      >
                        {claimDetails.claim.status}
                      </Tag>
                    </div>
                  </div>

                  {/* Comments */}
                  <FormDrawerTextAreaField3
                    disabled={claimDetails.claim.status !== "Draft"}
                    error={claimUpdate.error}
                    fieldName="comments"
                    height="100px"
                    label="Comments"
                    placeholder="Enter any notes you want to capture"
                    setValueObject={setClaim}
                    setValueVariable="comments"
                    value={claim.comments}
                  />
                </div>

                {claimDetails.claim.status === "Draft" ? (
                  <Card style={{ marginLeft: "10px", width: "40%" }}>
                    {/* Approver */}
                    <FormDrawerSelectField2
                      fieldName="approver"
                      helperText="You can select people in your organisation who have authority to approve expenses"
                      icon="user"
                      items={filteredManagerList}
                      label="Approver"
                      labelInfo="(required)"
                      loading={managerList.loading}
                      placeholder="Select an approver"
                      query={managerQuery.searchString}
                      setQueryObject={setManagerQuery}
                      setQueryVariable="searchString"
                      setValueObject={setClaimApprovalRequest}
                      setValueIdVariable="approver"
                      setValueNameVariable="approverName"
                      value={claimApprovalRequest.approverName}
                    />

                    {/* Submit */}
                    <FormDrawerButtonField
                      fieldName="status"
                      helperText="Do not submit to keep the expense claim in draft"
                      label="Submit"
                      options={[
                        {
                          icon: "pause",
                          id: 1,
                          intent: "primary",
                          label: "Do not submit",
                          value: false,
                        },
                        {
                          icon: "play",
                          id: 2,
                          intent: "success",
                          label: "Submit for review",
                          value: true,
                        },
                      ]}
                      setValueObject={setClaimApprovalRequest}
                      setValueVariable="submit"
                      value={claimApprovalRequest.submit}
                    />
                  </Card>
                ) : (
                  <Card style={{ marginLeft: "10px", width: "40%" }}>
                    <p>
                      <strong>Reviewer</strong>
                    </p>

                    <div
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <NameValue
                        email={
                          claimDetails.claim.approval_data
                            ? claimDetails.claim.approval_data.approver_name
                            : undefined
                        }
                        image={
                          claimDetails.claim.approval_data
                            ? claimDetails.claim.approval_data
                                .approver_profile_photo
                            : undefined
                        }
                      />
                    </div>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <p>
                          <strong>Submitted</strong>
                        </p>

                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <DateValue date={claimDetails.claim.submitted} />
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <p>
                          <strong>Reviewed</strong>
                        </p>

                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <DateValue date={claimDetails.claim.completed} />
                        </div>
                      </div>
                    </div>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <p>
                      <strong>Comments</strong>
                    </p>

                    <p>
                      {claimDetails.claim.approval_data ? (
                        claimDetails.claim.approval_data.comments ? (
                          claimDetails.claim.approval_data.comments
                        ) : (
                          <span className={Classes.TEXT_MUTED}>
                            No comments.
                          </span>
                        )
                      ) : undefined}
                    </p>
                  </Card>
                )}
              </div>

              {/* Expenses */}
              <div style={{ margin: "20px 0 20px 0" }}>
                {/* Error alerts */}
                {expenseCreate.error && (
                  <ErrorList
                    error={expenseCreate.error}
                    message="Unable to create the expense."
                  />
                )}

                {expenseDelete.error && (
                  <ErrorList
                    error={expenseDelete.error}
                    message="Unable to delete the expense."
                  />
                )}

                {expenseUpdate.error && (
                  <ErrorList
                    error={expenseUpdate.error}
                    message="Unable to update the expense."
                  />
                )}
              </div>

              {/* Data table */}
              <EditableTableContainer
                activeRecord={editExpenseId}
                body={
                  <>
                    {expenseList.expenses.map((entry) => {
                      return (
                        <tr
                          key={entry.id}
                          onClick={
                            editExpenseId ||
                            showNewExpenseForm ||
                            claimDetails.claim.status !== "Draft"
                              ? undefined
                              : () => {
                                  dispatch(listExpenseDetailsAction(entry.id));

                                  setEditExpenseId(entry.id);
                                }
                          }
                        >
                          {/* Booking code */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editExpenseId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerSelectField2
                                  error={expenseUpdate.error}
                                  fieldName="booking_code"
                                  items={bookingCodeList.booking_codes}
                                  loading={bookingCodeList.loading}
                                  placeholder="Select a booking code"
                                  query={bookingCodeQuery.searchString}
                                  setQueryObject={setBookingCodeQuery}
                                  setQueryVariable="searchString"
                                  setValueObject={setExpense}
                                  setValueIdVariable="bookingCode"
                                  setValueNameVariable="bookingCodeName"
                                  value={expense.bookingCodeName}
                                />
                              </div>
                            ) : (
                              <TableText text={entry.booking_code_data.name} />
                            )}
                          </td>

                          {/* Category */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editExpenseId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerSelectField2
                                  error={expenseUpdate.error}
                                  fieldName="category"
                                  items={expenseCategoryList.expense_categories}
                                  loading={expenseCategoryList.loading}
                                  placeholder="Select a category"
                                  query={expenseCategoryQuery.searchString}
                                  setQueryObject={setExpenseCategoryQuery}
                                  setQueryVariable="searchString"
                                  setValueObject={setExpense}
                                  setValueIdVariable="category"
                                  setValueNameVariable="categoryName"
                                  value={expense.categoryName}
                                />
                              </div>
                            ) : (
                              <TableText text={entry.category_name} />
                            )}
                          </td>

                          {/* Date */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editExpenseId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerDateField2
                                  error={expenseUpdate.error}
                                  fieldName="date"
                                  icon="calendar"
                                  placeholder="Enter date"
                                  setValueObject={setExpense}
                                  setValueVariable="date"
                                  value={expense.date}
                                />
                              </div>
                            ) : (
                              new Date(entry.date).toLocaleString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })
                            )}
                          </td>

                          {/* Value */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editExpenseId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerNumericField2
                                  error={expenseUpdate.error}
                                  fieldName="value"
                                  leftElement={
                                    <Tag
                                      minimal
                                      style={{
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  }
                                  majorStepSize={10000}
                                  min={0}
                                  minorStepSize={100}
                                  placeholder="Enter value"
                                  setValueObject={setExpense}
                                  setValueVariable="value"
                                  stepSize={1000}
                                  value={expense.value}
                                />
                              </div>
                            ) : (
                              <TableNumber number={entry.value} />
                            )}
                          </td>

                          {/* Comments */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editExpenseId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerTextField2
                                  error={expenseUpdate.error}
                                  fieldName="comments"
                                  placeholder="Enter comments"
                                  setValueObject={setExpense}
                                  setValueVariable="comments"
                                  value={expense.comments}
                                />
                              </div>
                            ) : (
                              <TableText text={entry.comments} />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                }
                //bordered={false}
                count={expenseList.count}
                createSuccess={expenseCreate.success}
                createSuccessMessage="Expense created"
                deleteSuccess={expenseDelete.success}
                deleteSuccessMessage="Expense deleted."
                footer={
                  <tr>
                    <td colSpan="3" style={{ textAlign: "end" }}>
                      Total
                    </td>

                    {/* Value */}
                    <td style={{ textAlign: "center" }}>
                      <TableNumber number={expenseList.totalValue} />
                    </td>

                    <td></td>
                  </tr>
                }
                head={
                  <tr>
                    {/* Booking code */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "250px",
                      }}
                    >
                      Booking code
                    </th>

                    {/* Category */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "250px",
                      }}
                    >
                      Category
                    </th>

                    {/* Date */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "200px",
                      }}
                    >
                      Date
                    </th>

                    {/* Value */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "200px",
                      }}
                    >
                      Value
                    </th>

                    {/* Comments */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "300px",
                      }}
                    >
                      Comments
                    </th>
                  </tr>
                }
                interactive={
                  !editExpenseId &&
                  !showNewExpenseForm &&
                  claimDetails.claim.status === "Draft"
                }
                loading={expenseList.loading}
                newForm={
                  <tr>
                    {/* Booking code */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerSelectField2
                          error={expenseCreate.error}
                          fieldName="booking_code"
                          items={bookingCodeList.booking_codes}
                          loading={bookingCodeList.loading}
                          placeholder="Select a booking code"
                          query={bookingCodeQuery.searchString}
                          setQueryObject={setBookingCodeQuery}
                          setQueryVariable="searchString"
                          setValueObject={setExpense}
                          setValueIdVariable="bookingCode"
                          setValueNameVariable="bookingCodeName"
                          value={expense.bookingCodeName}
                        />
                      </div>
                    </td>

                    {/* Category */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerSelectField2
                          error={expenseCreate.error}
                          fieldName="category"
                          items={expenseCategoryList.expense_categories}
                          loading={expenseCategoryList.loading}
                          placeholder="Select a category"
                          query={expenseCategoryQuery.searchString}
                          setQueryObject={setExpenseCategoryQuery}
                          setQueryVariable="searchString"
                          setValueObject={setExpense}
                          setValueIdVariable="category"
                          setValueNameVariable="categoryName"
                          value={expense.categoryName}
                        />
                      </div>
                    </td>

                    {/* Date */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerDateField2
                          error={expenseCreate.error}
                          fieldName="date"
                          icon="calendar"
                          placeholder="Enter date"
                          setValueObject={setExpense}
                          setValueVariable="date"
                          value={expense.date}
                        />
                      </div>
                    </td>

                    {/* Value */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerNumericField2
                          error={expenseCreate.error}
                          fieldName="value"
                          leftElement={
                            <Tag
                              minimal
                              style={{
                                textAlign: "center",
                                width: "50px",
                              }}
                            >
                              {activeOrganisation.currency}
                            </Tag>
                          }
                          majorStepSize={10000}
                          min={0}
                          minorStepSize={100}
                          placeholder="Enter value"
                          setValueObject={setExpense}
                          setValueVariable="value"
                          stepSize={1000}
                          value={expense.value}
                        />
                      </div>
                    </td>

                    {/* Comments */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerTextField2
                          error={expenseCreate.error}
                          fieldName="comments"
                          placeholder="Enter comments"
                          setValueObject={setExpense}
                          setValueVariable="comments"
                          value={expense.comments}
                        />
                      </div>
                    </td>
                  </tr>
                }
                noMargin
                noResultsMessage="Add expenses."
                noResultsTitle="No expenses found"
                page={expenseQuery.page}
                refreshList={() => {
                  dispatch(
                    listExpensesAction({
                      claim: claimDetails.claim.id,
                      organisation: activeOrganisation.id,
                    })
                  );
                }}
                resetAction={() => {
                  dispatch(resetCreateExpenseAction());

                  dispatch(resetDeleteExpenseAction());

                  dispatch(resetUpdateExpenseAction());
                }}
                resetFormVariables={() => {
                  setExpense({
                    ...expenseDefault,
                  });
                }}
                setActiveRecord={setEditExpenseId}
                setPageObject={setExpenseQuery}
                setPageVariable="page"
                setShowNewForm={setShowNewExpenseForm}
                showNewForm={showNewExpenseForm}
                striped={false}
                updateSuccess={expenseUpdate.success}
                updateSuccessMessage="Expense updated."
              />

              {/* Buttons */}
              <div style={{ margin: "15px 0 0 0" }}>
                {/* Neither adding nor editing record */}
                {!editExpenseId && !showNewExpenseForm && (
                  <Button
                    disabled={
                      expenseDetails.expense.status === "Approved" ||
                      expenseDetails.expense.status === "Rejected" ||
                      expenseDetails.expense.status === "Submitted"
                    }
                    icon="add"
                    intent="primary"
                    onClick={() => {
                      setShowNewExpenseForm(true);
                    }}
                    text="Add"
                  />
                )}

                {/* Adding record */}
                {showNewExpenseForm && (
                  <>
                    <Button
                      icon="add"
                      intent="success"
                      onClick={() => {
                        dispatch(resetCreateExpenseAction());

                        addExpense();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Add"
                    />

                    <Button
                      icon="delete"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetCreateExpenseAction());

                        setShowNewExpenseForm(false);
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />
                  </>
                )}

                {/* Editing record */}
                {editExpenseId && (
                  <>
                    <Button
                      icon="updated"
                      intent="success"
                      onClick={() => {
                        dispatch(resetUpdateExpenseAction());

                        updateExpense();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Update"
                    />

                    <Button
                      icon="disable"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetUpdateExpenseAction());

                        setEditExpenseId(null);
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />

                    <Button
                      icon="delete"
                      intent="danger"
                      onClick={() => {
                        dispatch(resetUpdateExpenseAction());

                        deleteExpense();
                      }}
                      text="Remove"
                    />
                  </>
                )}
              </div>
            </Card>

            {claimDetails.claim.can_be_reversed
              ? claimDetails.claim.can_be_reversed.result === true && (
                  <Card style={{ width: "100%" }}>
                    <H5>Reverse claim</H5>

                    <Divider style={{ margin: "0 0 15px 0" }} />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "60%" }}>
                        {/* Reason */}
                        <FormDrawerTextAreaField3
                          error={claimReverse.error}
                          fieldName="comments"
                          height="100px"
                          label="Reason"
                          placeholder="Enter the reason for the reversal"
                          setValueObject={setClaimReversal}
                          setValueVariable="comments"
                          value={claimReversal.comments}
                        />

                        <Button
                          icon="undo"
                          intent="danger"
                          onClick={reverseClaim}
                          text="Reverse this claim"
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "40%" }}>
                        <Callout intent="primary">
                          You can reverse an expense claim if the following
                          conditions are met:
                          <UL>
                            <li>
                              You are an administrator or the owner of the
                              organisation.
                            </li>

                            <li>The claim is in Approved status.</li>

                            <li>
                              The claim does not contain expenses that have been
                              invoiced.
                            </li>
                          </UL>
                        </Callout>
                      </div>
                    </div>
                  </Card>
                )
              : undefined}
          </div>
        }
        cannotDelete={bookingDetails.booking.cannot_delete}
        deleteSuccess={claimDelete.success}
        deleteSuccessMessage="Expense claim deleted."
        deleting={claimDelete.loading}
        disabled={editExpenseId || showNewExpenseForm}
        error={claimUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update expense claim"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting an expense claim"
        icon="edit"
        isOpen={updateClaimDrawerIsOpen}
        loading={claimDetails.loading}
        onDelete={claimDetails.claim.status === "Draft" && deleteClaim}
        onSave={claimDetails.claim.status === "Draft" && updateClaim}
        refreshList={() => {
          dispatch(listClaimsAction({ ...claimQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteClaimAction());

          dispatch(resetUpdateClaimAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={claimUpdate.success}
        saveSuccessMessage="Claim updated."
        saving={claimUpdate.loading}
        setIsOpen={setUpdateClaimDrawerIsOpen}
        size="large"
        title="Update expense claim"
      />

      {/* New leave request form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Select the week</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Week */}
            <div style={{ margin: "0 0 15px 0" }}>
              <div style={{ marginBottom: "5px" }}>Week</div>

              <SelectField
                options={weekOptions}
                setValueObject={setLeaveRequest}
                setValueVariable="weekLabel"
                value={leaveRequest.weekLabel}
              />
            </div>
          </Card>
        }
        error={leaveRequestCreate.error}
        errorMessage="We couldn't add the leave request. Review any errors below and try
        saving again."
        helpContent={
          <FormDrawerHelpContainer
            form="Create leave request"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a leave request"
        icon="edit"
        isOpen={addLeaveRequestDrawerIsOpen}
        onSave={addLeaveRequest}
        refreshList={() => {
          dispatch(
            listLeaveRequestsAction({
              organisation: activeOrganisation.id,
              user: userLogin.user,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={leaveRequestCreate.success}
        saveSuccessMessage="Leave request created."
        saving={leaveRequestCreate.loading}
        setIsOpen={setAddLeaveRequestDrawerIsOpen}
        size="small"
        title="Create leave request"
      />

      {/* Existing leave request form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the leave request details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {leaveRequestDetails.leave_request.status === "Cancelled" && (
              <Callout intent="warning" style={{ margin: "0 0 20px 0" }}>
                This leave request was cancelled on{" "}
                {new Date(
                  leaveRequestDetails.leave_request.cancelled_on
                ).toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Callout>
            )}

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Week */}
                    <div style={{ margin: "0 0 15px 0" }}>
                      <div style={{ marginBottom: "5px" }}>Week</div>

                      <SelectField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        options={weekOptions}
                        setValueObject={setLeaveRequest}
                        setValueVariable="weekLabel"
                        value={leaveRequest.weekLabel}
                      />
                    </div>
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Status */}
                    <div style={{ marginBottom: "5px" }}>Status</div>

                    <Tag
                      intent={
                        leaveRequestDetails.leave_request.status === "Approved"
                          ? "success"
                          : leaveRequestDetails.leave_request.status === "Draft"
                          ? "primary"
                          : leaveRequestDetails.leave_request.status ===
                            "Rejected"
                          ? "danger"
                          : "warning"
                      }
                      large
                      style={{
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      {leaveRequestDetails.leave_request.status}
                    </Tag>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "85%" }}>
                    {/* Type */}
                    <FormDrawerSelectField2
                      disabled={
                        leaveRequestDetails.leave_request.status !== "Draft"
                      }
                      error={leaveRequestUpdate.error}
                      fieldName="category"
                      helperText="You can select an existing category"
                      icon="key-escape"
                      info={
                        <LeaveCategoryInfo
                          categoryId={leaveRequest.category}
                          query={leaveCategoryQueryDefault}
                        />
                      }
                      items={leaveCategoryList.leave_categories}
                      label="Type"
                      loading={leaveCategoryList.loading}
                      placeholder="Select a category"
                      query={leaveCategoryQuery.searchString}
                      setQueryObject={setLeaveCategoryQuery}
                      setQueryVariable="searchString"
                      setValueObject={setLeaveRequest}
                      setValueIdVariable="category"
                      setValueNameVariable="categoryName"
                      showInfo={leaveRequest.category}
                      value={leaveRequest.categoryName}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "15%" }}></div>
                </div>

                {/* Comments */}
                <FormDrawerTextAreaField3
                  disabled={
                    leaveRequestDetails.leave_request.status !== "Draft"
                  }
                  error={leaveRequestUpdate.error}
                  fieldName="comments"
                  height="100px"
                  label="Comments"
                  noRewrite={
                    leaveRequestDetails.leave_request.status !== "Draft"
                  }
                  placeholder="Enter any notes you want to capture"
                  setValueObject={setLeaveRequest}
                  setValueVariable="comments"
                  value={leaveRequest.comments}
                />
              </div>

              {leaveRequestDetails.leave_request.status === "Draft" ? (
                <Card style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve leave requests"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setLeaveRequestApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={leaveRequestApprovalRequest.approverName}
                  />

                  {/* Submit */}
                  <FormDrawerButtonField
                    fieldName="status"
                    helperText="Do not submit to keep the leave request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: false,
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: true,
                      },
                    ]}
                    setValueObject={setLeaveRequestApprovalRequest}
                    setValueVariable="submit"
                    value={leaveRequestApprovalRequest.submit}
                  />
                </Card>
              ) : (
                <Card style={{ marginLeft: "10px", width: "50%" }}>
                  <p>
                    <strong>Reviewer</strong>
                  </p>

                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <NameValue
                      email={
                        leaveRequestDetails.leave_request.approval_data
                          ? leaveRequestDetails.leave_request.approval_data
                              .approver_name
                          : undefined
                      }
                      image={
                        leaveRequestDetails.leave_request.approval_data
                          ? leaveRequestDetails.leave_request.approval_data
                              .approver_profile_photo
                          : undefined
                      }
                    />
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Submitted</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue
                          date={leaveRequestDetails.leave_request.submitted}
                        />
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Reviewed</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue
                          date={leaveRequestDetails.leave_request.completed}
                        />
                      </div>
                    </div>
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Comments</strong>
                  </p>

                  <p>
                    {leaveRequestDetails.leave_request.approval_data ? (
                      leaveRequestDetails.leave_request.approval_data
                        .comments ? (
                        leaveRequestDetails.leave_request.approval_data.comments
                      ) : (
                        <span className={Classes.TEXT_MUTED}>No comments.</span>
                      )
                    ) : undefined}
                  </p>
                </Card>
              )}
            </div>

            {/* Leave entries */}
            <Card style={{ margin: "20px 0 20px 0" }}>
              <HTMLTable style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_mon"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesMon"
                        value={leaveRequest.minutesMon}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_tue"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesTue"
                        value={leaveRequest.minutesTue}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_wed"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesWed"
                        value={leaveRequest.minutesWed}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_thu"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesThu"
                        value={leaveRequest.minutesThu}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_fri"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesFri"
                        value={leaveRequest.minutesFri}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_sat"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesSat"
                        value={leaveRequest.minutesSat}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_sun"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesSun"
                        value={leaveRequest.minutesSun}
                      />
                    </td>

                    <td>
                      <div style={{ paddingTop: "5px" }}>
                        <strong>
                          {convertMinutesToHoursAndMinutes(
                            leaveRequest.minutesFri +
                              leaveRequest.minutesMon +
                              leaveRequest.minutesSat +
                              leaveRequest.minutesSun +
                              leaveRequest.minutesThu +
                              leaveRequest.minutesTue +
                              leaveRequest.minutesWed
                          )}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <td>Monday</td>

                    <td>Tuesday</td>

                    <td>Wednesday</td>

                    <td>Thursday</td>

                    <td>Friday</td>

                    <td>Saturday</td>

                    <td>Sunday</td>

                    <td>Total</td>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>

            {leaveRequestDetails.leave_request.status === "Approved" && (
              <Button
                icon="undo"
                intent="danger"
                loading={leaveRequestCancel.loading}
                onClick={() => {
                  dispatch(
                    cancelLeaveRequestAction(
                      leaveRequestDetails.leave_request.id
                    )
                  );
                }}
                text="Cancel this leave"
              />
            )}
          </Card>
        }
        deleteSuccess={leaveRequestDelete.success}
        deleteSuccessMessage="Leave request deleted."
        deleting={leaveRequestDelete.loading}
        error={leaveRequestUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update leave request"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a leave request"
        icon="edit"
        isOpen={updateLeaveRequestDrawerIsOpen}
        loading={leaveRequestDetails.loading}
        onDelete={
          leaveRequestDetails.leave_request.status === "Draft" &&
          deleteLeaveRequest
        }
        onSave={
          leaveRequestDetails.leave_request.status === "Draft" &&
          updateLeaveRequest
        }
        refreshList={() => {
          dispatch(listLeaveRequestsAction({ ...leaveRequestQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteLeaveRequestAction());

          dispatch(resetUpdateLeaveRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={leaveRequestUpdate.success}
        saveSuccessMessage="Leave request updated."
        saving={leaveRequestUpdate.loading}
        setIsOpen={setUpdateLeaveRequestDrawerIsOpen}
        size="medium"
        title="Update leave request"
      />

      {/* New exit action form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the action</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={staffExitActionCreate.error}
                  fieldName="name"
                  icon="take-action"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the action a title"
                  setValueObject={setStaffExitAction}
                  setValueVariable="name"
                  value={staffExitAction.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Action */}
            <FormDrawerTextAreaField3
              error={staffExitActionCreate.error}
              fieldName="action"
              height="100px"
              label="Action"
              labelInfo="(required)"
              placeholder="Enter the action"
              setValueObject={setStaffExitAction}
              setValueVariable="action"
              value={staffExitAction.action}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={staffExitActionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for completing the action"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setStaffExitAction}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={staffExitAction.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={staffExitActionCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the action due date"
                  setValueObject={setStaffExitAction}
                  setValueVariable="due"
                  value={staffExitAction.due}
                />
              </div>
            </div>
          </Card>
        }
        error={staffExitActionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create exit action"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing exit actions"
        icon="edit"
        isOpen={addExitActionDrawerIsOpen}
        onSave={addExitAction}
        refreshList={() => {
          dispatch(listStaffExitActionsAction({ staff: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffExitActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffExitActionCreate.success}
        saveSuccessMessage="Action created."
        saving={staffExitActionCreate.loading}
        setIsOpen={setAddExitActionDrawerIsOpen}
        title="Create exit action"
      />

      {/* Existing exit action form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the action</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={staffExitActionUpdate.error}
                    fieldName="name"
                    icon="shield"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the action a title"
                    setValueObject={setStaffExitAction}
                    setValueVariable="name"
                    value={staffExitAction.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Action */}
              <FormDrawerTextAreaField3
                error={staffExitActionUpdate.error}
                fieldName="action"
                height="100px"
                label="Action"
                labelInfo="(required)"
                placeholder="Enter the action"
                setValueObject={setStaffExitAction}
                setValueVariable="action"
                value={staffExitAction.action}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={staffExitActionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setStaffExitAction}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={staffExitAction.ownerName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                >
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={staffExitActionUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the action due date"
                    setValueObject={setStaffExitAction}
                    setValueVariable="due"
                    value={staffExitAction.due}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  tag={
                    staffExitActionDetails.staff_exit_action.owner_done
                      ? "Ready to close"
                      : "Working on it"
                  }
                  tagIntent={
                    staffExitActionDetails.staff_exit_action.owner_done
                      ? "success"
                      : "primary"
                  }
                  text={staffExitActionDetails.staff_exit_action.owner_comments}
                  title="Update from action owner"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={staffExitActionUpdate.error}
                    fieldName="status"
                    intent={
                      staffExitAction.status === "Completed"
                        ? "success"
                        : staffExitAction.status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Completed",
                      },
                      {
                        id: 2,
                        icon: "refresh",
                        value: "In-progress",
                      },
                      {
                        id: 3,
                        icon: "remove",
                        value: "On-hold",
                      },
                    ]}
                    setValueObject={setStaffExitAction}
                    setValueVariable="status"
                    value={staffExitAction.status}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                ></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={staffExitActionUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setStaffExitAction}
                setValueVariable="comments"
                value={staffExitAction.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={staffExitActionDelete.success}
        deleteSuccessMessage="Action deleted."
        deleting={staffExitActionDelete.loading}
        error={staffExitActionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update exit action"
            module="Staff"
            view="Detail"
          />
        }
        helpTitle="Managing exit actions"
        icon="edit"
        isOpen={updateExitActionDrawerIsOpen}
        loading={staffExitActionDetails.loading}
        onDelete={deleteExitAction}
        onSave={updateExitAction}
        refreshList={() => {
          dispatch(listStaffExitActionsAction({ ...staffExitActionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffExitActionAction());

          dispatch(resetUpdateStaffExitActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffExitActionUpdate.success}
        saveSuccessMessage="Action updated."
        saving={staffExitActionUpdate.loading}
        setIsOpen={setUpdateExitActionDrawerIsOpen}
        title="Update exit action"
      />

      {/* New exit upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the upload details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  error={staffExitUploadCreate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setStaffExitUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={staffExitUpload.file}
                  valueName={staffExitUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  error={staffExitUploadCreate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setStaffExitUpload}
                  setValueVariable="name"
                  value={staffExitUpload.name}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={staffExitUploadCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    { id: 1, icon: "saved", value: "Exit Agreement" },
                    { id: 2, icon: "form", value: "Exit Checklist" },
                    { id: 3, icon: "chat", value: "Exit Interview" },
                    { id: 4, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setStaffExitUpload}
                  setValueVariable="category"
                  value={staffExitUpload.category}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={staffExitUploadCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarise the file contents"
              setValueObject={setStaffExitUpload}
              setValueVariable="description"
              value={staffExitUpload.description}
            />
          </Card>
        }
        error={staffExitUploadCreate.error}
        icon="edit"
        isOpen={addExitUploadDrawerIsOpen}
        onSave={addExitUpload}
        refreshList={() => {
          dispatch(listStaffExitUploadsAction({ staff: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateStaffExitUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffExitUploadCreate.success}
        saveSuccessMessage="File uploaded."
        saving={staffExitUploadCreate.loading}
        setIsOpen={setAddExitUploadDrawerIsOpen}
        title="Upload file"
      />

      {/* Existing exit upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the file details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  disabled={staffDetails.staff.user_access_status === "view"}
                  error={staffExitUploadUpdate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setStaffExitUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={staffExitUpload.file}
                  valueName={staffExitUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  disabled={staffDetails.staff.user_access_status === "view"}
                  error={staffExitUploadUpdate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setStaffExitUpload}
                  setValueVariable="name"
                  value={staffExitUpload.name}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={staffExitUploadUpdate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    { id: 1, icon: "saved", value: "Exit Agreement" },
                    { id: 2, icon: "form", value: "Exit Checklist" },
                    { id: 3, icon: "chat", value: "Exit Interview" },
                    { id: 4, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setStaffExitUpload}
                  setValueVariable="category"
                  value={staffExitUpload.category}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              disabled={staffDetails.staff.user_access_status === "view"}
              error={staffExitUploadUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarise the file contents"
              setValueObject={setStaffExitUpload}
              setValueVariable="description"
              value={staffExitUpload.description}
            />

            <Button
              disabled={!staffExitUpload.file}
              icon="download"
              intent="primary"
              onClick={() => {
                saveAs(staffExitUpload.file, staffExitUpload.fileName);
              }}
              text="Download"
            />
          </Card>
        }
        deleteSuccess={staffExitUploadDelete.success}
        deleteSuccessMessage="File deleted."
        deleting={staffExitUploadDelete.loading}
        disabled={staffDetails.staff.user_access_status === "view"}
        error={staffExitUploadUpdate.error}
        icon="edit"
        isOpen={updateExitUploadDrawerIsOpen}
        loading={staffExitUploadDetails.loading}
        onDelete={deleteExitUpload}
        onSave={updateExitUpload}
        refreshList={() => {
          dispatch(listStaffExitUploadsAction({ ...staffExitUploadQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteStaffExitUploadAction());

          dispatch(resetUpdateStaffExitUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffExitUploadUpdate.success}
        saveSuccessMessage="File updated."
        saving={staffExitUploadUpdate.loading}
        setIsOpen={setUpdateExitUploadDrawerIsOpen}
        title="Update file"
      />

      {/* Action dialogs */}
      {/* Bookings dialog */}
      <Dialog
        className={!lightMode ? Classes.DARK : undefined}
        icon="timeline-events"
        isCloseButtonShown
        isOpen={bookingsDialogIsOpen}
        lazy
        onClose={() => {
          setBookingsDialogIsOpen(false);
        }}
        style={{
          height: "95vh",
          width: "95vw",
        }}
        title="Bookings"
      >
        <div className={Classes.DIALOG_BODY}>
          <Timeline4
            categoryOneColor={Colors.BLUE3}
            categoryOneValue="Confirmed"
            categoryThreeColor={Colors.ORANGE5}
            categoryThreeValue="Tentative"
            categoryTwoColor={Colors.GREEN3}
            categoryTwoValue="Realised"
            data={generateTimelineDataFromBookings(bookingList.bookings)}
            height="70vh"
            hideExpandButton
            itemListHeading="Booking code"
            loading={bookingList.loading}
            onClick={(id, type) => {
              if (staffDetails.staff.user_access_status !== "view") {
                const booking = bookingList.bookings.filter(
                  (booking) => booking.id === Number(id)
                )[0];

                if (booking.type === "Realised") {
                  AppToaster.show({
                    icon: "warning-sign",
                    intent: "warning",
                    message:
                      "You cannot edit realised bookings directly, as they are based on timesheets.",
                  });
                } else {
                  dispatch(listBookingDetailsAction(id));

                  setUpdateBookingDrawerIsOpen(true);
                }
              }
            }}
            showDates
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              disabled={staffDetails.staff.user_access_status === "view"}
              icon="add"
              intent="primary"
              onClick={() => {
                setAddBookingDrawerIsOpen(true);
              }}
              text="Add"
            />

            <Button
              className={Classes.DIALOG_CLOSE_BUTTON}
              onClick={() => {
                setBookingsDialogIsOpen(false);
              }}
              text="Close"
            />
          </div>
        </div>
      </Dialog>

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Staff"
              onClick={() => {
                dispatch(resetListStaffAction());

                navigate("/staff");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          staffDetails.staff.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={staffDetails.loading}
        title={`${staffDetails.staff.name}`}
      />

      {/* Error alerts */}
      {/* Bookings loading error */}
      {bookingList.error && (
        <ErrorAlert message="We couldn't load the staff bookings. Try refreshing the page." />
      )}
      {/* Booking codes loading error */}
      {bookingCodeList.error && (
        <ErrorAlert message="We couldn't load the booking code list. Try refreshing the page." />
      )}
      {/* Claims loading error */}
      {claimList.error && (
        <ErrorAlert message="We couldn't load the claim list. Try refreshing the page." />
      )}
      {/* Expense categories loading error */}
      {expenseCategoryList.error && (
        <ErrorAlert message="We couldn't load the expense category list. Try refreshing the page." />
      )}
      {/* Expenses loading error */}
      {expenseList.error && (
        <ErrorAlert message="We couldn't load the expense list. Try refreshing the page." />
      )}
      {/* Leave request loading error */}
      {leaveRequestList.error && (
        <ErrorAlert message="We couldn't load the leave request list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Roles loading error */}
      {roleList.error && (
        <ErrorAlert message="We couldn't load the role list. Try refreshing the page." />
      )}
      {/* Staff loading error */}
      {staffDetails.error && (
        <ErrorAlert message="We couldn't load the staff record. Try refreshing the page." />
      )}
      {/* Staff competency loading error */}
      {staffCompetencyList.error && (
        <ErrorAlert message="We couldn't load the competency list. Try refreshing the page." />
      )}
      {/* Staff development plans loading error */}
      {staffDevelopmentPlanList.error && (
        <ErrorAlert message="We couldn't load the staff development plans. Try refreshing the page." />
      )}
      {/* Staff exit actions loading error */}
      {staffExitActionList.error && (
        <ErrorAlert message="We couldn't load the staff exit actions. Try refreshing the page." />
      )}
      {/* Staff exit uploads loading error */}
      {staffExitUploadList.error && (
        <ErrorAlert message="We couldn't load the staff exit files. Try refreshing the page." />
      )}
      {/* Staff goals loading error */}
      {staffGoalList.error && (
        <ErrorAlert message="We couldn't load the staff goals. Try refreshing the page." />
      )}
      {/* Staff onboarding actions loading error */}
      {staffOnboardingActionList.error && (
        <ErrorAlert message="We couldn't load the staff onboarding actions. Try refreshing the page." />
      )}
      {/* Staff onboarding uploads loading error */}
      {staffOnboardingUploadList.error && (
        <ErrorAlert message="We couldn't load the staff onboarding files. Try refreshing the page." />
      )}
      {/* Staff permissions loading error */}
      {staffPermissionList.error && (
        <ErrorAlert message="We couldn't load the staff permissions. Try refreshing the page." />
      )}
      {/* Timesheets loading error */}
      {timesheetList.error && (
        <ErrorAlert message="We couldn't load the timesheet list. Try refreshing the page." />
      )}
      {/* Timesheet entries loading error */}
      {timesheetEntryList.error && (
        <ErrorAlert message="We couldn't load the timesheet entry list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Staff"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="staff-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="staff-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listStaffDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <TabSectionField
                        bottom
                        label={
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                staffDetails.staff.profile_photo
                                  ? staffDetails.staff.profile_photo
                                  : "/images/no_photo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "150px",
                                margin: "10px 0 10px 0",
                                objectFit: "contain",
                                width: "150px",
                              }}
                            />

                            <Text ellipsize>{staffDetails.staff.name}</Text>
                          </div>
                        }
                        value={
                          <TabSectionFieldMultifieldCard
                            children={
                              <>
                                {/* Id */}
                                <TabSectionField
                                  label="Id"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.id}
                                />

                                {/* Name */}
                                <TabSectionField
                                  label="Name"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.name_alias}
                                />

                                {/* Title */}
                                <TabSectionField
                                  label="Title"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.title}
                                />

                                {/* About */}
                                <TabSectionField
                                  label="About"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.about}
                                />

                                {/* Phone */}
                                <TabSectionField
                                  label="Phone"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.phone}
                                />

                                {/* Contact email */}
                                <TabSectionField
                                  label="Contact email"
                                  loading={staffDetails.loading}
                                  value={
                                    <TabSectionEmailLink
                                      email={staffDetails.staff.contact_email}
                                    />
                                  }
                                />

                                {/* LinkedIn page */}
                                <TabSectionField
                                  label="LinkedIn page"
                                  loading={staffDetails.loading}
                                  value={
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <TabSectionFieldLink
                                        url={
                                          staffDetails.staff.linkedin_page
                                            ? staffDetails.staff.linkedin_page
                                            : undefined
                                        }
                                      />
                                    </div>
                                  }
                                />

                                {/* Role */}
                                <TabSectionField
                                  label="Role"
                                  loading={staffDetails.loading}
                                  value={
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <TabSectionFieldLink
                                        name={
                                          staffDetails.staff.role_data
                                            ? staffDetails.staff.role_data.name
                                            : undefined
                                        }
                                        sameTab
                                        url={
                                          staffDetails.staff.role
                                            ? `/roles/${staffDetails.staff.role}`
                                            : undefined
                                        }
                                      />
                                    </div>
                                  }
                                />

                                {/* Resume */}
                                <TabSectionField
                                  label="Resume"
                                  loading={staffDetails.loading}
                                  value={
                                    <TabSectionFieldLink
                                      shorten
                                      url={staffDetails.staff.resume}
                                    />
                                  }
                                />

                                {/* Location */}
                                <TabSectionField
                                  helperText={
                                    staffDetails.staff.location_address
                                  }
                                  label="Location"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.location_name}
                                />

                                {/* Employment type */}
                                <TabSectionField
                                  label="Employment type"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.employment}
                                />

                                {/* Notes */}
                                <TabSectionField
                                  label="Notes"
                                  loading={staffDetails.loading}
                                  value={staffDetails.staff.notes}
                                />

                                {/* Manager */}
                                <TabSectionField
                                  bottom
                                  helperText={
                                    staffDetails.staff.user_access_status ===
                                    "view"
                                      ? "Contact to request edit access"
                                      : undefined
                                  }
                                  label="Manager"
                                  loading={staffDetails.loading}
                                  value={
                                    <NameValue
                                      email={staffDetails.staff.manager_name}
                                      image={
                                        staffDetails.staff.manager_profile_photo
                                      }
                                    />
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                    }
                    help="Review and update team member information"
                    icon="people"
                    title="Summary"
                  />

                  {/* Work hours */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listStaffDetailsAction(id));

                          setUpdateWorkHoursDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Custom work hours */}
                        <TabSectionField
                          bottom={!staffDetails.staff.custom_availability}
                          helperText={
                            staffDetails.staff.custom_availability
                              ? "This team member's work hours have been personalised"
                              : "This team member's work hours are determined by the organisation's default"
                          }
                          label="Custom work hours"
                          loading={staffDetails.loading}
                          value={
                            <Tag
                              intent={
                                staffDetails.staff.custom_availability
                                  ? "success"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {staffDetails.staff.custom_availability
                                ? "Enabled"
                                : "Disabled"}
                            </Tag>
                          }
                        />

                        {/* Capacity */}
                        {staffDetails.staff.custom_availability && (
                          <TabSectionField
                            bottom
                            label="Capacity"
                            loading={staffDetails.loading}
                            value={
                              <StaffAvailabilityCard
                                fill
                                staff={staffDetails.staff}
                              />
                            }
                          />
                        )}
                      </>
                    }
                    help="Define custom work hours for this team member"
                    icon="timeline-events"
                    title="Work hours"
                  />

                  {/* Permissions */}
                  {(staffDetails.staff.user_access_status === "owner" ||
                    staffDetails.staff.user_access_status === "admin" ||
                    staffDetails.staff.user_access_status === "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={staffPermissionList.loading}
                              setValueObject={setStaffPermissionQuery}
                              setValueVariable="searchString"
                              value={staffPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={staffPermissionList.staff_permissions.map(
                              (staff_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={staff_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listStaffPermissionDetailsAction(
                                          staff_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={staff_permission}
                                  />
                                );
                              }
                            )}
                            count={staffPermissionList.count}
                            loading={staffPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={staffPermissionQuery.page}
                            setPageObject={setStaffPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="All staff records are restricted. You need to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}
                </div>
              }
            />

            {/* Onboarding */}
            <Tab
              id="staff-onboarding-tab"
              title="Onboarding"
              panel={
                <div>
                  {/* Onboarding actions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddOnboardingActionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive={!showOnboardingActionKanban}
                                label="Completed"
                                loading={staffOnboardingActionList.loading}
                                onClick={() => {
                                  !showOnboardingActionKanban &&
                                    setStaffOnboardingActionQuery({
                                      ...staffOnboardingActionQuery,
                                      page: 1,
                                      status: "Completed",
                                    });
                                }}
                                value={
                                  staffOnboardingActionList.numberOfActionsCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive={!showOnboardingActionKanban}
                                label="In-progress"
                                loading={staffOnboardingActionList.loading}
                                onClick={() => {
                                  !showOnboardingActionKanban &&
                                    setStaffOnboardingActionQuery({
                                      ...staffOnboardingActionQuery,
                                      page: 1,
                                      status: "In-progress",
                                    });
                                }}
                                value={
                                  staffOnboardingActionList.numberOfActionsInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive={!showOnboardingActionKanban}
                                label="On-hold"
                                loading={staffOnboardingActionList.loading}
                                onClick={() => {
                                  !showOnboardingActionKanban &&
                                    setStaffOnboardingActionQuery({
                                      ...staffOnboardingActionQuery,
                                      page: 1,
                                      status: "On-hold",
                                    });
                                }}
                                value={
                                  staffOnboardingActionList.numberOfActionsOnHold
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Buttons */}
                        <ButtonGroup
                          style={{
                            background: lightMode
                              ? Colors.LIGHT_GRAY3
                              : Colors.DARK_GRAY5,
                            maxWidth: "100%",
                            minWidth: "100%",
                            padding: "10px 10px 0 10px",
                          }}
                        >
                          <Button
                            icon="th"
                            onClick={() => setShowOnboardingActionKanban(false)}
                          />

                          <Button
                            icon="list-columns"
                            onClick={() => {
                              setStaffOnboardingActionQuery({
                                ...staffOnboardingActionQueryDefault,
                              });

                              setInitialiseOnboardingActionKanban(true);

                              setShowOnboardingActionKanban(true);
                            }}
                          />
                        </ButtonGroup>

                        {/* Data table */}
                        {!showOnboardingActionKanban && (
                          <TableContainer2
                            body={staffOnboardingActionList.staff_onboarding_actions.map(
                              (staff_onboarding_action) => {
                                return (
                                  <tr
                                    key={staff_onboarding_action.id}
                                    onClick={() => {
                                      if (
                                        staffDetails.staff
                                          .user_access_status !== "view"
                                      ) {
                                        dispatch(
                                          listStaffOnboardingActionDetailsAction(
                                            staff_onboarding_action.id
                                          )
                                        );

                                        setUpdateOnboardingActionDrawerIsOpen(
                                          true
                                        );
                                      }
                                    }}
                                  >
                                    {/* Action */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5>
                                              {staff_onboarding_action.name}
                                            </H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Action</strong>
                                            </p>

                                            <p>
                                              {staff_onboarding_action.action}
                                            </p>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Comments</strong>
                                            </p>

                                            <p>
                                              {staff_onboarding_action.comments ? (
                                                staff_onboarding_action.comments
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No comments.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={staff_onboarding_action.name}
                                      />
                                    </td>

                                    {/* Owner */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        email={
                                          staff_onboarding_action.owner_name
                                        }
                                        image={
                                          staff_onboarding_action.owner_profile_photo
                                        }
                                        showImage
                                      />
                                    </td>

                                    {/* Due */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate
                                        date={staff_onboarding_action.due}
                                      />
                                    </td>

                                    {/* Status */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          staff_onboarding_action.status ===
                                          "Completed"
                                            ? "success"
                                            : staff_onboarding_action.status ===
                                              "In-progress"
                                            ? "warning"
                                            : "primary"
                                        }
                                        value={staff_onboarding_action.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !staffOnboardingActionQuery.dueEnd &&
                              !staffOnboardingActionQuery.dueStart &&
                              !staffOnboardingActionQuery.owner &&
                              !staffOnboardingActionQuery.searchString &&
                              !staffOnboardingActionQuery.status
                            }
                            count={staffOnboardingActionList.count}
                            head={
                              <tr>
                                {/* Action */}
                                <TableColumnHeadSearch
                                  queryObject={staffOnboardingActionQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setStaffOnboardingActionQuery}
                                  setQueryVariable="searchString"
                                  title="Action"
                                  width="200px"
                                />

                                {/* Owner */}
                                <TableColumnHeadSelect2
                                  filterObject={staffOnboardingActionQuery}
                                  filterVariable="owner"
                                  items={managerList.managers}
                                  setFilterObject={
                                    setStaffOnboardingActionQuery
                                  }
                                  setQueryObject={setManagerQuery}
                                  setQueryVariable="searchString"
                                  title="Owner"
                                  width="200px"
                                />

                                {/* Due */}
                                <TableColumnHeadDate
                                  endDateVariable="dueEnd"
                                  queryObject={staffOnboardingActionQuery}
                                  setQueryObject={setStaffOnboardingActionQuery}
                                  shortRange
                                  startDateVariable="dueStart"
                                  title="Due"
                                  width="125px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={staffOnboardingActionQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Completed",
                                      icon: "confirm",
                                      value: "Completed",
                                    },
                                    {
                                      display: "In-progress",
                                      icon: "refresh",
                                      value: "In-progress",
                                    },
                                    {
                                      display: "On-hold",
                                      icon: "remove",
                                      value: "On-hold",
                                    },
                                  ]}
                                  setFilterObject={
                                    setStaffOnboardingActionQuery
                                  }
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              staffDetails.staff.user_access_status !== "view"
                            }
                            loading={staffOnboardingActionList.loading}
                            noMargin
                            onClearFilters={() => {
                              setStaffOnboardingActionQuery({
                                ...staffOnboardingActionQueryDefault,
                              });
                            }}
                            page={staffOnboardingActionQuery.page}
                            setPageObject={setStaffOnboardingActionQuery}
                            setPageVariable="page"
                          />
                        )}

                        {/* Kanban */}
                        {showOnboardingActionKanban && (
                          <Kanban
                            centerColumns
                            data={generateKanbanDataFromStaffOnboardingActions(
                              staffDetails.staff.onboarding_actions
                            )}
                            initialise={initialiseOnboardingActionKanban}
                            noMargin
                            onClick={(id) => {
                              if (
                                staffDetails.staff.user_access_status !== "view"
                              ) {
                                dispatch(
                                  listStaffOnboardingActionDetailsAction(id)
                                );

                                setUpdateOnboardingActionDrawerIsOpen(true);
                              }
                            }}
                            onDragEnd={(result) => {
                              const actionId = result.draggableId;
                              const actionStatus =
                                result.destination.droppableId;

                              updateOnboardingActionStatus(
                                actionId,
                                actionStatus
                              );
                            }}
                            searchBarMargin="-45px 0 20px 70px"
                            searchBarPlaceholder="Search actions"
                            setInitialise={setInitialiseOnboardingActionKanban}
                            statusCategories={[
                              {
                                intent: "primary",
                                value: "On-hold",
                              },
                              {
                                intent: "warning",
                                value: "In-progress",
                              },
                              {
                                intent: "success",
                                value: "Completed",
                              },
                            ]}
                          />
                        )}
                      </>
                    }
                    help="Assign and review actions to onboard the team member"
                    icon="take-action"
                    message={
                      <>
                        advaise.app has prefilled this section with a standard
                        set of actions. Assign or delete them as appropriate.
                      </>
                    }
                    messageIntent="primary"
                    title="Onboarding actions"
                  />

                  {/* Onboarding files */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddOnboardingUploadDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            margin: "0 0 20px 0",
                          }}
                        >
                          <div style={{ margin: "0 10px 0 0" }}>Category</div>

                          <div style={{ width: "300px" }}>
                            <SelectField
                              options={[
                                { id: 1, label: "All", value: "" },
                                { id: 2, value: "Background Check" },
                                { id: 3, value: "Education Certificate" },
                                { id: 4, value: "Identity Document" },
                                { id: 5, value: "Declaration" },
                                { id: 6, value: "Employment Contract" },
                                { id: 7, value: "Reference" },
                                { id: 8, value: "Other" },
                              ]}
                              setValueObject={setStaffOnboardingUploadQuery}
                              setValueVariable="category"
                              value={staffOnboardingUploadQuery.category}
                            />
                          </div>
                        </div>

                        <ItemCardList
                          content={staffOnboardingUploadList.staff_onboarding_uploads.map(
                            (staff_onboarding_upload) => {
                              return (
                                <DocumentCard
                                  interactive
                                  key={staff_onboarding_upload.id}
                                  onClick={() => {
                                    dispatch(
                                      listStaffOnboardingUploadDetailsAction(
                                        staff_onboarding_upload.id
                                      )
                                    );

                                    setUpdateOnboardingUploadDrawerIsOpen(true);
                                  }}
                                  title={staff_onboarding_upload.name}
                                  type={staff_onboarding_upload.type}
                                />
                              );
                            }
                          )}
                          count={staffOnboardingUploadList.count}
                          loading={staffOnboardingUploadList.loading}
                          noMargin
                          noResultsMessage="Add files"
                          noResultsTitle="No files found"
                          page={staffOnboardingUploadQuery.page}
                          setPageObject={setStaffOnboardingUploadQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage onboarding documents"
                    icon="folder-open"
                    title="Onboarding files"
                  />
                </div>
              }
            />

            {/* Development */}
            <Tab
              id="staff-development-tab"
              title="Development"
              panel={
                <div>
                  {/* Competency chart */}
                  {staffDetails.staff.role && (
                    <TabSectionChartContainer
                      chart={
                        <CompetencyChart
                          data={staffDetails.staff.competency_profile}
                          roleDescription={
                            staffDetails.staff.role_data
                              ? staffDetails.staff.role_data.description
                              : undefined
                          }
                          roleFitScore={roleFitScore}
                          roleTitle={
                            staffDetails.staff.role_data
                              ? staffDetails.staff.role_data.name
                              : undefined
                          }
                        />
                      }
                      title="Competencies"
                    />
                  )}

                  {/* Goals */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddGoalDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Exceeded */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Exceeded"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Exceeded",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsExceeded}
                              />

                              {/* Met */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="Met"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Met",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsMet}
                              />

                              {/* Not met */}
                              <DataCardSimple
                                intent="danger"
                                interactive
                                label="Not met"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Not met",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsNotMet}
                              />

                              {/* Not rated */}
                              <DataCardSimple
                                intent="primary"
                                interactive
                                label="Not rated"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Not rated",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsNotRated}
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={staffGoalList.staff_goals.map((staff_goal) => {
                            return (
                              <tr
                                key={staff_goal.id}
                                onClick={() => {
                                  if (
                                    staffDetails.staff.user_access_status !==
                                    "view"
                                  ) {
                                    dispatch(
                                      listStaffGoalDetailsAction(staff_goal.id)
                                    );

                                    setUpdateGoalDrawerIsOpen(true);
                                  }
                                }}
                              >
                                {/* Goal */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    info={
                                      <>
                                        <H5>{staff_goal.name}</H5>

                                        <Divider
                                          style={{
                                            background: lightMode
                                              ? Colors.GRAY1
                                              : Colors.GRAY5,
                                            margin: "10px 0 10px 0",
                                          }}
                                        />

                                        <p>
                                          <strong>Goal</strong>
                                        </p>

                                        <p>{staff_goal.goal}</p>

                                        <Divider
                                          style={{
                                            background: lightMode
                                              ? Colors.GRAY1
                                              : Colors.GRAY5,
                                            margin: "10px 0 10px 0",
                                          }}
                                        />

                                        <p>
                                          <strong>Comments</strong>
                                        </p>

                                        <p>
                                          {staff_goal.comments ? (
                                            staff_goal.comments
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )}
                                        </p>
                                      </>
                                    }
                                    text={staff_goal.name}
                                  />
                                </td>

                                {/* Year */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag value={staff_goal.year} />
                                </td>

                                {/* Due */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={staff_goal.due} />
                                </td>

                                {/* Rating */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      staff_goal.rating === "Exceeded"
                                        ? "success"
                                        : staff_goal.rating === "Met"
                                        ? "warning"
                                        : staff_goal.rating === "Not met"
                                        ? "danger"
                                        : "primary"
                                    }
                                    value={staff_goal.rating}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !staffGoalQuery.dueEnd &&
                            !staffGoalQuery.dueStart &&
                            !staffGoalQuery.rating &&
                            !staffGoalQuery.searchString &&
                            !staffGoalQuery.year
                          }
                          count={staffGoalList.count}
                          head={
                            <tr>
                              {/* Goal */}
                              <TableColumnHeadSearch
                                queryObject={staffGoalQuery}
                                queryVariable="searchString"
                                setQueryObject={setStaffGoalQuery}
                                setQueryVariable="searchString"
                                title="Goal"
                                width="200px"
                              />

                              {/* Year */}
                              <TableColumnHeadSelectSimple
                                filterObject={staffGoalQuery}
                                filterVariable="year"
                                options={generateYearList}
                                setFilterObject={setStaffGoalQuery}
                                title="Year"
                                width="125px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={staffGoalQuery}
                                setQueryObject={setStaffGoalQuery}
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Rating */}
                              <TableColumnHeadSelectSimple
                                filterObject={staffGoalQuery}
                                filterVariable="rating"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Exceeded",
                                    icon: "arrow-up",
                                    value: "Exceeded",
                                  },
                                  {
                                    display: "Met",
                                    icon: "arrow-right",
                                    value: "Met",
                                  },
                                  {
                                    display: "Not met",
                                    icon: "arrow-down",
                                    value: "Not met",
                                  },
                                  {
                                    display: "Not rated",
                                    icon: "remove",
                                    value: "Not rated",
                                  },
                                ]}
                                setFilterObject={setStaffGoalQuery}
                                title="Rating"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            staffDetails.staff.user_access_status !== "view"
                          }
                          loading={staffGoalList.loading}
                          noMargin
                          onClearFilters={() => {
                            setStaffGoalQuery({
                              ...staffGoalQueryDefault,
                            });
                          }}
                          page={staffGoalQuery.page}
                          setPageObject={setStaffGoalQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Set expectations and review performance"
                    icon="locate"
                    title="Goals"
                  />

                  {/* Competencies */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            staffDetails.staff.user_access_status === "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddCompetencyDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <GenerateFromFileButton
                          disabled={
                            !staff.resume ||
                            staffCompetencyList.count > 0 ||
                            !staffDetails.staff.competency_profile ||
                            !staffDetails.staff.name_alias ||
                            staffDetails.staff.user_access_status === "view"
                          }
                          filename={staff.resume}
                          objectDescription={`${
                            staffDetails.staff.name_alias
                          }, who may have the following competencies: ${
                            staffDetails.staff.competency_profile
                              ? staffDetails.staff.competency_profile
                                  .map(
                                    (competency) =>
                                      `${competency.competency} (id: ${competency.id}, description: ${competency.description})`
                                  )
                                  .join(", ")
                              : undefined
                          }`}
                          objectType="staff"
                          onSuccess={(result) => {
                            if (
                              !("competencies" in result) ||
                              result.competencies.length === 0
                            ) {
                              AppToaster.show({
                                icon: "warning-sign",
                                intent: "warning",
                                message: "Unable to generate suitable content.",
                              });
                            } else {
                              AppToaster.show({
                                icon: "tick-circle",
                                intent: "success",
                                message: "Generation complete.",
                              });
                            }

                            dispatch(
                              uploadStaffCompetenciesAction(
                                id,
                                result.competencies
                              )
                            );
                          }}
                          text="Evaluate"
                        />
                      </>
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search competency"
                            searching={staffCompetencyList.loading}
                            setValueObject={setStaffCompetencyQuery}
                            setValueVariable="searchString"
                            value={staffCompetencyQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={staffCompetencyList.staff_competencies.map(
                            (staff_competency, i, { length }) => {
                              return (
                                <TabSectionField
                                  bottom={length - 1 === i}
                                  key={staff_competency.id}
                                  label={
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        {staff_competency.competency_data.name}
                                      </div>

                                      <div style={{ marginLeft: "5px" }}>
                                        <InfoPopup
                                          content={
                                            <CompetencyInfo
                                              competencyData={
                                                staff_competency.competency_data
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    </div>
                                  }
                                  value={
                                    <TabSectionFieldMultifieldCard
                                      children={
                                        <>
                                          {/* Level */}
                                          <TabSectionField
                                            helperText={
                                              staff_competency.level === 5
                                                ? "Expert"
                                                : staff_competency.level >= 3
                                                ? "Intermediate"
                                                : "Beginner"
                                            }
                                            label="Level"
                                            value={
                                              <TabSectionFieldProgressBar
                                                intent="success"
                                                rating={
                                                  staff_competency.level / 5
                                                }
                                              />
                                            }
                                          />

                                          {/* Comments */}
                                          <TabSectionField
                                            bottom
                                            label="Comments"
                                            value={staff_competency.comments}
                                          />
                                        </>
                                      }
                                      interactive={
                                        staffDetails.staff
                                          .user_access_status !== "view"
                                      }
                                      onClick={() => {
                                        if (
                                          staffDetails.staff
                                            .user_access_status !== "view"
                                        ) {
                                          dispatch(
                                            listStaffCompetencyDetailsAction(
                                              staff_competency.id
                                            )
                                          );

                                          setUpdateCompetencyDrawerIsOpen(true);
                                        }
                                      }}
                                    />
                                  }
                                />
                              );
                            }
                          )}
                          count={staffCompetencyList.count}
                          loading={staffCompetencyList.loading}
                          noResultsMessage="Add competencies."
                          noResultsTitle="No competencies found"
                          page={staffCompetencyQuery.page}
                          setPageObject={setStaffCompetencyQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Identify the team member's competencies"
                    icon="build"
                    title="Competencies"
                  />

                  {/* Development plan */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddDevelopmentPlanDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Completed"
                                loading={staffDevelopmentPlanList.loading}
                                onClick={() => {
                                  setStaffDevelopmentPlanQuery({
                                    ...staffDevelopmentPlanQuery,
                                    page: 1,
                                    status: "Completed",
                                  });
                                }}
                                value={
                                  staffDevelopmentPlanList.numberOfDevelopmentPlansCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="In-progress"
                                loading={staffDevelopmentPlanList.loading}
                                onClick={() => {
                                  setStaffDevelopmentPlanQuery({
                                    ...staffDevelopmentPlanQuery,
                                    page: 1,
                                    status: "In-progress",
                                  });
                                }}
                                value={
                                  staffDevelopmentPlanList.numberOfDevelopmentPlansInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive
                                label="On-hold"
                                loading={staffDevelopmentPlanList.loading}
                                onClick={() => {
                                  setStaffDevelopmentPlanQuery({
                                    ...staffDevelopmentPlanQuery,
                                    page: 1,
                                    status: "On-hold",
                                  });
                                }}
                                value={
                                  staffDevelopmentPlanList.numberOfDevelopmentPlansOnHold
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={staffDevelopmentPlanList.staff_development_plans.map(
                            (staff_development_plan) => {
                              return (
                                <tr
                                  key={staff_development_plan.id}
                                  onClick={() => {
                                    if (
                                      staffDetails.staff.user_access_status !==
                                      "view"
                                    ) {
                                      dispatch(
                                        listStaffDevelopmentPlanDetailsAction(
                                          staff_development_plan.id
                                        )
                                      );

                                      setUpdateDevelopmentPlanDrawerIsOpen(
                                        true
                                      );
                                    }
                                  }}
                                >
                                  {/* Development plan */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{staff_development_plan.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Development plan</strong>
                                          </p>

                                          <p>
                                            {
                                              staff_development_plan.development_plan
                                            }
                                          </p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Comments</strong>
                                          </p>

                                          <p>
                                            {staff_development_plan.comments ? (
                                              staff_development_plan.comments
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={staff_development_plan.name}
                                    />
                                  </td>

                                  {/* Due */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableDate
                                      date={staff_development_plan.due}
                                    />
                                  </td>

                                  {/* Status */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      intent={
                                        staff_development_plan.status ===
                                        "Completed"
                                          ? "success"
                                          : staff_development_plan.status ===
                                            "In-progress"
                                          ? "warning"
                                          : "primary"
                                      }
                                      value={staff_development_plan.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !staffDevelopmentPlanQuery.dueEnd &&
                            !staffDevelopmentPlanQuery.dueStart &&
                            !staffDevelopmentPlanQuery.searchString &&
                            !staffDevelopmentPlanQuery.status
                          }
                          count={staffDevelopmentPlanList.count}
                          head={
                            <tr>
                              {/* Development plan */}
                              <TableColumnHeadSearch
                                queryObject={staffDevelopmentPlanQuery}
                                queryVariable="searchString"
                                setQueryObject={setStaffDevelopmentPlanQuery}
                                setQueryVariable="searchString"
                                title="Development plan"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={staffDevelopmentPlanQuery}
                                setQueryObject={setStaffDevelopmentPlanQuery}
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={staffDevelopmentPlanQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Completed",
                                    icon: "confirm",
                                    value: "Completed",
                                  },
                                  {
                                    display: "In-progress",
                                    icon: "refresh",
                                    value: "In-progress",
                                  },
                                  {
                                    display: "On-hold",
                                    icon: "remove",
                                    value: "On-hold",
                                  },
                                ]}
                                setFilterObject={setStaffDevelopmentPlanQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            staffDetails.staff.user_access_status !== "view"
                          }
                          loading={staffDevelopmentPlanList.loading}
                          noMargin
                          onClearFilters={() => {
                            setStaffDevelopmentPlanQuery({
                              ...staffDevelopmentPlanQueryDefault,
                            });
                          }}
                          page={staffDevelopmentPlanQuery.page}
                          setPageObject={setStaffDevelopmentPlanQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage actions to upskill the team member"
                    icon="learning"
                    title="Development plan"
                  />
                </div>
              }
            />

            {/* Assignments */}
            <Tab
              id="staff-assignment-tab"
              title="Assignments"
              panel={
                <div>
                  {/* Booking chart */}
                  <TabSectionChartContainer
                    chart={
                      <BookingChart2
                        data={bookingList.bookingProfile}
                        setStaffQuery={setBookingQuery}
                        utilisationAnalysis={utilisationAnalysis}
                        utilisationScore={utilisationScore}
                      />
                    }
                    title="Bookings"
                  />

                  {/* Bookings */}
                  <TabSection2
                    buttons={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          disabled={
                            staffDetails.staff.user_access_status === "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddBookingDrawerIsOpen(true);
                          }}
                          text="Add"
                        />

                        <Button
                          icon="maximize"
                          onClick={() => {
                            setBookingsDialogIsOpen(true);
                          }}
                        />
                      </div>
                    }
                    content={
                      <>
                        {/* Timeline */}
                        {bookingList.count > 0 && (
                          <Timeline4
                            categoryOneColor={Colors.BLUE3}
                            categoryOneValue="Confirmed"
                            categoryThreeColor={Colors.ORANGE5}
                            categoryThreeValue="Tentative"
                            categoryTwoColor={Colors.GREEN3}
                            categoryTwoValue="Realised"
                            data={generateTimelineDataFromBookings(
                              bookingList.bookings
                            )}
                            height="70vh"
                            hideExpandButton
                            itemListHeading="Booking code"
                            loading={bookingList.loading}
                            onClick={(id, type) => {
                              if (
                                staffDetails.staff.user_access_status !== "view"
                              ) {
                                const booking = bookingList.bookings.filter(
                                  (booking) => booking.id === Number(id)
                                )[0];

                                if (booking.type === "Realised") {
                                  AppToaster.show({
                                    icon: "warning-sign",
                                    intent: "warning",
                                    message:
                                      "You cannot edit realised bookings directly, as they are based on timesheets.",
                                  });
                                } else {
                                  dispatch(listBookingDetailsAction(id));

                                  setUpdateBookingDrawerIsOpen(true);
                                }
                              }
                            }}
                            showDates
                            tableTop
                          />
                        )}

                        {/* Data table */}
                        <TableContainer2
                          body={bookingList.bookings.map((booking) => {
                            return (
                              <tr
                                key={booking.id}
                                onClick={() => {
                                  if (booking.type === "Realised") {
                                    AppToaster.show({
                                      icon: "warning-sign",
                                      intent: "warning",
                                      message:
                                        "You cannot edit realised bookings directly, as they are based on timesheets.",
                                    });
                                  } else if (
                                    staffDetails.staff.user_access_status !==
                                    "view"
                                  ) {
                                    dispatch(
                                      listBookingDetailsAction(booking.id)
                                    );

                                    setUpdateBookingDrawerIsOpen(true);
                                  }
                                }}
                              >
                                {/* Booking code */}
                                <td style={{ verticalAlign: "middle" }}>
                                  {booking.booking_code_data ? (
                                    <TableText
                                      info={
                                        <BookingInfo bookingData={booking} />
                                      }
                                      text={booking.booking_code_data.name}
                                    />
                                  ) : undefined}
                                </td>

                                {/* Billable */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {booking.booking_code_data ? (
                                    booking.booking_code_data.billable !==
                                    null ? (
                                      <TableTag
                                        intent={
                                          booking.booking_code_data.billable
                                            ? "success"
                                            : "primary"
                                        }
                                        large
                                        minimal
                                        style={{
                                          textAlign: "center",
                                          minWidth: "100px",
                                        }}
                                        value={
                                          booking.booking_code_data.billable
                                            ? "Billable"
                                            : "Not billable"
                                        }
                                      />
                                    ) : undefined
                                  ) : undefined}
                                </td>

                                {/* Type */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      booking.type === "Confirmed"
                                        ? "primary"
                                        : booking.type === "Realised"
                                        ? "success"
                                        : "warning"
                                    }
                                    value={booking.type}
                                  />
                                </td>

                                {/* Effort */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableTag
                                    value={`${
                                      convertMinutesToHoursAndMinutes(
                                        booking.minutes
                                      ).split(":")[0]
                                    }h ${
                                      convertMinutesToHoursAndMinutes(
                                        booking.minutes
                                      ).split(":")[1]
                                    }m`}
                                  />
                                </td>

                                {/* Start */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={booking.start} />
                                </td>

                                {/* End */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={booking.end} />
                                </td>

                                {/* Utilisation */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      booking.utilisation > 110
                                        ? "danger"
                                        : booking.utilisation > 100
                                        ? "warning"
                                        : "success"
                                    }
                                    value={
                                      <PercentageValue
                                        percentage={booking.utilisation}
                                      />
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !bookingQuery.bookingCode &&
                            !bookingQuery.billable &&
                            !bookingQuery.endEnd &&
                            !bookingQuery.endStart &&
                            !bookingQuery.startEnd &&
                            !bookingQuery.startStart &&
                            !bookingQuery.type
                          }
                          count={bookingList.count}
                          head={
                            <tr>
                              {/* Booking code */}
                              <TableColumnHeadSelect2
                                filterObject={bookingQuery}
                                filterVariable="bookingCode"
                                items={bookingCodeList.booking_codes}
                                setFilterObject={setBookingQuery}
                                setQueryObject={setBookingCodeQuery}
                                setQueryVariable="searchString"
                                title="Booking code"
                                width="200px"
                              />

                              {/* Billable */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="billable"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Billable",
                                    icon: "dollar",
                                    value: "true",
                                  },
                                  {
                                    display: "Not billable",
                                    icon: "cross",
                                    value: "false",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Billable"
                                width="125px"
                              />

                              {/* Type */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="type"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Confirmed",
                                    icon: "circle",
                                    value: "Confirmed",
                                  },
                                  {
                                    display: "Realised",
                                    icon: "confirm",
                                    value: "Realised",
                                  },
                                  {
                                    display: "Tentative",
                                    icon: "remove",
                                    value: "Tentative",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Type"
                                width="125px"
                              />

                              {/* Effort */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "minutes",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-minutes",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Effort"
                                width="125px"
                              />

                              {/* Start */}
                              <TableColumnHeadDate
                                endDateVariable="startEnd"
                                queryObject={bookingQuery}
                                setQueryObject={setBookingQuery}
                                shortRange
                                startDateVariable="startStart"
                                title="Start"
                                width="125px"
                              />

                              {/* End */}
                              <TableColumnHeadDate
                                endDateVariable="endEnd"
                                queryObject={bookingQuery}
                                setQueryObject={setBookingQuery}
                                shortRange
                                startDateVariable="endStart"
                                title="End"
                                width="125px"
                              />

                              {/* Utilisation */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "utilisation",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-utilisation",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Utilisation"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            staffDetails.staff.user_access_status !== "view"
                          }
                          loading={bookingList.loading}
                          noMargin
                          onClearFilters={() => {
                            setBookingQuery({
                              ...bookingQueryDefault,
                            });
                          }}
                          page={bookingQuery.page}
                          setPageObject={setBookingQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage staff bookings"
                    icon="timeline-events"
                    title="Bookings"
                  />

                  {/* Timesheets */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddTimesheetDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={timesheetList.timesheets.map((timesheet) => {
                            return (
                              <tr
                                key={timesheet.id}
                                onClick={() => {
                                  if (
                                    staffDetails.staff.user_access_status !==
                                    "view"
                                  ) {
                                    dispatch(
                                      listTimesheetDetailsAction(timesheet.id)
                                    );

                                    setTimesheetEntryQuery({
                                      page: 1,
                                      timesheet: timesheet.id,
                                    });

                                    setUpdateTimesheetDrawerIsOpen(true);
                                  }
                                }}
                              >
                                {/* Week */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <TableText text={timesheet.week_label} />
                                    <div style={{ marginLeft: "5px" }}>
                                      <Button
                                        icon="duplicate"
                                        minimal
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          dispatch(
                                            duplicateTimesheetAction(
                                              timesheet.id
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>

                                {/* Submitted */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={timesheet.submitted} />
                                </td>

                                {/* Reviewed */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={timesheet.completed} />
                                </td>

                                {/* Status */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      timesheet.status === "Approved"
                                        ? "success"
                                        : timesheet.status === "Draft"
                                        ? "primary"
                                        : timesheet.status === "Rejected"
                                        ? "danger"
                                        : "warning"
                                    }
                                    value={timesheet.status}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !timesheetQuery.completedEnd &&
                            !timesheetQuery.completedStart &&
                            !timesheetQuery.status &&
                            !timesheetQuery.submittedEnd &&
                            !timesheetQuery.submittedStart &&
                            !timesheetQuery.weekLabel
                          }
                          count={timesheetList.count}
                          head={
                            <tr>
                              {/* Week */}
                              <TableColumnHeadSearch
                                queryObject={timesheetQuery}
                                queryVariable="weekLabel"
                                setQueryObject={setTimesheetQuery}
                                setQueryVariable="weekLabel"
                                title="Week"
                                width="200px"
                              />

                              {/* Submitted */}
                              <TableColumnHeadDate
                                endDateVariable="submittedEnd"
                                queryObject={timesheetQuery}
                                setQueryObject={setTimesheetQuery}
                                shortRange
                                startDateVariable="submittedStart"
                                title="Submitted"
                                width="125px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="completedEnd"
                                queryObject={timesheetQuery}
                                setQueryObject={setTimesheetQuery}
                                shortRange
                                startDateVariable="completedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={timesheetQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Draft",
                                    icon: "manually-entered-data",
                                    value: "Draft",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "document-share",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "tick",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Rejected",
                                    icon: "cross",
                                    value: "Rejected",
                                  },
                                  {
                                    display: "Reversed",
                                    icon: "undo",
                                    value: "Reversed",
                                  },
                                ]}
                                setFilterObject={setTimesheetQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            staffDetails.staff.user_access_status !== "view"
                          }
                          loading={timesheetList.loading}
                          noMargin
                          onClearFilters={() => {
                            setTimesheetQuery({
                              ...timesheetQueryDefault,
                            });
                          }}
                          page={timesheetQuery.page}
                          setPageObject={setTimesheetQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage timesheets"
                    icon="stopwatch"
                    title="Timesheets"
                  />

                  {/* Expenses */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddClaimDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={claimList.claims.map((claim) => {
                            return (
                              <tr
                                key={claim.id}
                                onClick={() => {
                                  if (
                                    staffDetails.staff.user_access_status !==
                                    "view"
                                  ) {
                                    dispatch(listClaimDetailsAction(claim.id));

                                    setExpenseQuery({
                                      claim: claim.id,
                                      organisation: activeOrganisation.id,
                                      page: 1,
                                    });

                                    setUpdateClaimDrawerIsOpen(true);
                                  }
                                }}
                              >
                                {/* Title */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <TableText text={claim.name} />

                                    <div style={{ marginLeft: "5px" }}>
                                      <Button
                                        icon="duplicate"
                                        minimal
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          dispatch(
                                            duplicateClaimAction(claim.id)
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>

                                {/* Submitted */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={claim.submitted} />
                                </td>

                                {/* Reviewed */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={claim.completed} />
                                </td>

                                {/* Status */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      claim.status === "Approved"
                                        ? "success"
                                        : claim.status === "Draft"
                                        ? "primary"
                                        : claim.status === "Paid"
                                        ? "success"
                                        : claim.status === "Rejected"
                                        ? "danger"
                                        : "warning"
                                    }
                                    value={claim.status}
                                  />
                                </td>

                                {/* Value */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber number={claim.value} />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !claimQuery.completedEnd &&
                            !claimQuery.completedStart &&
                            !claimQuery.searchString &&
                            !claimQuery.status &&
                            !claimQuery.submittedEnd &&
                            !claimQuery.submittedStart
                          }
                          count={claimList.count}
                          footnote={
                            <div>Figures in {activeOrganisation.currency}</div>
                          }
                          head={
                            <tr>
                              {/* Claim */}
                              <TableColumnHeadSearch
                                queryObject={claimQuery}
                                queryVariable="searchString"
                                setQueryObject={setClaimQuery}
                                setQueryVariable="searchString"
                                title="Claim"
                                width="200px"
                              />

                              {/* Submitted */}
                              <TableColumnHeadDate
                                endDateVariable="submittedEnd"
                                queryObject={claimQuery}
                                setQueryObject={setClaimQuery}
                                shortRange
                                startDateVariable="submittedStart"
                                title="Submitted"
                                width="125px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="completedEnd"
                                queryObject={claimQuery}
                                setQueryObject={setClaimQuery}
                                shortRange
                                startDateVariable="completedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={claimQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Draft",
                                    icon: "manually-entered-data",
                                    value: "Draft",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "document-share",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "tick",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Paid",
                                    icon: "bank-account",
                                    value: "Paid",
                                  },
                                  {
                                    display: "Rejected",
                                    icon: "cross",
                                    value: "Rejected",
                                  },
                                  {
                                    display: "Reversed",
                                    icon: "undo",
                                    value: "Reversed",
                                  },
                                ]}
                                setFilterObject={setClaimQuery}
                                title="Status"
                                width="125px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={claimQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "id",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-id",
                                  },
                                ]}
                                setFilterObject={setClaimQuery}
                                title="Value"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            staffDetails.staff.user_access_status !== "view"
                          }
                          loading={claimList.loading}
                          noMargin
                          onClearFilters={() => {
                            setClaimQuery({
                              ...claimQueryDefault,
                            });
                          }}
                          page={claimQuery.page}
                          setPageObject={setClaimQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage expense claims"
                    icon="bank-account"
                    title="Expenses"
                  />

                  {/* Leave requests */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddLeaveRequestDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={leaveRequestList.leave_requests.map(
                            (leave_request) => {
                              return (
                                <tr
                                  key={leave_request.id}
                                  onClick={() => {
                                    dispatch(
                                      listLeaveRequestDetailsAction(
                                        leave_request.id
                                      )
                                    );

                                    setUpdateLeaveRequestDrawerIsOpen(true);
                                  }}
                                >
                                  {/* Week */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={leave_request.week_label}
                                    />
                                  </td>

                                  {/* Type */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={leave_request.category_name}
                                    />
                                  </td>

                                  {/* Submitted */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={leave_request.submitted} />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={leave_request.completed} />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        leave_request.status === "Approved"
                                          ? "success"
                                          : leave_request.status === "Draft"
                                          ? "primary"
                                          : leave_request.status === "Rejected"
                                          ? "danger"
                                          : "warning"
                                      }
                                      value={leave_request.status}
                                    />
                                  </td>

                                  {/* Time */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      value={`${
                                        convertMinutesToHoursAndMinutes(
                                          leave_request.total_minutes
                                        ).split(":")[0]
                                      }h ${
                                        convertMinutesToHoursAndMinutes(
                                          leave_request.total_minutes
                                        ).split(":")[1]
                                      }m`}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !leaveRequestQuery.category &&
                            !leaveRequestQuery.completedEnd &&
                            !leaveRequestQuery.completedStart &&
                            !leaveRequestQuery.status &&
                            !leaveRequestQuery.submittedEnd &&
                            !leaveRequestQuery.submittedStart &&
                            !leaveRequestQuery.weekLabel
                          }
                          count={leaveRequestList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="5"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    Hours
                                  </Tag>
                                </div>
                              </td>

                              {/* Total */}
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {leaveRequestList.totalMinutes
                                  ? `${
                                      convertMinutesToHoursAndMinutes(
                                        leaveRequestList.totalMinutes
                                      ).split(":")[0]
                                    }h ${
                                      convertMinutesToHoursAndMinutes(
                                        leaveRequestList.totalMinutes
                                      ).split(":")[1]
                                    }m`
                                  : "-"}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Week */}
                              <TableColumnHeadSearch
                                queryObject={leaveRequestQuery}
                                queryVariable="weekLabel"
                                setQueryObject={setLeaveRequestQuery}
                                setQueryVariable="weekLabel"
                                title="Week"
                                width="200px"
                              />

                              {/* Type */}
                              <TableColumnHeadSelect2
                                filterObject={leaveRequestQuery}
                                filterVariable="category"
                                items={leaveCategoryList.leave_categories}
                                setFilterObject={setLeaveRequestQuery}
                                setQueryObject={setLeaveCategoryQuery}
                                setQueryVariable="searchString"
                                title="Type"
                                width="200px"
                              />

                              {/* Submitted */}
                              <TableColumnHeadDate
                                endDateVariable="submittedEnd"
                                queryObject={leaveRequestQuery}
                                setQueryObject={setLeaveRequestQuery}
                                shortRange
                                startDateVariable="submittedStart"
                                title="Submitted"
                                width="125px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="completedEnd"
                                queryObject={leaveRequestQuery}
                                setQueryObject={setLeaveRequestQuery}
                                shortRange
                                startDateVariable="completedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={leaveRequestQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Draft",
                                    icon: "manually-entered-data",
                                    value: "Draft",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "document-share",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "tick",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Rejected",
                                    icon: "cross",
                                    value: "Rejected",
                                  },
                                  {
                                    display: "Cancelled",
                                    icon: "undo",
                                    value: "Cancelled",
                                  },
                                ]}
                                setFilterObject={setLeaveRequestQuery}
                                title="Status"
                                width="125px"
                              />

                              {/* Time */}
                              <TableColumnHeadSelectSimple
                                filterObject={leaveRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "total_minutes",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-total_minutes",
                                  },
                                ]}
                                setFilterObject={setLeaveRequestQuery}
                                title="Time"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={leaveRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setLeaveRequestQuery({
                              ...leaveRequestQueryDefault,
                            });
                          }}
                          page={leaveRequestQuery.page}
                          setPageObject={setLeaveRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage leave requests"
                    icon="key-escape"
                    title="Leave requests"
                  />
                </div>
              }
            />

            {/* Exit */}
            <Tab
              id="staff-exit-tab"
              title="Exit"
              panel={
                <div>
                  {/* Exit actions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddExitActionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive={!showExitActionKanban}
                                label="Completed"
                                loading={staffExitActionList.loading}
                                onClick={() => {
                                  !showExitActionKanban &&
                                    setStaffExitActionQuery({
                                      ...staffExitActionQuery,
                                      page: 1,
                                      status: "Completed",
                                    });
                                }}
                                value={
                                  staffExitActionList.numberOfActionsCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive={!showExitActionKanban}
                                label="In-progress"
                                loading={staffExitActionList.loading}
                                onClick={() => {
                                  !showExitActionKanban &&
                                    setStaffExitActionQuery({
                                      ...staffExitActionQuery,
                                      page: 1,
                                      status: "In-progress",
                                    });
                                }}
                                value={
                                  staffExitActionList.numberOfActionsInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive={!showExitActionKanban}
                                label="On-hold"
                                loading={staffExitActionList.loading}
                                onClick={() => {
                                  !showExitActionKanban &&
                                    setStaffExitActionQuery({
                                      ...staffExitActionQuery,
                                      page: 1,
                                      status: "On-hold",
                                    });
                                }}
                                value={
                                  staffExitActionList.numberOfActionsOnHold
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Buttons */}
                        <ButtonGroup
                          style={{
                            background: lightMode
                              ? Colors.LIGHT_GRAY3
                              : Colors.DARK_GRAY5,
                            maxWidth: "100%",
                            minWidth: "100%",
                            padding: "10px 10px 0 10px",
                          }}
                        >
                          <Button
                            icon="th"
                            onClick={() => setShowExitActionKanban(false)}
                          />

                          <Button
                            icon="list-columns"
                            onClick={() => {
                              setStaffExitActionQuery({
                                ...staffExitActionQueryDefault,
                              });

                              setInitialiseExitActionKanban(true);

                              setShowExitActionKanban(true);
                            }}
                          />
                        </ButtonGroup>

                        {/* Data table */}
                        {!showExitActionKanban && (
                          <TableContainer2
                            body={staffExitActionList.staff_exit_actions.map(
                              (staff_exit_action) => {
                                return (
                                  <tr
                                    key={staff_exit_action.id}
                                    onClick={() => {
                                      if (
                                        staffDetails.staff
                                          .user_access_status !== "view"
                                      ) {
                                        dispatch(
                                          listStaffExitActionDetailsAction(
                                            staff_exit_action.id
                                          )
                                        );

                                        setUpdateExitActionDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Action */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5>{staff_exit_action.name}</H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Action</strong>
                                            </p>

                                            <p>{staff_exit_action.action}</p>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Comments</strong>
                                            </p>

                                            <p>
                                              {staff_exit_action.comments ? (
                                                staff_exit_action.comments
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No comments.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={staff_exit_action.name}
                                      />
                                    </td>

                                    {/* Owner */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        email={staff_exit_action.owner_name}
                                        image={
                                          staff_exit_action.owner_profile_photo
                                        }
                                        showImage
                                      />
                                    </td>

                                    {/* Due */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={staff_exit_action.due} />
                                    </td>

                                    {/* Status */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          staff_exit_action.status ===
                                          "Completed"
                                            ? "success"
                                            : staff_exit_action.status ===
                                              "In-progress"
                                            ? "warning"
                                            : "primary"
                                        }
                                        value={staff_exit_action.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !staffExitActionQuery.dueEnd &&
                              !staffExitActionQuery.dueStart &&
                              !staffExitActionQuery.owner &&
                              !staffExitActionQuery.searchString &&
                              !staffExitActionQuery.status
                            }
                            count={staffExitActionList.count}
                            head={
                              <tr>
                                {/* Action */}
                                <TableColumnHeadSearch
                                  queryObject={staffExitActionQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setStaffExitActionQuery}
                                  setQueryVariable="searchString"
                                  title="Action"
                                  width="200px"
                                />

                                {/* Owner */}
                                <TableColumnHeadSelect2
                                  filterObject={staffExitActionQuery}
                                  filterVariable="owner"
                                  items={managerList.managers}
                                  setFilterObject={setStaffExitActionQuery}
                                  setQueryObject={setManagerQuery}
                                  setQueryVariable="searchString"
                                  title="Owner"
                                  width="200px"
                                />

                                {/* Due */}
                                <TableColumnHeadDate
                                  endDateVariable="dueEnd"
                                  queryObject={staffExitActionQuery}
                                  setQueryObject={setStaffExitActionQuery}
                                  shortRange
                                  startDateVariable="dueStart"
                                  title="Due"
                                  width="125px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={staffExitActionQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Completed",
                                      icon: "confirm",
                                      value: "Completed",
                                    },
                                    {
                                      display: "In-progress",
                                      icon: "refresh",
                                      value: "In-progress",
                                    },
                                    {
                                      display: "On-hold",
                                      icon: "remove",
                                      value: "On-hold",
                                    },
                                  ]}
                                  setFilterObject={setStaffExitActionQuery}
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              staffDetails.staff.user_access_status !== "view"
                            }
                            loading={staffExitActionList.loading}
                            noMargin
                            onClearFilters={() => {
                              setStaffExitActionQuery({
                                ...staffExitActionQueryDefault,
                              });
                            }}
                            page={staffExitActionQuery.page}
                            setPageObject={setStaffExitActionQuery}
                            setPageVariable="page"
                          />
                        )}

                        {/* Kanban */}
                        {showExitActionKanban && (
                          <Kanban
                            centerColumns
                            data={generateKanbanDataFromStaffExitActions(
                              staffDetails.staff.exit_actions
                            )}
                            initialise={initialiseExitActionKanban}
                            noMargin
                            onClick={(id) => {
                              if (
                                staffDetails.staff.user_access_status !== "view"
                              ) {
                                dispatch(listStaffExitActionDetailsAction(id));

                                setUpdateExitActionDrawerIsOpen(true);
                              }
                            }}
                            onDragEnd={(result) => {
                              const actionId = result.draggableId;
                              const actionStatus =
                                result.destination.droppableId;

                              updateExitActionStatus(actionId, actionStatus);
                            }}
                            searchBarMargin="-45px 0 20px 70px"
                            searchBarPlaceholder="Search actions"
                            setInitialise={setInitialiseExitActionKanban}
                            statusCategories={[
                              {
                                intent: "primary",
                                value: "On-hold",
                              },
                              {
                                intent: "warning",
                                value: "In-progress",
                              },
                              {
                                intent: "success",
                                value: "Completed",
                              },
                            ]}
                          />
                        )}
                      </>
                    }
                    help="Assign and review actions to exit the team member"
                    icon="take-action"
                    message={
                      <>
                        advaise.app has prefilled this section with a standard
                        set of actions. Assign or delete them as appropriate.
                      </>
                    }
                    messageIntent="primary"
                    title="Exit actions"
                  />

                  {/* Exit files */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          staffDetails.staff.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddExitUploadDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            margin: "0 0 20px 0",
                          }}
                        >
                          <div style={{ margin: "0 10px 0 0" }}>Category</div>

                          <div style={{ width: "300px" }}>
                            <SelectField
                              options={[
                                { id: 1, label: "All", value: "" },
                                { id: 2, value: "Exit Agreement" },
                                { id: 3, value: "Exit Checklist" },
                                { id: 4, value: "Exit Interview" },
                                { id: 5, value: "Other" },
                              ]}
                              setValueObject={setStaffExitUploadQuery}
                              setValueVariable="category"
                              value={staffExitUploadQuery.category}
                            />
                          </div>
                        </div>

                        <ItemCardList
                          content={staffExitUploadList.staff_exit_uploads.map(
                            (staff_exit_upload) => {
                              return (
                                <DocumentCard
                                  interactive
                                  key={staff_exit_upload.id}
                                  onClick={() => {
                                    dispatch(
                                      listStaffExitUploadDetailsAction(
                                        staff_exit_upload.id
                                      )
                                    );

                                    setUpdateExitUploadDrawerIsOpen(true);
                                  }}
                                  title={staff_exit_upload.name}
                                  type={staff_exit_upload.type}
                                />
                              );
                            }
                          )}
                          count={staffExitUploadList.count}
                          loading={staffExitUploadList.loading}
                          noMargin
                          noResultsMessage="Add files"
                          noResultsTitle="No files found"
                          page={staffExitUploadQuery.page}
                          setPageObject={setStaffExitUploadQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage exit documents"
                    icon="folder-open"
                    title="Exit files"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default StaffDetailScreen;
