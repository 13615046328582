// Constant imports
import {
  STAFF_EXIT_ACTION_LIST_REQUEST,
  STAFF_EXIT_ACTION_LIST_SUCCESS,
  STAFF_EXIT_ACTION_LIST_FAIL,
  STAFF_EXIT_ACTION_LIST_RESET,
  STAFF_EXIT_ACTION_CREATE_REQUEST,
  STAFF_EXIT_ACTION_CREATE_SUCCESS,
  STAFF_EXIT_ACTION_CREATE_FAIL,
  STAFF_EXIT_ACTION_CREATE_RESET,
  STAFF_EXIT_ACTION_DETAILS_REQUEST,
  STAFF_EXIT_ACTION_DETAILS_SUCCESS,
  STAFF_EXIT_ACTION_DETAILS_FAIL,
  STAFF_EXIT_ACTION_DETAILS_RESET,
  STAFF_EXIT_ACTION_UPDATE_REQUEST,
  STAFF_EXIT_ACTION_UPDATE_SUCCESS,
  STAFF_EXIT_ACTION_UPDATE_FAIL,
  STAFF_EXIT_ACTION_UPDATE_RESET,
  STAFF_EXIT_ACTION_DELETE_REQUEST,
  STAFF_EXIT_ACTION_DELETE_SUCCESS,
  STAFF_EXIT_ACTION_DELETE_FAIL,
  STAFF_EXIT_ACTION_DELETE_RESET,
} from "../constants/staffExitActionConstants";

// List all
export const staffExitActionListReducer = (
  state = {
    loading: false,
    count: 0,
    staff_exit_actions: [],
    numberOfActionsCompleted: 0,
    numberOfActionsInProgress: 0,
    numberOfActionsOnHold: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_EXIT_ACTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STAFF_EXIT_ACTION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        staff_exit_actions: action.payload.staff_exit_actions,
        numberOfActionsCompleted: action.payload.numberOfActionsCompleted,
        numberOfActionsInProgress: action.payload.numberOfActionsInProgress,
        numberOfActionsOnHold: action.payload.numberOfActionsOnHold,
        success: true,
        error: "",
      };

    case STAFF_EXIT_ACTION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        staff_exit_actions: [],
        numberOfActionsCompleted: 0,
        numberOfActionsInProgress: 0,
        numberOfActionsOnHold: 0,
        success: false,
        error: action.payload,
      };

    case STAFF_EXIT_ACTION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        staff_exit_actions: [],
        numberOfActionsCompleted: 0,
        numberOfActionsInProgress: 0,
        numberOfActionsOnHold: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const staffExitActionCreateReducer = (
  state = { loading: false, staff_exit_action: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_EXIT_ACTION_CREATE_REQUEST:
      return {
        loading: true,
        staff_exit_action: state.staff_exit_action,
        success: false,
        error: "",
      };

    case STAFF_EXIT_ACTION_CREATE_SUCCESS:
      return {
        loading: false,
        staff_exit_action: action.payload,
        success: true,
        error: "",
      };

    case STAFF_EXIT_ACTION_CREATE_FAIL:
      return {
        loading: false,
        staff_exit_action: state.staff_exit_action,
        success: false,
        error: action.payload,
      };

    case STAFF_EXIT_ACTION_CREATE_RESET:
      return {
        loading: false,
        staff_exit_action: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const staffExitActionDetailsReducer = (
  state = { loading: false, staff_exit_action: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_EXIT_ACTION_DETAILS_REQUEST:
      return {
        loading: true,
        staff_exit_action: state.staff_exit_action,
        success: false,
        error: "",
      };

    case STAFF_EXIT_ACTION_DETAILS_SUCCESS:
      return {
        loading: false,
        staff_exit_action: action.payload,
        success: true,
        error: "",
      };

    case STAFF_EXIT_ACTION_DETAILS_FAIL:
      return {
        loading: false,
        staff_exit_action: state.staff_exit_action,
        success: false,
        error: action.payload,
      };

    case STAFF_EXIT_ACTION_DETAILS_RESET:
      return {
        loading: false,
        staff_exit_action: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const staffExitActionUpdateReducer = (
  state = { loading: false, staff_exit_action: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_EXIT_ACTION_UPDATE_REQUEST:
      return {
        loading: true,
        staff_exit_action: state.staff_exit_action,
        success: false,
        error: "",
      };

    case STAFF_EXIT_ACTION_UPDATE_SUCCESS:
      return {
        loading: false,
        staff_exit_action: action.payload,
        success: true,
        error: "",
      };

    case STAFF_EXIT_ACTION_UPDATE_FAIL:
      return {
        loading: false,
        staff_exit_action: state.staff_exit_action,
        success: false,
        error: action.payload,
      };

    case STAFF_EXIT_ACTION_UPDATE_RESET:
      return {
        loading: false,
        staff_exit_action: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const staffExitActionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_EXIT_ACTION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case STAFF_EXIT_ACTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case STAFF_EXIT_ACTION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case STAFF_EXIT_ACTION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
