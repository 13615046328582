// Constant imports
import {
  STAFF_EXIT_UPLOAD_LIST_REQUEST,
  STAFF_EXIT_UPLOAD_LIST_SUCCESS,
  STAFF_EXIT_UPLOAD_LIST_FAIL,
  STAFF_EXIT_UPLOAD_LIST_RESET,
  STAFF_EXIT_UPLOAD_CREATE_REQUEST,
  STAFF_EXIT_UPLOAD_CREATE_SUCCESS,
  STAFF_EXIT_UPLOAD_CREATE_FAIL,
  STAFF_EXIT_UPLOAD_CREATE_RESET,
  STAFF_EXIT_UPLOAD_DETAILS_REQUEST,
  STAFF_EXIT_UPLOAD_DETAILS_SUCCESS,
  STAFF_EXIT_UPLOAD_DETAILS_FAIL,
  STAFF_EXIT_UPLOAD_DETAILS_RESET,
  STAFF_EXIT_UPLOAD_UPDATE_REQUEST,
  STAFF_EXIT_UPLOAD_UPDATE_SUCCESS,
  STAFF_EXIT_UPLOAD_UPDATE_FAIL,
  STAFF_EXIT_UPLOAD_UPDATE_RESET,
  STAFF_EXIT_UPLOAD_DELETE_REQUEST,
  STAFF_EXIT_UPLOAD_DELETE_SUCCESS,
  STAFF_EXIT_UPLOAD_DELETE_FAIL,
  STAFF_EXIT_UPLOAD_DELETE_RESET,
} from "../constants/staffExitUploadConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listStaffExitUploadsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_UPLOAD_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffExitUploads(
        params,
        token
      );

      dispatch({
        type: STAFF_EXIT_UPLOAD_LIST_SUCCESS,

        payload: {
          count: data.count,
          staff_exit_uploads: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_UPLOAD_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListStaffExitUploadsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_UPLOAD_LIST_RESET });
};

// Create
export const createStaffExitUploadAction =
  (staff_exit_upload) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_UPLOAD_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createStaffExitUpload(
        token,
        staff_exit_upload
      );

      dispatch({
        type: STAFF_EXIT_UPLOAD_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_UPLOAD_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateStaffExitUploadAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_UPLOAD_CREATE_RESET });
};

// List details
export const listStaffExitUploadDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_UPLOAD_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffExitUpload(id, token);

      dispatch({
        type: STAFF_EXIT_UPLOAD_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_UPLOAD_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListStaffExitUploadDetailsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_UPLOAD_DETAILS_RESET });
};

// Update
export const updateStaffExitUploadAction =
  (id, staff_exit_upload) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_UPLOAD_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateStaffExitUpload(
        id,
        token,
        staff_exit_upload
      );

      dispatch({
        type: STAFF_EXIT_UPLOAD_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_UPLOAD_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateStaffExitUploadAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_UPLOAD_UPDATE_RESET });
};

// Delete
export const deleteStaffExitUploadAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_EXIT_UPLOAD_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteStaffExitUpload(id, token);

      dispatch({
        type: STAFF_EXIT_UPLOAD_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_EXIT_UPLOAD_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteStaffExitUploadAction = () => async (dispatch) => {
  dispatch({ type: STAFF_EXIT_UPLOAD_DELETE_RESET });
};
