// Library imports
import { Colors } from "@blueprintjs/core";

// Main function
const generateKanbanDataFromStaffOnboardingActions = (actions) => {
  if (actions) {
    let arr = [];

    actions.forEach((action) => {
      arr.push({
        action: action.action,
        comments: action.comments,
        color: Colors.BLUE3,
        due: action.due,
        id: `${action.id}`,
        name: action.name,
        owner: action.owner_name,
        ownerPhoto: action.owner_profile_photo,
        status: action.status,
      });
    });

    return arr;
  }

  return [];
};

export default generateKanbanDataFromStaffOnboardingActions;
